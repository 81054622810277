import React from 'react'

function HorizontalSpacer({
    width,
    height,
    className
}) {
    return (
        <svg 
            className={className}
            width={width}
            height={height || 1}
            viewBox="0 0 612 2" 
            fill="none" 
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="none">
            <rect y="2" width="1.99997" height="612" transform="rotate(-90 0 2)" fill="url(#paint0_linear_810_423)" fillOpacity="0.2" />
            <defs>
                <linearGradient id="paint0_linear_810_423" x1="0.999987" y1="2" x2="0.999987" y2="614" gradientUnits="userSpaceOnUse">
                    <stop stopColor="white" stopOpacity="0" />
                    <stop offset="0.508997" stopColor="white" />
                    <stop offset="0.988192" stopColor="white" stopOpacity="0" />
                </linearGradient>
            </defs>
        </svg>

    )
}

export default HorizontalSpacer