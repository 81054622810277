import React from 'react'

function PolygonIcon(props) {
    return (
        <svg 
            {...props}
            viewBox="0 0 33 33" 
            fill="none" 
            xmlns="http://www.w3.org/2000/svg">
            <circle cx="16.5" cy="16.5" r="16.5" fill="#8547E3" />
            <g clipPath="url(#clip0_2_6603)">
                <path d="M22.1525 13.4816C21.7385 13.2667 21.2062 13.2667 20.733 13.4816L17.4208 15.2547L15.1732 16.3831L11.9202 18.1562C11.5061 18.3711 10.9738 18.3711 10.5006 18.1562L7.95733 16.7592C7.5433 16.5443 7.24757 16.1144 7.24757 15.6308V12.9443C7.24757 12.5144 7.48416 12.0846 7.95733 11.8159L10.5006 10.4726C10.9147 10.2577 11.447 10.2577 11.9202 10.4726L14.4635 11.8697C14.8775 12.0846 15.1732 12.5144 15.1732 12.998V14.7711L17.4208 13.5891V11.7622C17.4208 11.3323 17.1842 10.9025 16.711 10.6338L11.9793 8.10846C11.5653 7.89353 11.033 7.89353 10.5598 8.10846L5.70976 10.6876C5.23659 10.9025 5 11.3323 5 11.7622V16.8129C5 17.2428 5.23659 17.6726 5.70976 17.9413L10.5006 20.4667C10.9147 20.6816 11.447 20.6816 11.9202 20.4667L15.1732 18.7473L17.4208 17.5652L20.6739 15.8458C21.0879 15.6308 21.6202 15.6308 22.0934 15.8458L24.6367 17.1891C25.0507 17.404 25.3464 17.8338 25.3464 18.3174V21.004C25.3464 21.4338 25.1098 21.8637 24.6367 22.1323L22.1525 23.4756C21.7385 23.6905 21.2062 23.6905 20.733 23.4756L18.1897 22.1323C17.7757 21.9174 17.4799 21.4876 17.4799 21.004V19.2846L15.2324 20.4667V22.2398C15.2324 22.6697 15.469 23.0995 15.9421 23.3682L20.733 25.8935C21.147 26.1085 21.6793 26.1085 22.1525 25.8935L26.9434 23.3682C27.3574 23.1532 27.6532 22.7234 27.6532 22.2398V17.1353C27.6532 16.7055 27.4166 16.2756 26.9434 16.007L22.1525 13.4816Z" fill="white" />
            </g>
            <defs>
                <clipPath id="clip0_2_6603">
                    <rect width="22.7123" height="18" fill="white" transform="translate(5 8)" />
                </clipPath>
            </defs>
        </svg>
    )
}

export default PolygonIcon