import React, { useEffect, useMemo } from 'react';
import { useLanguageTrigger } from '~/Context/LanguageContext';
import Web3Interface, { Focus } from '~/Utilities/Web3Interface';
import AboutUpperSection from '~/Components/AboutUpperSection';
import FAQMenuItem from '~/Components/FAQMenuItem';
import Header from '~/Components/Header/Header';
import Footer from '~/Components/Footer/Footer';
import Language from '~/Language';
import Style from "./FAQ.module.css";

function FAQ() {
    const languageTrigger = useLanguageTrigger();

    const faqEntries = useMemo(
        () => {
            return Object
                .keys(Language)
                .filter(i => i.startsWith("__FAQ_") && i.endsWith("_Q"))
                .map(i => ({
                    question: Language[i],
                    answer: Language[i.replace('_Q', '_A')]
                }))
        },
        // eslint-disable-next-line
        [languageTrigger]
    )

    useEffect(() => {
        Web3Interface.setFocus(Focus.NONE);
    }, []);

    return (
        <div className={Style.container}>
            <Header />

            <AboutUpperSection
                title={ Language.FREQUENTLY_ASKED_QUESTIONS }
                description={Language.FAQ_PARAGRAPH} />

            <div className={Style.faqItemList}>
                {
                    faqEntries?.map((item, index) =>
                        <FAQMenuItem
                            key={index}
                            title={item.question}
                            content={item.answer}/>
                    )
                }

            </div>

            <Footer/>
        </div>
    )
}

export default FAQ;