import { ethers } from 'ethers';
import LiquidityFarmABI from './ABI/LiquidityFarm.js';
import Config from '~/config.js';
import LocalStorage from '../LocalStorage.js';
import Helpers from '~/helpers.js';
import Language from '~/Language/index.js';

class LiquidityFarm {
    constructor(web3Interface, signer, swapRouter) {
        this.web3Interface = web3Interface;
        this.signer = signer;
        this.swapRouter = swapRouter;
        this.lastSparseUpdate = 0;
        this.isInitialized = true;
        this.isFocused = false;
        this.state = null;
        
        this.contract = new ethers.Contract(
            Config.Blockchain.Contracts.LIQUIDITY_FARM, 
            LiquidityFarmABI, 
            signer
        );
    }

    async updateState() {
        if(!this.isFocused && this.state !== null)
            return;

        let newState = {}

        if(Date.now() > this.lastSparseUpdate + Config.Blockchain.SPARSE_UPDATE_RATE_MS) {
            this.lastSparseUpdate = Date.now();
            newState.tvl = await this.contract.totalDeposits()
            newState.blockReward = await this.contract.getBlockReward()
        }

        let userData = await this.contract.userData(this.web3Interface.address)
        newState.stakedAmount = userData.stakedAmount; 

        newState.pendingRewards = 
            await this.contract.pendingRewards(this.web3Interface.address)

        if(!this.state)
            this.state = newState;
        else
            this.state = {...this.state, ...newState};

        return this.state;
    }

    async harvest() {
        let txData = await this.contract.harvest()
    
        this.web3Interface.addPendingTransaction(
            txData.hash, 
            'LIQUIDITY_FARM_HARVEST', 
            Language.CLAIMING_TOKENS
        )

        return txData;
    }

    async stake(amount) {
        let erc20AmountOuntMin = 
            (await this.swapRouter.getPathOutput(
                amount.div(2),
                [
                    Config.Blockchain.Contracts.WETH,
                    Config.Blockchain.Contracts.STABLECOIN,
                    Config.Blockchain.Contracts.ERC20
                ]
            ))
            .mul(10000 * (1 - LocalStorage.getSlippage()))
            .div(10000);

        let stablecoinAmountOuntMin = 
            (await this.swapRouter.getPathOutput(
                amount.div(2),
                [
                    Config.Blockchain.Contracts.WETH,
                    Config.Blockchain.Contracts.STABLECOIN
                ]
            ))
            .mul(10000 * (1 - LocalStorage.getSlippage()))
            .div(10000);

        let isToken0Stablecoin = Helpers.isSameAddress(
            await this.swapRouter.lpToken.token0(), 
            Config.Blockchain.Contracts.STABLECOIN
        )

        // let deadline = 
        //     Math.floor(Date.now() / 1000 + LocalStorage.getDeadlinePeriod() * 60)

        let txData = await this.contract.stakeFromEth(
            isToken0Stablecoin ? stablecoinAmountOuntMin : erc20AmountOuntMin,
            isToken0Stablecoin ? erc20AmountOuntMin : stablecoinAmountOuntMin,
            // deadline,
            {value: amount}
        )
    
        this.web3Interface.addPendingTransaction(
            txData.hash, 
            'LIQUIDITY_FARM_STAKE', 
            'Staking tokens...'
        )

        return txData;
    }

    async unstake(amount) {
        let txData = await this.contract.unstakeToTokens(amount)
    
        this.web3Interface.addPendingTransaction(
            txData.hash, 
            'LIQUIDITY_FARM_UNSTAKE', 
            'Unstaking tokens...'
        )

        return txData;
    }

    async staticStake(amount) {
        return this.contract.callStatic.stakeFromEth(
            0,
            0,
            {value: amount}
        )
    }

    async staticUnstake(amount) {
        return this.contract.callStatic.unstakeToTokens(amount)
    }
}

export default LiquidityFarm;