import React, { useState } from 'react'
import ArrowRightIcon from '~/Assets/Vectors/ArrowRightIcon';
import HorizontalSpacer from '~/Assets/Vectors/HorizontalSpacer';
import Helpers from '~/helpers'
import Style from './RoadmapItem.module.css'

function RoadmapItem({
    title,
    date,
    paragraphs,
    upperLineStyle,
    lowerLineStyle
}) {
    const [ isExpanded, setIsExpanded ] = useState(false);

    const handleToggleExpand = () => {
        setIsExpanded(!isExpanded);
        console.log(isExpanded)
    }

    const getLineClasses = (lineStyle) => {
        if(lineStyle === RoadmapItem.LineStyles.COMPLETED)
            return Style.indicatorLine;
        else 
        if(lineStyle === RoadmapItem.LineStyles.FUTURE)
            return Style.indicatorLine + ' ' + Style.futureLine;
        else
            return Style.indicatorLine + ' ' + Style.noLine;
    }

    return (
        <div className={Style.container}>
            <div className={Style.indicatorContainer}>
                <div className={getLineClasses(upperLineStyle)}/>
                <div className={
                    Helpers.conditionalClass(
                        upperLineStyle === RoadmapItem.LineStyles.FUTURE,
                        Style.indicatorCircle,
                        Style.futureCircle
                    )}/>
                <div className={getLineClasses(lowerLineStyle)}/>
            </div>

            <div className={
                Helpers.conditionalClass(
                    upperLineStyle === RoadmapItem.LineStyles.FUTURE,
                    Style.bodyContainer,
                    Style.futureBody
                )}>
                
                <div className={Style.header}>
                    <p className={Style.title}>
                        { title }
                    </p>

                    <p className={Style.title + ' ' + Style.titleDate}>
                        { date }
                    </p>
                </div>

                <HorizontalSpacer width="100%" height="2px"/>

                <div style={{height: 10}}/>

                <div className={Style.paragraphContainer}>
                    <p className={Style.paragraph}>
                        { paragraphs[0] }
                    </p>    
                </div>

                <div 
                    className={
                        Helpers.conditionalClass(
                            isExpanded,
                            Style.paragraphContainerHidden,
                            Style.paragraphContainer
                        )}>
                    {
                        paragraphs
                            ?.filter((item, index) => index > 0)
                            .map(item =>
                                <p 
                                    className={Style.paragraph}
                                    key={item}>
                                    { item }
                                </p>    
                            )
                    }
                </div>

                {
                    paragraphs?.length > 1 &&
                    <div 
                        className={Style.readMoreContainer}
                        onClick={handleToggleExpand}>
                        <ArrowRightIcon
                            className={
                                Helpers.conditionalClass(
                                    isExpanded,
                                    Style.readMoreArrow,
                                    Style.readMoreArrowRotated
                                )}
                            circleColor={
                                upperLineStyle === RoadmapItem.LineStyles.FUTURE ?
                                "#1A1920" :
                                "#1D2585"
                            }
                            lineColor="#ffffff"/>

                        <p className={Style.readMore}>
                            {
                                isExpanded ?
                                'Read Less' :
                                'Read More'
                            }
                        </p>
                    </div>
                }

            </div>
        </div>
    )
}

RoadmapItem.LineStyles = {
    NONE: 0,
    COMPLETED: 1,
    FUTURE: 2
}

export default RoadmapItem