import React, { useState } from "react";
import { LanguageProvider } from "./Context/LanguageContext";
import { Web3Provider } from "./Context/Web3Context";
import { PopupManager } from "./Context/PopupManagerContext";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./Views/Home";
import FAQ from "./Views/FAQ";
import About from "./Views/About";
import Vesting from "./Views/Vesting";
import PublicSale from "./Views/PublicSale";

import Blob from './Assets/Vectors/Blob'

function App() {
	const [languageTrigger, setLanguageTrigger ] = useState({});

	return (
		<div className="App" style={{position: 'relative'}}>
			<div style={{
				position: 'absolute',
				zIndex: -1,
				inset: 0,
				width: '100%',
				height: '100%',
				overflow: 'hidden'
			}}>

				<Blob />
				<Blob />
				<Blob />
				<Blob />
				
			</div>


			<LanguageProvider 
				trigger={languageTrigger} 
				setTrigger={setLanguageTrigger}>
				<Web3Provider>
					<PopupManager>
						<BrowserRouter>
							<Routes>
								<Route 
									path="/" 
									element={<Home/>} />

								<Route
									path="faq" 
									element={<FAQ/>} />

								<Route 
									path="vesting" 
									element={<Vesting/>} />

								<Route 
									path="about" 
									element={<About/>} />

								<Route 
									path="sale" 
									element={<PublicSale/>} />
							</Routes>
						</BrowserRouter>
					</PopupManager>
				</Web3Provider>
			</LanguageProvider>
		</div>
	);
}

export default App;
