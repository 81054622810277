const EN = {
    LANGUAGE_NAME: "English",
    INFORMATIONAL_VIDEO_URL: "https://media.gravel777.com/how-g777-works.mov",
    PREVIOUS_REWARDS: "Previous Rewards",
    BUY_G777: "Buy G777",
    VIEW_NFT_COLLECTION: "View NFT Collection",
    PUBLIC_SALE: "Public Sale",
    VESTING: "Vesting",
    ABOUT: "About",
    CONNECT: "CONNECT",
    FETCHING_DATA: "Fetching data...",
    FAILED_TO_FETCH_DATA: "Failed to fetch data",
    LAST_ROUND_WINNERS: "Last Round Winners",
    VIEW_ALL: "View All",
    JACKPOT_WINNER: "Jackpot Winner",
    NFT_MINT_WINNER: "NFT Mint Winner",
    VIEW_ON_OPENSEA: "View on OpenSea",
    PASSIVE_INCOME_RECIPIENT: "Passive Income Recipient",
    EARN_GRAVEL_TOKENS: "Earn Gravel Tokens",
    PLEASE_SWITCH_TO_NETWORK: "Please switch to the {0} network",
    PLEASE_CONNECT_YOUR_WALLET: "Please connect your wallet",
    SWITCH_NETWORK: "Switch network",
    CONNECT_WALLET: "Connect wallet",
    WAITING_FOR_CONFIRMATION: "Waiting for confirmation...",
    BALANCE: "Balance:",
    NEW_TOTAL_DEPOSIT: "New total Deposit:",
    MONTHLY_INCOME: "Monthly Income:",
    YEARLY_INCOME: "Yearly Income:",
    STAKE: "Stake",
    UNSTAKE: "Unstake",
    WITHDRAW: "Withdraw",
    DEPOSIT: "Deposit",
    POOL_INFO: "Pool Info",
    TOTAL_VALUE_LOCKED: "Total Value Locked:",
    TOTAL_TOKENS_LOCKED: "Total Tokens Locked:",
    YOUR_SHARE: "Your Share:",
    VIEW_CONTRACT: "View Contract",
    LIQUIDITY_FARM: "Liquidity Farm",
    STAKING_POOL: "Staking Pool",
    APR: "APR:",
    EARNED: "Earned:",
    CLAIM_REWARDS: "Claim Rewards",
    EARN_G777_LIQUIDITY_FARM: "Earn G777 by providing liquidity",
    EARN_G777_STAKING_POOL: "Earn G777 by staking your existing G777",
    APPROVE_TOKEN: "Approve Token",
    ADD_LIQUIDITY: "Add Liquidity",
    STAKE_G777: "Stake G777",
    ROUND: "Round",
    DATE: "Date",
    JACKPOT: "Jackpot",
    NFT_MINT: "NFT Mint",
    PASSIVE_INCOME: "Passive Income",
    VIEW_TRANSACTION: "View Transaction",
    INVALID_EMAIL: "Invalid email",
    EMAIL_ADDRESS_NOT_VALID: "The provided email address is not valid. Please provide a valid email address in order to continue!",
    SUBSCRIBED: "Subscribed",
    THANK_YOU_FOR_SUBSCRIBING: "Thank you for subscribing to our newsletter! We will keep you up to date with the latest developments in the project.",
    FAILED_TO_SUBSCRIBE: "Failed to subscribe",
    COULD_NOT_SUBSCRIBE: "Could not subscribe to the newsletter. If this issue persists, please contact support!",
    EXPERIENCE_THE_EXCITEMENT: "Experience the excitement of decentralized gaming with Gravel 777, where every transfer could be a winning move.",
    STAY_IN_THE_LOOP: "Stay in the loop",
    JOIN_OUR_MAILING_LIST: "Join our mailing list to stay in the loop with our newest feature releases and events.",
    YOUR_EMAIL_ADDRESS: "Your email address",
    SIGNING_UP: "Signing up...",
    SIGN_UP: "Sign up",
    JOIN_THE_COMMUNITY: "Join the community",
    PRIVACY_POLICY: "Privacy Policy",
    TERMS_OF_SERVICE: "Terms of Service",
    G777_SLOGAN_TOP: "Play, transfer, win",
    G777_SLOGAN_BOTTOM: "on Gravel 777",
    HOME_PAGE_PARAGRAPH: "Make every transfer count on Gravel 777, the decentralized platform that turns every token transfer into a chance to win big.",
    CURRENT_POOL: "Current Pool",
    POOL_ENDS_IN: "Pool Ends in...",
    WAITING_FOR_POOL_REVEAL: "Waiting for pool reveal",
    HOURS: "Hours",
    MINUTES: "Minutes",
    SECONDS: "Seconds",
    HOW_GRAVEL_777_WORKS: "HOW GRAVEL 777 WORKS",
    CLAIM_YOUR_VESTED_TOKENS: "Claim your vested tokens",
    CLAIM_YOUR_VESTED_TOKENS_PARAGRAPH: "Claim your vested tokens from the Gravel 777 public sale, airdrop, or seed investment and start participating in the game. Explore the platform's unique gameplay, and discover how you can earn rewards by making regular token transfers.",
    G777_VESTED: "G777 Vested",
    G777_REWARDED: "G777 Rewarded",
    INVESTMENT_CLAIMED: "Investment claimed",
    TOTAL_G777: "Total G777",
    TOKENS_CLAIMED: "Tokens claimed",
    PERCENTAGE_CLAIMED: "Percentage claimed",
    VESTED_G777: "Vested G777",
    REWARDED_G777: "Rewarded G777",
    AIRDROP: "Airdrop",
    INVESTOR: "Investor",
    NOTHING_TO_CLAIM: "Nothing to claim",
    CLAIMABLE: "Claimable",
    OF_YOUR_TOTAL: "% of your total",
    NO_VESTING_SOURCES: "The selected account has no vesting sources",
    ACCOUNT_NOT_ELIGIBLE: "[ACCOUNT NOT ELIGIBLE]",
    CLAIMED_SO_FAR: "Claimed so far",
    CLAIMING_TOKENS: "Claiming tokens...",
    CLAIM_GRAVEL_777: "Claim Gravel 777",
    ENTER_GRAVEL_777_PUBLIC_SALE: "Enter Gravel 777 public sale",
    GRAVEL_777_PUBLIC_SALE_PARAGRAPH: "The Gravel 777 public sale is an opportunity for early investors to contribute to the project's initial success and have higher returns in the long run.",
    DAYS: "Days",
    HRS: "Hrs",
    MINS: "Mins",
    YOUR_POSITION: "Your position",
    VESTED_PRICE: "Vested price",
    PRICE_DISCLAIMER: "* Token price after public sale vesting period ends, assuming a 25% sell-off and net zero purchases on the DEX. This value is just an estimation!",
    SALE_NOT_OPEN_YET: "The sale is not open yet. Please come back in {0} days, {1} hours and {2} minutes!",
    THE_SALE_HAS_FINISHED: "The sale has finished. Please go to the Vesting page to claim your tokens!",
    GO_TO_VESTING_PAGE: "Go to Vesting page",
    CREATING_POSITION: "Creating position...",
    CREATE_POSITION: "Create position",
    FREQUENTLY_ASKED_QUESTIONS: "Frequently Asked Questions",
    FAQ_PARAGRAPH: "Get answers to common questions about Gravel 777 and how to play and win tokens on the platform.",
    ABOUT_GRAVEL_777: "About Gravel 777",
    ABOUT_GRAVEL_777_PARAGRAPH: "Gravel 777 is a decentralized gaming platform that is built on the Polygon network. The platform rewards users for their token activity and allows them to earn a portion of the pool as a jackpot or passive income through the platform's NFTs.",
    OUR_ROADMAP: "Our Roadmap",
    OUR_MISSION: "Our Mission",
    THE_GRAVEL_777_MISSION_LINE_SHORT: "Empowering users through decentralized gaming on the Polygon network",
    THE_PLATFORMS_AIM: "The platform's aim",
    THE_GRAVEL_777_MISSION_LINE_1: "The Gravel 777 mission is to provide a decentralized gaming platform that enables users to engage in regular token transfers and earn rewards through a fair and transparent lottery process. By leveraging the power of blockchain technology and smart contracts, Gravel 777 seeks to create a dynamic and engaging gaming experience that allows users to earn tokens and NFTs through their everyday token activity.",
    THE_GRAVEL_777_MISSION_LINE_2: "Our goal is to create a platform that is accessible to all users, regardless of their level of experience with blockchain technology. We strive to provide a user-friendly experience that allows users to easily participate in the game and earn rewards, while also promoting the use of blockchain technology and contributing to the growth of the decentralized ecosystem. We believe that by combining the power of blockchain with the excitement of gaming, we can create a platform that is truly unique and engaging for our users.",
    CONNECT_YOUR_WALLET: "Connect your Wallet",
    SELECT_WALLET_TYPE: "Please select your preferred wallet below! If MetaMask is not available, you can use WalletConnect through your Mobile Wallet App.",
    HOW_TO_USE_WALLET_CONNECT: "How to Use WalletConnect",
    YES: "Yes",
    NO: "No",
    OK: "OK",
    CLOSE: "Close",
    APPLY: "Apply",
    CANCEL: "Cancel",
    TX_SENT_TITLE: "Transaction sent",
    TX_SENT_MESSAGE: "Your transaction has been sent. Please allow some time for the miners to accept your transaction! Once the transaction is accepted, you can view your minted tokens on OpenSea.",
    TX_SETTINGS: "Transaction Settings",
    SLIPPAGE_LABEL: "Swap slippage:",
    DEADLINE_LABEL: "Transaction Deadline (minutes):",
    INVESTMENT_LOCKED: "Investment locked",
    INVESTMENT_LOCKED_REASON_1: "Your investment has been locked because you have not provided the neccessary KYC information to the project team. Please contact the team as soon as possible in order to unlock your investment and claim your tokens!",
    INVESTMENT_LOCKED_REASON_2: "Your investment has been locked because you have not signed the contract agreement with the project team. Please contact the team as soon as possible in order to unlock your investment and claim your tokens!",
    INVESTMENT_LOCKED_REASON_UNKNOWN: "Your investment has been locked for an unknown reason. Please contact the team as soon as possible!",
    
    __FAQ_0_Q: "What is the Gravel 777 platform?",
    __FAQ_0_A: "The Gravel 777 platform is a gaming experience that is built within an ERC20 token, known as Gravel 777 (G777). The platform aims to provide a seamless gaming experience for users while they are utilizing the platform token for their day-to-day transactions.",
    __FAQ_1_Q: "How does the Gravel 777 platform work?",
    __FAQ_1_A: "When users utilize the Gravel 777 platform's tokens for operations such as transfers, swapping, or staking, the sender automatically participates in a game of chance. At the end of each round, one transaction is chosen as the winner and is rewarded with the majority of the pool value. Additionally, transactions made during a game round are also eligible to win newly minted Gravel 777 NFTs, which provide the holder with a source of passive income.",
    __FAQ_2_Q: "How do users participate in the Gravel 777 platform?",
    __FAQ_2_A: "To participate in the Gravel 777 platform, users simply need to make an ERC20 token transfer. This will automatically enroll them in the current game round, and 5% of the value transferred will be added to the round pool.",
    __FAQ_3_Q: "What are the odds of winning on the Gravel 777 platform?",
    __FAQ_3_A: "The odds of a transaction winning on the Gravel 777 platform are proportional to the amount of the transaction. This means that the winning odds are proportional to the tax amount paid on the transaction and the contribution it made to the round's pool.",
    __FAQ_4_Q: "What happens at the end of a game round?",
    __FAQ_4_A: "At the end of a game round, the round pool is closed and a new one is automatically created. The closed round pool is then revealed to select a winner and distribute rewards. This process involves interactions with various platform modules, as well as off-chain services.",
    __FAQ_5_Q: "How are winners selected on the Gravel 777 platform?",
    __FAQ_5_A: "Winners are selected on the Gravel 777 platform using a secure and verifiable random seed provided by Chainlink VRF. This seed is used to select both the winner of the round jackpot and the winner of the newly minted Gravel 777 NFT.",
    __FAQ_6_Q: "How does the Gravel 777 platform generate secure random numbers?",
    __FAQ_6_A: "The Gravel 777 platform uses an Oracle, specifically the Chainlink VRF, to generate secure random numbers. The platform makes a request for random words on the VRF Coordinator contract, which are then provided by nodes on the Chainlink platform. The random numbers are then verified on-chain to prevent manipulation.",
    __FAQ_7_Q: "Are there any costs associated with using the Oracle on the Gravel 777 platform?",
    __FAQ_7_A: "Yes, there are costs associated with using the Oracle on the Gravel 777 platform. The platform uses LINK tokens to pay for requests on the Oracle. The cost of these requests is minimal, with just a few cents spent on LINK tokens per day. This cost is covered by the platform's Treasury.",
    __FAQ_8_Q: "What is the process for selecting a winner on the Gravel 777 platform?",
    __FAQ_8_A: "The Gravel 777 platform uses a simple and fair process for selecting winners. Each transaction made on the platform is logged in the Round Pool contract along with the amount transferred. The transactions are added to the Round Pool contract as slots, in chronological order, with the size of the slot depending on the transfer amount. Once the round ends, a random number is generated in the range of 0 to the total amount transferred during the round. The winner is then selected by finding the transaction slot that the random number falls under.",
    __FAQ_9_Q: "Why is this method of selecting winners considered fair?",
    __FAQ_9_A: "This method is considered fair because it accounts for the total amount of tokens transferred by a player during the round. The probability of winning is directly proportional to the amount of tokens transferred by the player. The time the tokens were transferred is not a factor, as the random number source is uniform and does not discriminate based on the position of the transfer slot in the round.",
    __FAQ_10_Q: "Who are the recipients of the round pool in Gravel 777?",
    __FAQ_10_A: "The recipients of the round pool in Gravel 777 are the jackpot winner (50%), passive income winners (up to 10%), staking pool (7%), liquidity farm (10%), public sale (15%), vesting (seed investors, 5%), and development team (3%).",
    __FAQ_11_Q: "Can the initial distribution of the round pool be updated in Gravel 777?",
    __FAQ_11_A: "Yes, the initial distribution of the round pool in Gravel 777 can be updated through a proposal and vote by the community through governance. If the vote passes, the new distribution will be set in place immediately.",
    __FAQ_12_Q: "How is the passive income share calculated in Gravel 777?",
    __FAQ_12_A: "The passive income share in Gravel 777 is calculated as 10% of the total supply, multiplied by the total supply. This ensures that each NFT will have the same annual average return, regardless of when it was minted.",
    __FAQ_13_Q: "What is the public sale in Gravel 777?",
    __FAQ_13_A: "The public sale in Gravel 777 is an opportunity for early investors to contribute to the project's initial success and have higher returns in the long run.",
    __FAQ_14_Q: "How is the public sale in Gravel 777 structured?",
    __FAQ_14_A: "The public sale in Gravel 777 is split into two phases: the investment phase and the returns phase. During the investment phase, investors can deposit USDC in the public sale contract. This phase lasts for four days. During the returns phase, public sale investors can claim their USDC deposits as G777 tokens, which are released linearly over a seven week period.",
    __FAQ_15_Q: "What is the purpose of the second phase of the public sale in Gravel 777?",
    __FAQ_15_A: "The second phase of the public sale in Gravel 777 is designed to create a constant buy-pressure on the DEX, causing the token price to gradually increase throughout the seven week period.",
    __FAQ_16_Q: "What incentives are provided to investors to participate in the public sale in Gravel 777?",
    __FAQ_16_A: "To incentivize investors to participate in the public sale, the public sale investors are rewarded with 15% of each round pool for the first year.",
    __FAQ_17_Q: "What is a staking pool?",
    __FAQ_17_A: "A staking pool is a way to incentivize token holders to hold onto their tokens rather than sell them. This helps to prevent volatility in the price of the token.",
    __FAQ_18_Q: "How does the G777 staking pool work?",
    __FAQ_18_A: "G777 token holders can stake their tokens in the staking pool, and in turn receive additional G777 rewards for doing so. This helps to prevent the tokens from being sold, which can help to reduce volatility in the token's price.",
    __FAQ_19_Q: "How are the rewards for the staking pool generated?",
    __FAQ_19_A: "The rewards for the staking pool are generated from the transaction tax. At the end of each round, 7% of the round value is sent to the staking pool and distributed to stakers.",
    __FAQ_20_Q: "Does staking in the G777 staking pool contribute to token inflation?",
    __FAQ_20_A: "No, since the staking pool is only rewarding tokens that are already in circulation, it does not contribute to token inflation.",
    __FAQ_21_Q: "What is a liquidity farm?",
    __FAQ_21_A: "A liquidity farm is a way to encourage the community to provide liquidity for a token on a decentralized exchange. This can help to reduce volatility in the token's price.",
    __FAQ_22_Q: "How does the G777 liquidity farm work?",
    __FAQ_22_A: "Users can lock their liquidity pool (LP) tokens in the farm's contract, and in exchange receive G777 tokens. This provides an incentive for users to add liquidity to the pool, and also provides rewards for locking LP tokens in the farm.",
    __FAQ_23_Q: "How are the rewards for the G777 liquidity farm generated?",
    __FAQ_23_A: "The rewards for the liquidity farm are generated from the transaction tax. At the end of each round, 10% of the round value is sent to the liquidity farm and distributed to users.",
    __FAQ_24_Q: "What is the treasury and how does it work?",
    __FAQ_24_A: "The treasury is a contract responsible for distributing funds for platform development, marketing, and adoption incentives. Whenever a new project is initiated by the development or marketing team, a proposal is published on the Governance contract. If the proposal is approved by the community, the team receives the funds from the reserve contract to implement the project.",
    __FAQ_25_Q: "How is the treasury funded?",
    __FAQ_25_A: "The treasury is initially funded from the excess of 10% sent to NFT holders. After the first year, funds that were intended for Public Sale investors will be redirected to the treasury.",
    __FAQ_26_Q: "What is vesting and how does it work?",
    __FAQ_26_A: "Vesting is a process that enables the linear release of owed tokens to seed round investors, founders, and other partners over a specified vesting period. The vested investors can claim their vested tokens at any time, but they cannot sell all of their tokens immediately after launch, which can help to prevent a sudden drop in the token's price. The vesting period for investors is one year.",
    __FAQ_27_Q: "What is the Governance contract and how does it work?",
    __FAQ_27_A: "The Governance contract enables the community to participate in the decision-making process for the platform's configuration and the distribution of Treasury funds. Proposals are usually made by the team, and if they are accepted by the community, they are implemented through a vote using G777 tokens. The weight of each vote is based on the number of tokens held by the voter.",
    
    __RM_1_T: "Defining the foundation of the platform",
    __RM_1_D: "August 2022",
    __RM_1_L_1: "The first milestone for the Gravel 777 project is to define the architecture, tokenomics, and design the NFT artwork and web page. This involves outlining the technical details of the platform, such as how the G777 ERC20 token and Round Pool will be implemented and how the tax on transfers will be collected and distributed to the jackpot winner and NFT holders.",
    __RM_1_L_2: "The team also focuses on creating a comprehensive whitepaper that describes the vision and goals of the project, as well as the mechanics of the platform and the benefits it offers to users. Additionally, they work on designing the NFT artwork and the overall look and feel of the web page to ensure a visually appealing and user-friendly experience for players. Overall, this first milestone is critical in setting the foundation for the successful launch and operation of the Gravel 777 platform.",
    __RM_2_T: "Developing and testing",
    __RM_2_D: "November 2022",
    __RM_2_L_1: "The second milestone for the project involves writing the smart contracts, conducting security audits, and testing the platform. This includes developing the smart contracts that will govern the transfer of tokens, the collection and distribution of the round pool, and the issuance and transfer of NFTs. The team will also work with security experts to ensure the contracts are secure and free from vulnerabilities.",
    __RM_2_L_2: "In addition to writing the smart contracts and conducting security audits, the team will also focus on testing the platform to ensure it is functioning as intended and that all features are working correctly. This will involve creating test cases and running simulations to simulate various scenarios and ensure the platform can handle the expected volume of transactions.",
    __RM_2_L_3: "Finally, the team will work on developing the web page and the reveal service, which is responsible for completing rounds, reporting round winners, and other data. The reveal service will make calls on the blockchain to finish each round and report the results to the web page, which will display the information to users. Overall, this second milestone is critical in preparing the Gravel 777 platform for launch and ensuring it is reliable and secure.",
    __RM_3_T: "Deploying in a test environment",
    __RM_3_D: "December 2022",
    __RM_3_L_1: "The third milestone involves deploying the platform in a test environment and allowing the team and a select group of people from the community to test it. This will involve setting up the test environment, deploying the smart contracts and other components of the platform, and providing access to the team and selected community members.",
    __RM_3_L_2: "The goal of this milestone is to ensure that the platform is functioning as intended and to identify any issues or bugs that need to be addressed before the full launch. The team will work closely with testers to gather feedback and address any issues that arise during the testing process.",
    __RM_3_L_3: "Once the testing is complete and any necessary modifications have been made, the team will then proceed to deploy the platform on the mainnet with the exact same configuration as the test environment. This will involve setting up the mainnet deployment, deploying the smart contracts and other components of the platform, and making it live and accessible to the public. Overall, this third milestone is crucial in preparing the Gravel 777 platform for its full launch and ensuring it is ready for use by the wider community.",
    __RM_4_T: "Community engagement and incentives",
    __RM_4_D: "",
    __RM_4_L_1: "The fourth milestone of the Gravel 777 roadmap focuses on community engagement and incentives. The goal of this phase is to increase awareness and adoption of the platform through targeted efforts to engage with and incentivize the community.",
    __RM_4_L_2: "To achieve this goal, the team will conduct a series of airdrops to reward community members who promote the platform through social media, referrals, and other channels. In addition to the airdrops, the team will collaborate with influencers and content creators to amplify the reach of the campaign. To further engage with the community, the team will also utilize social media, forums, and other online channels to answer questions, provide support, and gather feedback. To educate the community about the platform and its features, the team may also host online events such as AMA sessions or webinars. By utilizing community feedback, the team will work to improve the user experience and address any issues or concerns that arise. By the end of this milestone, the team aims to have successfully conducted a series of airdrops that drive awareness and adoption of the platform, as well as enhanced engagement with the community through online channels and events.",
    __RM_5_T: "Going live",
    __RM_5_D: "",
    __RM_5_L_1: "The fifth milestone of the project roadmap involves going live with the platform. This phase marks the official launch of the platform, and includes the deployment of the project, the addition of initial liquidity, and the opening of the public sale.",
    __RM_5_L_2: "Once the project is deployed and the initial liquidity has been added, the team will open the public sale to allow users to purchase G777 tokens. The first pool will also be collected at this time, and the jackpot winner and NFT holder will be determined. This marks the start of the first official round on the Gravel 777 platform, and users will be able to begin making token transfers and participating in the platform.",
    __RM_5_L_3: "To ensure a smooth and successful launch, the team will work closely with exchanges and other partners to ensure that the platform is properly integrated and that users have access to the necessary resources and support. By the end of this milestone, the Gravel 777 platform will be live and fully operational, ready to begin rewarding its users with tokens and NFTs.",
}

export default EN;