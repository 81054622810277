import React from 'react'

function MinusIcon({
    className
}) {
    return (
        <svg 
            className={className}
            viewBox="0 0 18 18" 
            fill="none" 
            xmlns="http://www.w3.org/2000/svg">
            <rect x="18" y="8" width="2" height="18" transform="rotate(90 18 8)" fill="white"/>
        </svg>
    )
}

export default MinusIcon