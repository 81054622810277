export async function get(url) {
    let request = await fetch(url);
    return(
        request.ok ?
        request.json() :
        null
    )
}

export async function getDelayed(url) {
    return new Promise(resolve => {
        setTimeout(async () => {
            let request = await fetch(url);
            resolve(
                request.ok ?
                request.json() :
                null
            )
        }, 5000);
    })
}