import React, { useEffect } from 'react';
import Web3Interface, { Focus } from '~/Utilities/Web3Interface';
import PublicSaleUpperSection from '~/Components/PublicSaleUpperSection';
import PublicSaleControls from '~/Components/PublicSaleControls';
import Header from '~/Components/Header/Header';
import Footer from '~/Components/Footer/Footer';
import Style from "./PublicSale.module.css";

function PublicSale() {
    useEffect(() => {
        Web3Interface.setFocus(Focus.VESTING);
    }, []);

    return (
        <div className={Style.container}>
            <Header />

            <PublicSaleUpperSection>
                <PublicSaleControls />
            </PublicSaleUpperSection>

            <Footer />
        </div>
    )
}

export default PublicSale;