import React from 'react';
import Web3Interface, { Providers } from '~/Utilities/Web3Interface';
import PopupContainer from '../PopupContainer';
import HorizontalSpacer from '~/Assets/Vectors/HorizontalSpacer';
import WalletButton from './WalletButton';
import MetaMaskIcon from '~/Assets/Vectors/MetaMaskIcon';
import WalletConnectIcon from '~/Assets/Vectors/WalletConnectIcon';
import ClosePlainIcon from '~/Assets/Vectors/ClosePlainIcon';
import Language from '~/Language';
import Config from '~/config';
import Style from './ConnectWalletPopup.module.css';

const ConnectWalletPopup = ({
    onClose,
    isOpen,
}) => {
    return (
        <PopupContainer
            isOpen={isOpen}
            onClose={onClose}
            backdropColor="#0009">

            <div className={Style.container}>

                <div className={Style.titleBarContainer}>
                    <p className={Style.title}>{ Language.CONNECT_YOUR_WALLET }</p>
                    <ClosePlainIcon 
                        className={Style.closeIcon}
                        onClick={onClose}/>
                </div>

                <HorizontalSpacer className={Style.upperSpacer} />

                <p className={Style.message}>{ Language.SELECT_WALLET_TYPE }</p>

                <div className={Style.walletButtonList}>
                    <WalletButton
                        name="MetaMask"
                        disabled={!Web3Interface.isMetaMaskAvailable()}
                        icon={MetaMaskIcon} 
                        onClick={() => {
                            Web3Interface.enable(Providers.METAMASK)
                            if(onClose)
                                onClose()
                        }}/>

                    <WalletButton
                        name="WalletConnect"
                        icon={WalletConnectIcon}
                        onClick={() => {
                            Web3Interface.enable(Providers.WALLET_CONNECT)
                            if(onClose)
                                onClose()
                        }} />
                </div>

                <div 
                    className={Style.howToLink}
                    onClick={() => {
                        window.open(Config.ExternalLinks.WALLETCONNECT_HELP, '_blank')
                    }}>
                    { Language.HOW_TO_USE_WALLET_CONNECT }
                </div>
            </div>

        </PopupContainer>
    );
}

export default ConnectWalletPopup;