import React from 'react'

function MetaMaskIcon({ className }) {
    return (
        <svg 
            className={className}
            viewBox="0 0 15 14" 
            fill="none" 
            xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M4.45557 12.8428L6.38786 13.3555V12.6851L6.54559 12.5274H7.64974V13.3161V13.8681H6.46673L5.00764 13.2372L4.45557 12.8428Z" fill="#CDBDB2"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M10.7648 12.8428L8.87195 13.3555V12.6851L8.71421 12.5274H7.61007V13.3161V13.8681H8.79308L10.2521 13.2372L10.7648 12.8428Z" fill="#CDBDB2"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M6.54543 11.3838L6.3877 12.6851L6.58483 12.5274H8.63539L8.87199 12.6851L8.71425 11.3838L8.39878 11.1866L6.82143 11.2261L6.54543 11.3838Z" fill="#393939"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M5.48071 2.59004L6.42712 4.79833L6.86093 11.2261H8.39881L8.87202 4.79833L9.73956 2.59004H5.48071Z" fill="#F89C35"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M1.34072 7.44048L0.236572 10.6346L2.99694 10.4768H4.77142V9.09669L4.69256 6.25746L4.29822 6.57293L1.34072 7.44048Z" fill="#F89D35"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M3.43054 7.79533L6.66411 7.87419L6.30924 9.53041L4.77129 9.13608L3.43054 7.79533Z" fill="#D87C30"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M3.43054 7.8348L4.77129 9.09668V10.3586L3.43054 7.8348Z" fill="#EA8D3A"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M4.77112 9.13607L6.34847 9.53041L6.86114 11.2261L6.50621 11.4232L4.77112 10.398V9.13607Z" fill="#F89D35"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M4.77104 10.3979L4.45557 12.8428L6.54559 11.3838L4.77104 10.3979Z" fill="#EB8F35"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M6.66399 7.87419L6.8612 11.2261L6.26965 9.51071L6.66399 7.87419Z" fill="#EA8E3A"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M2.95703 10.4374L4.77099 10.3979L4.45552 12.8428L2.95703 10.4374Z" fill="#D87C30"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M1.10412 13.9076L4.45595 12.8428L2.95747 10.4374L0.236572 10.6346L1.10412 13.9076Z" fill="#EB8F35"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M6.42751 4.79838L4.73189 6.21799L3.43054 7.79535L6.66411 7.91368L6.42751 4.79838Z" fill="#E8821E"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M4.45557 12.8428L6.54559 11.3838L6.38786 12.6457V13.3555L4.96824 13.0795L4.45557 12.8428Z" fill="#DFCEC3"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M10.7648 12.8428L8.71421 11.3838L8.87195 12.6457V13.3555L10.2916 13.0795L10.7648 12.8428Z" fill="#DFCEC3"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M5.79654 8.58397L6.23035 9.49098L4.69239 9.09664L5.79654 8.58397Z" fill="#393939"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M1.06433 0.736717L6.42733 4.79836L5.52039 2.59007L1.06433 0.736717Z" fill="#E88F35"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M1.06442 0.736679L0.354614 2.90554L0.748952 5.27157L0.472916 5.4293L0.867254 5.7842L0.551783 6.06024L0.985555 6.45458L0.709518 6.69118L1.34046 7.47986L4.29799 6.57288C5.7439 5.41616 6.45371 4.82465 6.42742 4.79836C6.40113 4.77207 4.61346 3.41818 1.06442 0.736679Z" fill="#8E5A30"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M13.8804 7.44052L14.9845 10.6346L12.2241 10.4769H10.4496V9.09674L10.5285 6.25751L10.9229 6.57298L13.8804 7.44052Z" fill="#F89D35"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M11.7905 7.79536L8.55696 7.87423L8.91182 9.53045L10.4498 9.13611L11.7905 7.79536Z" fill="#D87C30"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M11.7905 7.83484L10.4498 9.09672V10.3586L11.7905 7.83484Z" fill="#EA8D3A"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M10.4493 9.13611L8.87199 9.53045L8.35931 11.2261L8.71425 11.4233L10.4493 10.398V9.13611Z" fill="#F89D35"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M10.4493 10.398L10.7648 12.8429L8.71421 11.4233L10.4493 10.398Z" fill="#EB8F35"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M8.55708 7.87423L8.35987 11.2261L8.95142 9.51075L8.55708 7.87423Z" fill="#EA8E3A"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M12.2634 10.4375L10.4495 10.398L10.7649 12.8429L12.2634 10.4375Z" fill="#D87C30"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M14.117 13.9076L10.7651 12.8429L12.2636 10.4375L14.9845 10.6346L14.117 13.9076Z" fill="#EB8F35"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M8.79356 4.79834L10.4892 6.21796L11.7905 7.79531L8.55696 7.91365L8.79356 4.79834Z" fill="#E8821E"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M9.42453 8.58401L8.99072 9.49103L10.5287 9.09669L9.42453 8.58401Z" fill="#393939"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M14.1561 0.736717L8.79313 4.79836L9.70007 2.59007L14.1561 0.736717Z" fill="#E88F35"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M14.1566 0.736717L14.8665 2.90558L14.4721 5.2716L14.7482 5.42934L14.3538 5.78424L14.6693 6.06028L14.2355 6.45462L14.5116 6.69122L13.8806 7.4799L10.9231 6.57292C9.47717 5.41619 8.76736 4.82469 8.79365 4.7984C8.81994 4.77211 10.6076 3.41822 14.1566 0.736717Z" fill="#8E5A30"/>
            </svg>
    )
}

export default MetaMaskIcon