import React from 'react'

function TwitterIcon({className}) {
    return (
        <svg 
            className={className}
            viewBox="0 0 18 14" 
            fill="none" 
            xmlns="http://www.w3.org/2000/svg">
            <path d="M15.6906 3.41471C15.6974 3.5575 15.701 3.70145 15.701 3.84539C15.701 8.22828 12.1568 13.2804 5.67223 13.2804C3.68177 13.2804 1.82884 12.7331 0.269531 11.7916C0.545243 11.8224 0.825854 11.838 1.1102 11.838C2.76204 11.838 4.28129 11.3075 5.48778 10.4183C3.94574 10.3916 2.64362 9.4327 2.1952 8.11452C2.40985 8.15283 2.6313 8.17431 2.85767 8.17431C3.17904 8.17431 3.49118 8.13484 3.78664 8.05881C2.17427 7.75466 0.959765 6.41441 0.959765 4.8066C0.959765 4.79267 0.959713 4.77817 0.960334 4.76482C1.43528 5.01267 1.97869 5.16241 2.55604 5.17925C1.61107 4.58546 0.988754 3.5697 0.988754 2.41927C0.988754 1.81098 1.16208 1.24097 1.46555 0.751079C3.20312 2.75823 5.80114 4.07815 8.72978 4.21746C8.66933 3.97425 8.63912 3.72176 8.63912 3.46115C8.63912 1.62986 10.2169 0.144531 12.163 0.144531C13.177 0.144531 14.0918 0.547342 14.7357 1.19163C15.5395 1.04304 16.292 0.767908 16.9748 0.38656C16.7096 1.16145 16.1525 1.81098 15.4229 2.22193C16.1365 2.14183 16.8175 1.9642 17.4472 1.7001C16.9772 2.36412 16.3796 2.94862 15.6906 3.41471Z" fill="white"/>
        </svg>
    )
}

export default TwitterIcon