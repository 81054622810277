import OpenSeaMonochromeIcon from './Assets/Vectors/SocialMedia/OpenSeaMonochromeIcon';
import InstagramIcon from './Assets/Vectors/SocialMedia/InstagramIcon';
import YoutubeIcon from './Assets/Vectors/SocialMedia/YoutubeIcon';
import TwitterIcon from './Assets/Vectors/SocialMedia/TwitterIcon';
import FacebookIcon from './Assets/Vectors/SocialMedia/FacebookIcon';
import GithubIcon from './Assets/Vectors/SocialMedia/GithubIcon';
import TelegramIcon from './Assets/Vectors/SocialMedia/TelegramIcon';
import DiscordIcon from './Assets/Vectors/SocialMedia/DiscordIcon';
import TiktokIcon from './Assets/Vectors/SocialMedia/TiktokIcon';
// import RedditIcon from "./Assets/Vectors/SocialMedia/RedditIcon";

const Config = {
	API_BASE_URL: 'https://api.gravel777.com/api',
	IS_COMING_SOON: true,
	ExternalLinks: {
		OPENSEA_ITEM_URL: `https://opensea.io/assets/matic/${process.env.REACT_APP_ERC721}/`,
		OPENSEA_COLLECTION_URL: `https://opensea.io/collection/${process.env.REACT_APP_OPENSEA_COLLECTION}`,
		TOKEN_EXCHANGE: `https://quickswap.exchange/#/swap?swapIndex=0&currency0=ETH&currency1=0xD20e926cf512120d91C8703a9B3dd242998AE6d2`,
		NFT_IMAGE_URL: 'https://media.gravel777.com/nft/',
		NFT_IMAGE_EXTENSION: '.png',
		WALLETCONNECT_HELP:
			'https://academy.binance.com/en/articles/how-to-use-walletconnect',
	},
	Blockchain: {
		Network: {
			ID: 137,
			TX_EXPLORER: 'https://polygonscan.com/tx/',
			BLOCK_TIME_MS: 2300,
			Configuration: {
				chainId: '0x89',
				chainName: 'Polygon',
				nativeCurrency: {
					name: 'MATIC',
					symbol: 'MATIC',
					decimals: 18,
				},
				rpcUrls: ['https://polygon-rpc.com/'],
				blockExplorerUrls: ['https://polygonscan.com/tx/'],
			},
		},
		Contracts: {
			ERC20: "0x022afff93cdd7740Fd32b0Abba01AD9aa9f92FEC",
			ERC721: "0x22Ab9C7727d962619C6970529CeeA2b19d6437dB",
			LP_TOKEN: "0x0B8ab2c29a3De9348Dbd00916F6F7617c160B6e2",
			STAKING_POOL: "0xc9679a817C15FaeA9D7f2BDf8975D97CA66dBE80",
			LIQUIDITY_FARM: "0x963ec413c82E3d6037EDA8Cfe506CBCe354a861a",
			PUBLIC_SALE: "0x09446CdC911143c5f2D8739A7598692A8D5930a3",
			VESTING: "0x7FbC6FBdfb54174416a365aEc191373066a8033B",
			AIRDROP: "0x4d07211f204795992CAA530606042fEE2C16f062",
			SWAP_ROUTER: '0xa5E0829CaCEd8fFDD4De3c43696c57F7D7A678ff',
			STABLECOIN: '0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174',
			WETH: '0x0d500b1d8e8ef31e21c99d1db9a6444d3adf1270',
		},
		SPARSE_UPDATE_RATE_MS: 30000,
		TX_UPDATE_RATE_MS: 5000,
		INFURA_ID: "f635223ca3c442d09b74422eed6b1c25",
	},
	Defaults: {
		SLIPPAGE_PERCENT: 0.5,
		DEADLINE_MINUTES: 20,
		ROUND_TIME_SECONDS: 24 * 60 * 60,
		ROUND_TABLE_ITEMS_PER_PAGE: 6,
		SALE_OPEN_TIME: 1666979915000,
		SALE_CLOSE_TIME: 1672195849156,
	},
	RoadmapEntryCompletedCount: 3,
	MultilanguageEnabled: false,
	SocialMedia: [
		{
			type: 'Instagram',
			icon: InstagramIcon,
			url: 'https://www.instagram.com/gravel777_token/',
		},
		{
			type: 'Discord',
			icon: DiscordIcon,
			url: 'https://discord.com/invite/AqzF8AWD',
		},
		{
			type: 'Telegram',
			icon: TelegramIcon,
			url: 'https://t.me/Gravel777OfficialGroup',
		},
		{
			type: 'Twitter',
			icon: TwitterIcon,
			url: 'https://twitter.com/Gravel777Token',
		},
		{
			type: 'Facebook',
			icon: FacebookIcon,
			url: 'https://www.facebook.com/gravel777token',
		},
		{
			type: 'YouTube',
			icon: YoutubeIcon,
			url: 'https://www.youtube.com/channel/UCPLriK_njTXsrP-PTQZpzQg',
		},
		{
			type: 'TikTok',
			icon: TiktokIcon,
			url: 'https://www.tiktok.com/@gravel777_token',
		},
		// {
		//     type: "Reddit",
		//     icon: RedditIcon,
		//     url: "https://example.com"
		// },
		{
			type: 'OpenSea',
			icon: OpenSeaMonochromeIcon,
			url: `https://opensea.io/collection/g-token-v3`,
		},
		{
			type: 'GitHub',
			icon: GithubIcon,
			url: 'https://github.com/Gravel777',
		},
	],
};

export default Config;
