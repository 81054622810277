import { ethers } from 'ethers';
import SwapRouterABI from './ABI/SwapRouter.js';
import LPTokenABI from './ABI/LPToken.js';
import ERC20ABI from './ABI/ERC20.js';
import Config from '~/config.js';
import Helpers from '~/helpers.js';

class SwapRouter {
    constructor(web3Interface, signer) {
        this.web3Interface = web3Interface;
        this.signer = signer;
        this.isFocused = false;
        this.lastSparseUpdate = 0;
        this.state = null;

        this.contract = new ethers.Contract(
            Config.Blockchain.Contracts.SWAP_ROUTER, 
            SwapRouterABI, 
            signer
        );

        this.lpToken = new ethers.Contract(
            Config.Blockchain.Contracts.LP_TOKEN, 
            LPTokenABI, 
            signer
        );

        this.g777 = new ethers.Contract(
            Config.Blockchain.Contracts.ERC20, 
            ERC20ABI, 
            signer
        );
    }

    async updateState() {
        if(!this.isFocused && this.state !== null)
            return;

        let newState = {}

        if(Date.now() > this.lastSparseUpdate + Config.Blockchain.SPARSE_UPDATE_RATE_MS) {
            this.lastSparseUpdate = Date.now();
            
            newState.tokenPrice = await this.getG777Price(); 
            newState.coinPrice = await this.getCoinPrice(); 
            newState.lpValue = await this.getLpValue(); 
        }

        newState.tokenBalance = await this.g777.balanceOf(this.web3Interface.address); 
        newState.lpBalance = await this.lpToken.balanceOf(this.web3Interface.address); 

        newState.tokenApproved = (
            await this.g777.allowance(
                this.web3Interface.address, 
                Config.Blockchain.Contracts.STAKING_POOL    
            )
        ).gt(ethers.constants.MaxUint256.div(1000000))

        newState.lpApproved = (
            await this.lpToken.allowance(
                this.web3Interface.address,
                Config.Blockchain.Contracts.LIQUIDITY_FARM
            )
        ).gt(ethers.constants.MaxUint256.div(1000000))

        if(!this.state)
            this.state = newState;
        else
            this.state = {...this.state, ...newState};

        return this.state;
    }

    async getTokenToTokenOutput(amount, from, to) {        
        let fromPath = Config.Blockchain.SwapPaths[from.toLowerCase()];
        let toPath = Config.Blockchain.SwapPaths[to.toLowerCase()];
        let swapPath = fromPath.concat(toPath.reverse().slice(1));

        let outputs = await this.contract.getAmountsOut(
            amount, swapPath
        )

        return outputs[swapPath.length - 1];
    }

    async getPathOutput(amount, swapPath) {        
        let outputs = await this.contract.getAmountsOut(
            amount, swapPath
        )
        return outputs[swapPath.length - 1];
    }

    async getLpValue() {
        let totalSupply = await this.lpToken.totalSupply();
        let reserves = await this.lpToken.getReserves();

        let isToken0Stablecoin = Helpers.isSameAddress(
            await this.lpToken.token0(), 
            Config.Blockchain.Contracts.STABLECOIN
        )
        let stablecoinReserve = 
            isToken0Stablecoin ?
            reserves.reserve0 :
            reserves.reserve1;
            
        return stablecoinReserve
            .mul(ethers.utils.parseEther("1.0"))
            .mul(2)
            .div(totalSupply);
    }

    async getG777Price() {
        return this.getPathOutput(
            ethers.utils.parseEther('1.0'),
            [
                Config.Blockchain.Contracts.ERC20,
                Config.Blockchain.Contracts.STABLECOIN
            ]
        )
    }

    async getCoinPrice() {
        return this.getPathOutput(
            ethers.utils.parseEther('1.0'),
            [
                Config.Blockchain.Contracts.WETH,
                Config.Blockchain.Contracts.STABLECOIN
            ]
        )
    }

    async approveStakingPool() {
        let txData = await this.g777.approve(
            Config.Blockchain.Contracts.STAKING_POOL,
            ethers.constants.MaxUint256
        )

        this.web3Interface.addPendingTransaction(
            txData.hash, 
            'STAKING_POOL_APPROVE', 
            'Approving token...'
        )

        return txData;
    }

    async approveLiquidityFarm() {
        let txData = await this.lpToken.approve(
            Config.Blockchain.Contracts.LIQUIDITY_FARM,
            ethers.constants.MaxUint256
        )

        this.web3Interface.addPendingTransaction(
            txData.hash, 
            'LIQUIDITY_FARM_APPROVE', 
            'Approving token...'
        )

        return txData;
    }
}

export default SwapRouter;