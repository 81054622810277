import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import { useWeb3Account } from '~/Context/Web3Context';
import { usePopupManager } from '~/Context/PopupManagerContext';
import Web3Interface from '~/Utilities/Web3Interface';
import ConnectWalletPopup from '~/Popups/ConnectWalletPopup';
import LanguageDropdown from '../LanguageDropdown';
import VerticalSpacer from '~/Assets/Vectors/VerticalSpacer';
import LabeledIcon from '../LabeledIcon/LabeledIcon';
import WalletConnectIcon from '~/Assets/Vectors/WalletConnectIcon';
import MetaMaskIcon from '~/Assets/Vectors/MetaMaskIcon';
import MenuIcon from '~/Assets/Vectors/MenuIcon';
import SideMenu from '../SideMenu';
import Language from '~/Language';
import Helpers from '~/helpers';
import Config from '~/config';
import Style from './Header.module.css';

function Header() {
    const { showPopup } = usePopupManager();
    const [ isMenuOpen, setIsMenuOpen ] = useState(false)
    const address = useWeb3Account();

    const handleConnectCliced = () => {
        showPopup(ConnectWalletPopup, {})
    }

    const hanldeOpenMenu = () => {
        setIsMenuOpen(true)
    }

    const handleCloseMenu = () => {
        setIsMenuOpen(false)
    }

    return (
        <div className={Style.container}>

            <SideMenu
                isOpen={isMenuOpen}
                onClose={handleCloseMenu} />

            <MenuIcon
                className={Style.menuIcon}
                onClick={hanldeOpenMenu}/>

            <Link 
                className={Style.logoText}
                to="/">
                GRAVEL<span className={Style.logoText777}>777</span>
            </Link>

            <div className={Style.rightSideContainer}>
                <div className={Style.menuItemContainer}>
                    <a 
                        className={Style.menuItem}
                        href={ Config.ExternalLinks.TOKEN_EXCHANGE }
                        target="_blank"
                        rel="noreferrer">
                        { Language.BUY_G777 }
                    </a>

                    <a 
                        className={Style.menuItem}
                        href={ Config.ExternalLinks.OPENSEA_COLLECTION_URL }
                        target="_blank"
                        rel="noreferrer">
                        { Language.VIEW_NFT_COLLECTION }
                    </a>

                    <VerticalSpacer className={Style.verticalSpacer}/>

                    <Link 
                        className={Style.menuItem}
                        to="/sale">
                        { Language.PUBLIC_SALE }
                    </Link>

                    <Link 
                        className={Style.menuItem}
                        to="/vesting">
                        { Language.VESTING }
                    </Link>
                    
                    <Link 
                        className={Style.menuItem}
                        to="/faq">
                        FAQ
                    </Link>
                    
                    <Link 
                        className={Style.menuItem}
                        to="/about">
                        { Language.ABOUT }
                    </Link>
                    
                    <VerticalSpacer className={Style.verticalSpacer}/>
                </div>

                <LanguageDropdown
                    className={Style.languageDropdown}/>

                <LabeledIcon
                    className={Style.labeledIcon}
                    iconClassName={Style.metamaskIcon}
                    text={
                        address ?
                        Helpers.getTruncatedAddress(address) :
                        Language.CONNECT
                    }
                    shortText={
                        address ?
                        Helpers.getTruncatedAddress(address).substring(0, 4) :
                        Language.CONNECT
                    }
                    onClick={handleConnectCliced}
                    icon={
                        Web3Interface.getLastUsedProviderType() === 1 ?
                        WalletConnectIcon :
                        MetaMaskIcon
                    } />
            </div>

        </div>
    )
}

export default Header