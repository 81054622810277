import React from 'react'

function ClosePlainIcon({
    onClick,
    className
}) {
    return (
        <svg 
            className={className} 
            onClick={onClick}
            viewBox="0 0 24 24" 
            fill="none" 
            xmlns="http://www.w3.org/2000/svg">
            <rect x="4.00146" y="6.02588" width="1.45066" height="20.3093" transform="rotate(-45 4.00146 6.02588)" fill="#FFFEFE"/>
            <rect x="18.3608" y="5" width="1.45066" height="20.3093" transform="rotate(45 18.3608 5)" fill="#FFFEFE"/>
        </svg>
    )
}

export default ClosePlainIcon