import { ethers } from 'ethers';
import VestingABI from './ABI/Vesting.js';
import Config from '~/config.js';
import Language from '~/Language/index.js';

class Vesting {
    constructor(web3Interface, signer) {
        this.web3Interface = web3Interface;
        this.signer = signer;
        this.lastSparseUpdate = 0;
        this.isInitialized = true;
        this.isFocused = false;
        this.state = null;

        this.contract = new ethers.Contract(
            Config.Blockchain.Contracts.VESTING, 
            VestingABI, 
            signer
        );
    }

    async updateState() {
        if(!this.isFocused && this.state !== null)
            return;

        let newState = {}

        if(Date.now() > this.lastSparseUpdate + Config.Blockchain.SPARSE_UPDATE_RATE_MS || this.state === null) {
            this.lastSparseUpdate = Date.now();    
        }

        let userData = await this.contract.userData(this.web3Interface.address)
        newState.claimedVested = userData.claimedVestedTokens; 
        newState.claimedReward = userData.claimedRewardTokens; 
        newState.lockStatus = userData.lockStatus?.toNumber();

        newState.totalVested = await this.contract.getUserVestedTokens(this.web3Interface.address)

        newState.claimableVested = await this.contract.getClaimableVestedTokens(this.web3Interface.address)
        newState.claimableReward = await this.contract.getClaimableRewardTokens(this.web3Interface.address)

        if(!this.state)
            this.state = newState;
        else
            this.state = {...this.state, ...newState};

        return this.state;
    }
    
    clearState() {
        this.state = null;
    }

    async claimTokens() {
        let txData = await this.contract.claim()
    
        this.web3Interface.addPendingTransaction(
            txData.hash, 
            'VESTING_CLAIM', 
            Language.CLAIMING_TOKENS
        )

        return txData;
    }
}

export default Vesting;