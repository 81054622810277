import React from 'react'
import OpenSeaIcon from '~/Assets/Vectors/OpenSeaIcon'
import PolygonIcon from '~/Assets/Vectors/PolygonIcon'
import Language from '~/Language'
import Helpers from '~/helpers'
import Config from '~/config'
import Style from './RoundResultTableItem.module.css'

function RoundResultTableItem({
    round,
    date,
    jackpot,
    nftMint,
    passiveIncomeWinner,
    passiveIncomeValue,
    tokenPrice,
    txHash
}) {
    const handleViewTransaction = () => {
        window.open(Config.Blockchain.Network.TX_EXPLORER + txHash, '_blank');
    }

    const handleViewMintOpenSea = () => {
        window.open(Config.ExternalLinks.OPENSEA_ITEM_URL + nftMint, '_blank');
    }

    const handleViewPassiveIncomeOpenSea = () => {
        window.open(Config.ExternalLinks.OPENSEA_ITEM_URL + passiveIncomeWinner, '_blank');
    }

    return (
        <div className={Style.container}>
            <p className={Style.value}>
                { round }
            </p>

            <p className={Helpers.joinClasses(Style.value, Style.dateValue)}>
                { (new Date(date)).toLocaleDateString() }
            </p>

            <div className={Style.valueContainer}>    
                <p className={Style.value}>
                    { Helpers.formatValue(jackpot) } G777 ({ Helpers.formatUsdValue(jackpot * tokenPrice) })
                </p>

                <div className={Style.iconContainer} title={ Language.VIEW_TRANSACTION }>
                    <PolygonIcon 
                        className={Helpers.joinClasses(Style.icon, Style.secondaryIcon)}
                        onClick={handleViewTransaction}/>
                </div>
            </div>

            <div className={Helpers.joinClasses(Style.valueContainer, Style.mintValue)}>    
                <p className={Style.value}>
                    NFT #{ nftMint }
                </p>

                <div className={Style.iconContainer} title={Language.VIEW_ON_OPENSEA}>
                    <OpenSeaIcon 
                        className={Helpers.joinClasses(Style.icon, Style.secondaryIcon)}
                        onClick={handleViewMintOpenSea}/>
                </div>
            </div>

            <div className={Style.valueContainer}>    
                <p className={Style.value}>
                    { Helpers.formatValue(passiveIncomeValue) } G777 ({ Helpers.formatUsdValue(passiveIncomeValue * tokenPrice) })
                </p>

                <div className={Style.iconContainer} title={ Language.VIEW_TRANSACTION }>
                    <PolygonIcon 
                        className={Style.icon}
                        onClick={handleViewTransaction}/>
                </div>

                <div className={Style.iconContainer} title={Language.VIEW_ON_OPENSEA}>
                    <OpenSeaIcon 
                        className={Style.icon}
                        onClick={handleViewPassiveIncomeOpenSea}/>
                </div>
            </div>
        </div>
    )
}

export default RoundResultTableItem