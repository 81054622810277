import React, { Fragment, useEffect, useMemo, useState } from 'react'
import { BigNumber } from 'ethers'
import { useNavigate } from 'react-router-dom'
import { useWeb3PendingTransactions, useWeb3State, useWeb3Status } from '~/Context/Web3Context'
import { usePopupManager } from '~/Context/PopupManagerContext'
import Web3Interface from '~/Utilities/Web3Interface'
import ConnectWalletPopup from '~/Popups/ConnectWalletPopup'
import HorizontalSpacer from '~/Assets/Vectors/HorizontalSpacer'
import VerticalSpacer from '~/Assets/Vectors/VerticalSpacer'
import Loader from '~/Assets/Vectors/Loader'
import Button from '../Button'
import Language from '~/Language'
import Helpers from '~/helpers'
import Config from '~/config'
import Style from './PublicSaleControls.module.css'

function PublicSaleControls() {
    const routerNavigate = useNavigate()
    const state = useWeb3State();
    const allPendingTransactions = useWeb3PendingTransactions()
    const { isConnected, networkId } = useWeb3Status();
    const { showPopup } = usePopupManager();

    const [ inputValue, setInputValue ] = useState('0');
    const [ isValidInput, setIsValidInput ] = useState(false);
    const [ isBuying, setIsBuying ] = useState(false);

    useEffect(
        () => {
            try {
                let bnInputValue = Helpers.ethToWei(inputValue);
                let bnBalance = BigNumber.from(state.balance);
    
                setIsValidInput(
                    bnInputValue.gt(0) &&
                    bnInputValue.lte(bnBalance)
                )
            }
            catch (ex) {
                setIsValidInput(false)
            }
        },
        [ inputValue, state, setIsValidInput ]
    )

    const saleState = useMemo(
        () => {
            if(state && state.publicSale) {
                let isSaleOpen = state.publicSale.saleState === 1;
                let isSaleFinished = state.publicSale.saleState === 2;
                let endTime = 
                    isSaleOpen ? 
                    Config.Defaults.SALE_CLOSE_TIME :
                    Config.Defaults.SALE_OPEN_TIME;

                let timeDifference = (endTime - Date.now()) / 60000;
                let days = Math.floor(timeDifference / (24 * 60));
                let hours = Math.floor(timeDifference % (24 * 60) / 60);
                let minutes = Math.floor(timeDifference % 60);

                if(days < 0)
                    days = 0;

                if(hours < 0)
                    hours = 0;

                if(minutes < 0)
                    minutes = 0;

                return {
                    isSaleOpen,
                    isSaleFinished,
                    days,
                    hours,
                    minutes
                }
            }
            else {
                return {
                    isSaleOpen: false,
                    isSaleFinished: false,
                    days: '--',
                    hours: '--',
                    minutes: '--'
                }
            }
        },
        [state]
    )

    const vestedPrice = useMemo(
        () => {
            let initialLiquidityTokens = 7_777_777 * 0.4;
            let initialLiquidityUsd = 250_000;
            let k = initialLiquidityTokens * initialLiquidityUsd;
            
            let tvl = Helpers.weiToEth(state?.publicSale?.totalInvested);
            let tvlAfterSellOff = tvl * 0.8;

            let liquidityUsd = initialLiquidityUsd + tvlAfterSellOff;
            let liquidityTokens = k / liquidityUsd;

            return liquidityUsd / liquidityTokens;
        },
        [state]
    )

    const pendingTransactions = useMemo(
        () => {
            return (allPendingTransactions || []).filter(item =>
                item.tag.startsWith('PUBLIC_SALE')
            )
        },
        [ allPendingTransactions ]
    )

    const handleBuy = async () => {
        setIsBuying(true);
        try {
            await Web3Interface.publicSale.buyEth(Helpers.ethToWei(inputValue))
        }
        finally {
            setIsBuying(false);
        }
    }

    const handleUseMax = () => {
        setInputValue(Helpers.weiToEthString(state?.balance))
    }

    const handleConnect = () => {
        showPopup(ConnectWalletPopup, {})
    }

    const handleSwitchNetwork = () => {
        Web3Interface.requestNetworkSwitch(Config.Blockchain.Network.Configuration);
    }

    const handleGoToVesting = () => {
        routerNavigate("/vesting"); 
    }

    return (
        <div className={Style.container}>
            {
                (!isConnected || (networkId !== Config.Blockchain.Network.ID)) ?
                <div className={Style.messageOverlayContainer}>
                    <p className={Style.messageOverlayText}>
                        {
                            isConnected ?
                            Language.formatString(Language.PLEASE_SWITCH_TO_NETWORK, Config.Blockchain.Network.Configuration.chainName) :
                            Language.PLEASE_CONNECT_YOUR_WALLET
                        }
                    </p>

                    <Button
                        className={Style.messageOverlayButton}
                        onClick={
                            isConnected ?
                            handleSwitchNetwork :
                            handleConnect
                        }
                        text={
                            isConnected ?
                            Language.SWITCH_NETWORK :
                            Language.CONNECT_WALLET
                        }/>
                </div>
                :
                !state ?
                <div className={Style.messageOverlayContainer}>
                    <div className={Style.loaderContainer}>
                        <Loader className={Style.loader}/>
                        <p className={Style.messageOverlayText}>
                            { Language.FETCHING_DATA }
                        </p>
                    </div>
                </div>
                :
                pendingTransactions && pendingTransactions.length > 0 ?
                <div className={Style.messageOverlayContainer}>
                    <div className={Style.loaderContainer}>
                        <Loader className={Style.loader}/>
                        <p className={Style.messageOverlayText}>
                            { pendingTransactions[0].description }
                        </p>
                    </div>
                </div>
                :
                null
            }

            <div className={
                Helpers.conditionalClass(
                    !isConnected || networkId !== Config.Blockchain.Network.ID || !state || 
                    (pendingTransactions && pendingTransactions.length > 0),
                    Style.blockContainer,
                    Style.blurred
                )}>

                <div className={Style.row}>
                    <div className={Style.timeValueWrapper}>
                        <p className={Style.timeValue}>
                            { saleState.days }
                            <span className={Style.timeLabel}>
                                { Language.DAYS }
                            </span>
                        </p>
                    </div>

                    <VerticalSpacer height={74} width={1} useWhite/>
                    
                    <div className={Style.timeValueWrapper}>
                        <p className={Style.timeValue}>
                            { saleState.hours }
                            <span className={Style.timeLabel}>
                                { Language.HRS }
                            </span>
                        </p>
                    </div>

                    <VerticalSpacer height={74} width={1} useWhite/>
                    
                    <div className={Style.timeValueWrapper}>
                        <p className={Style.timeValue}>
                            { saleState.minutes }
                            <span className={Style.timeLabel}>
                                { Language.MINS }
                            </span>
                        </p>
                    </div>
                </div>

                {
                    (saleState?.isSaleOpen || saleState.isSaleFinished) &&
                    <Fragment>
                        <HorizontalSpacer width="100%" height={2}/>

                        <div className={Style.statColumns}>
                            <div className={Style.column}>
                                <p className={Style.stateValue}>
                                    { Helpers.formatUsdValue(Helpers.parseStablecoinValue(state?.publicSale?.totalInvested)) }
                                </p>
                                <p className={Style.stateLabel}>
                                    { Language.TOTAL_VALUE_LOCKED }
                                </p>
                            </div>
                            <div className={Style.column}>
                                <p className={Style.stateValue}>
                                    { Helpers.formatUsdValue(Helpers.parseStablecoinValue(state?.publicSale?.buyAmount)) }
                                </p>
                                <p className={Style.stateLabel}>
                                    { Language.YOUR_POSITION }
                                </p>
                            </div>
                            <div className={Style.column}>
                                <p className={Style.stateValue}>
                                    { Helpers.formatUsdValue(vestedPrice) } <span>*</span>
                                </p>
                                <p className={Style.stateLabel}>
                                    { Language.VESTED_PRICE }
                                </p>
                            </div>
                        </div>

                        <p className={Style.disclaimer}>
                            { Language.PRICE_DISCLAIMER }
                        </p>
                    </Fragment>
                }
            </div>

            <div>
                <div className={
                    Helpers.conditionalClass(
                        !isConnected || networkId !== Config.Blockchain.Network.ID || !state || 
                        (pendingTransactions && pendingTransactions.length > 0),
                        Style.blockContainer,
                        Style.blurred
                    )
                }>
                    {
                        !saleState?.isSaleOpen && !saleState?.isSaleFinished ?
                        <p className={Style.saleMessageText}>
                            { Language.formatString(Language.SALE_NOT_OPEN_YET, saleState.days, saleState.hours, saleState.minutes ) }
                        </p>
                        :
                        saleState?.isSaleFinished ?
                        <Fragment>
                            <p className={Style.saleMessageText}>
                                { Language.THE_SALE_HAS_FINISHED }
                            </p>

                            <div style={{height: 3}}/>

                            <Button
                                className={Style.buyButton}
                                loaderColor="#000"
                                onClick={handleGoToVesting}
                                text={ Language.GO_TO_VESTING_PAGE } />
                        </Fragment>
                        :
                        <Fragment>

                            <div className={Style.fieldRow}>
                                <p className={Style.currencyLabel}>
                                    MATIC:
                                </p>

                                <input
                                    className={Style.input}
                                    onChange={(event) => { setInputValue(event.target.value) }}
                                    autoFocus
                                    value={inputValue}
                                    type="text"/>
                            </div>

                            <div className={Style.fieldRow}>
                                <p className={Style.secondaryLabel}>
                                    { Language.BALANCE }
                                    <span 
                                        className={Style.maxLabel}
                                        onClick={handleUseMax}>
                                        { Helpers.weiToEth(state?.balance, 3) } (Max)
                                    </span>
                                </p>

                                <p className={Style.secondaryLabel}>
                                    ≈ { Helpers.formatUsdValue(parseFloat(inputValue) * Helpers.parseStablecoinValue(state?.coinPrice)) }
                                </p>
                            </div>

                            <Button
                                className={Style.buyButton}
                                loaderColor="#000"
                                onClick={handleBuy}
                                isLoading={isBuying}
                                disabled={!isValidInput}
                                text={
                                    isBuying ?
                                    Language.CREATING_POSITION :
                                    Language.CREATE_POSITION
                                } />

                            <HorizontalSpacer width="100%" height={1} className={Style.bottomSpacer}/>

                            <div className={Style.fieldRow}>
                                <p className={Style.positionLabel}>
                                    { Language.YOUR_POSITION }:
                                </p>

                                <p className={Style.positionValue}>
                                    { Helpers.formatValue(Helpers.parseStablecoinValue(state?.publicSale?.buyAmount), 3) + ' USDC' }
                                </p>
                            </div>
                        </Fragment>
                    }
                </div>
            </div>
        </div>
    )
}

export default PublicSaleControls