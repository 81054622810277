import React from 'react'
import { useNavigate } from 'react-router-dom';
import Style from './SideMenuItem.module.css'
import { cx } from '@emotion/css';

function SideMenuItem({
	text,
	icon,
	iconClassName,
	href,
	onClick,
	external
}) {
    const routerNavigate = useNavigate()

	const handleClick = () => {
		if(href) {
			if(href.startsWith('http'))
				window.open(href, '_blank');
			else
				routerNavigate(href)
		}
	}

	return (
		<div
			className={cx(Style.container, {[Style.externalLink]: external})}
			onClick={onClick || handleClick}
		>
			{ icon && React.createElement(icon, { className: iconClassName || Style.icon })}
			<p className={Style.text}>{ text }</p>
		</div>
	)
}

export default SideMenuItem