import { ethers } from 'ethers';
import SwapRouter from './SwapRouter';
import Airdrop from './Airdrop';
import Vesting from './Vesting';
import PublicSale from './PublicSale';
import StakingPool from './StakingPool';
import LiquidityFarm from './LiquidityFarm';
import Config from '../../config';
import Helpers from '~/helpers';

class Web3Interface {
    constructor() {
        this.injectedProvider = null;
        this.provider = null;
        this.signer = null;
        this.address = null;
        this.isConnected = false;
        this.currentNetwork = null;
        this.currentBlockNumber = null;
        this.lastRefreshBlock = 0;
        this.onConnectionStatusChangeCallback = null;
        this.onStateUpdateCallback = null;
        this.onAccountChangedCallback = null;
        this.onNewBlock = null;
        this.onNetworkChangedCallback = null;
        this.onProviderChangedCallback = null;
        this.onPendingTransactionListChangedCallback = null;
        this.isUpdatingTransactions = false;
        this.isInRefreshLock = false;
        this.lastTransactionUpdateTime = 0;
        this.focus = Focus.NONE;
        this.swapRouter = null;
        this.airdrop = null;
        this.vesting = null;
        this.publicSale = null;
        this.stakingPool = null;
        this.liquidityFarm = null;

        if(typeof window !== 'undefined') {
            if(this.getLastUsedProviderType() !== null)
                this.enable(this.getLastUsedProviderType(), true);
        }
    }

    getLastUsedProviderType() {
        let providerType = window.localStorage.___W3SP;
        return Number.isNaN(parseInt(providerType)) ? null : parseInt(providerType);
    }

    setLastUsedProviderType(providerType) {
        window.localStorage.___W3SP = providerType || '';
    }

    isMetaMaskAvailable() {
        return typeof window.ethereum !== 'undefined';
    }

    async enable(providerType, isReused) {
        if(this.onProviderChangedCallback)
            this.onProviderChangedCallback(providerType);

        if(providerType === Providers.WALLET_CONNECT) {
            const provider = new window.WalletConnectProvider.default({
                infuraId: Config.Blockchain.INFURA_ID,
                rpc: { '137': 'https://polygon-rpc.com/' }
            });
            await provider.enable();
            if(this.getLastUsedProviderType() === providerType && !isReused)
                await provider.wc.killSession()

            this.injectedProvider = provider;
            this.setLastUsedProviderType(providerType);
            await this.connect();
        }
        else {
            if(this.isMetaMaskAvailable()) {
                await window.ethereum.request({ method: 'eth_requestAccounts' });
                this.injectedProvider = window.ethereum;
                this.setLastUsedProviderType(providerType);
                await this.connect();
            }
            else {
                this.setLastUsedProviderType(null);
                this.injectedProvider = null;
            }
        }
    }

    async connect() {
        if(!this.injectedProvider)
            return;

        if(this.provider)
            this.provider.removeAllListeners()

        this.provider = new ethers.providers.Web3Provider(this.injectedProvider, "any");
        this.signer = this.provider.getSigner();
        this.instances = [];
        try {
            this.isConnected = true;
            this.address = await this.signer.getAddress();
            this.currentNetwork = (await this.provider.getNetwork()).chainId

            this.swapRouter = new SwapRouter(this, this.signer);
            this.airdrop = new Airdrop(this, this.signer);
            this.vesting = new Vesting(this, this.signer);
            this.publicSale = new PublicSale(this, this.signer, this.swapRouter);
            this.stakingPool = new StakingPool(this, this.signer);
            this.liquidityFarm = new LiquidityFarm(this, this.signer, this.swapRouter);
            this.setFocus(this.focus);

            this.injectedProvider.on('accountsChanged', (account) => {
                if(account.length === 0)
                    this.setLastUsedProviderType(null);

                this.state = null;
                if(this.onStateUpdateCallback)
                    this.onStateUpdateCallback(this.state);
                
                this.connect();
            });

            this.provider.on('network', (network) => {
                let didNetworkSwitch = this.currentNetwork !== network.chainId;
                this.currentNetwork = network.chainId;
                this.state = null;
                if(this.onNetworkChangedCallback)
                    this.onNetworkChangedCallback(network.chainId);

                if(didNetworkSwitch)
                    this.connect();
            });

            this.provider.on('block', (blockNumber) => {
                this.currentBlockNumber = blockNumber;
                if(this.currentNetwork === Config.Blockchain.Network.ID) {
                    this.updatePendingTransactions(blockNumber);
                    this.refreshState();
                }
                
                if(this.onNewBlock)
                    this.onNewBlock(blockNumber);
            });

            if(this.onConnectionStatusChangeCallback)
                this.onConnectionStatusChangeCallback(this.isConnected)

            if(this.onAccountChangedCallback)
                this.onAccountChangedCallback(this.address);

            if(this.onNetworkChangedCallback)
                this.onNetworkChangedCallback((await this.provider.getNetwork()).chainId);

            if(this.onPendingTransactionListChangedCallback)
                this.onPendingTransactionListChangedCallback(this.getPendingTransactions());

            if((await this.provider.getNetwork()).chainId === Config.Blockchain.Network.ID)
                this.refreshState();
        }
        catch(ex) {
            this.isConnected = false;
            this.signer = null;
            this.address = null;
            this.state = null;

            console.log('Web3Interface:connect()', ex)

            if(this.onConnectionStatusChangeCallback)
                this.onConnectionStatusChangeCallback(false)

            if(this.onAccountChangedCallback)
                this.onAccountChangedCallback(null);
        }
    }

    async requestNetworkSwitch(networkData) {
        try {
            await this.injectedProvider.request({
                method: 'wallet_switchEthereumChain',
                params: [{ chainId: networkData.chainId}]
            })

            return true;
        }
        catch(error) {
            if(error.code === 4902 || error.toString().includes('wallet_addEthereumChain')) {
                await this.injectedProvider.request({
                    method: 'wallet_addEthereumChain', 
                    params: [networkData]
                })
                return true;
            }
            return false;
        }
    }

    async refreshState() {
        if(this.isRefreshing || this.isInRefreshLock)
            return;

        this.isRefreshing = true;

        try {
            let swapRouterState = await this.swapRouter.updateState();
            let airdropState = await this.airdrop.updateState();
            let vestingState = await this.vesting.updateState();
            let publicSaleState = await this.publicSale.updateState();
            let stakingPoolState = await this.stakingPool.updateState();
            let liquidityFarmState = await this.liquidityFarm.updateState();

            let newState = {
                ...this.state,
                ...swapRouterState,
                balance: await this.getBalance()
            }

            if(airdropState)
                newState.airdrop = airdropState;

            if(vestingState)
                newState.vesting = vestingState;

            if(publicSaleState)
                newState.publicSale = publicSaleState;

            if(stakingPoolState)
                newState.stakingPool = stakingPoolState;

            if(liquidityFarmState)
                newState.liquidityFarm = liquidityFarmState;

            newState.pendingUpdate = {}

            this.state = newState;

            if(this.onStateUpdateCallback)
                this.onStateUpdateCallback(this.state);
        }
        catch(ex) {
            console.log('Web3Interface:refreshState()', ex)
        }
        finally {
            this.isRefreshing = false;
        }
    }

    setOnConnectionStatusChangeCallback(callback) {
        this.onConnectionStatusChangeCallback = callback;
        if(callback)
            callback(this.isConnected);
    }

    setOnStateUpdateCallback(callback) {
        this.onStateUpdateCallback = callback;
        if(callback)
            callback(this.state);
    }

    setOnNewBlockCallback(callback) {
        this.onNewBlock = callback;
    }

    setOnAccountChangedCallback(callback) {
        this.onAccountChangedCallback = callback;
        if(callback)
            callback(this.address);
    }

    setOnNetworkChangedCallback(callback) {
        this.onNetworkChangedCallback = callback;
    }

    setOnProviderChangedCallback(callback) {
        this.onProviderChangedCallback = callback;
        if(callback)
            callback(this.getLastUsedProviderType())
    }

    setOnPendingTransactionListChangedCallback(callback) {
        this.onPendingTransactionListChangedCallback = callback;
    }

    getPendingTransactions() {
        let storageTxList = 
            window.localStorage.___W3TX ?
            JSON.parse(window.localStorage.___W3TX) : [];

        return storageTxList instanceof Array ? storageTxList : [];
    }

    setPendingTransactions(txList) {
        if(!(txList instanceof Array))
            throw new Error('Transaction list must be an array');
        
        window.localStorage.___W3TX = JSON.stringify(txList);
    }

    addPendingTransaction(hash, tag, description, resource, network, timeout = 300000) {
        let storageTxList = this.getPendingTransactions();
        storageTxList.push({
            hash, 
            tag, 
            description, 
            resource: resource || null,
            network: network || this.currentNetwork,
            sentAt: Date.now(), 
            expiresAt: Date.now() + timeout
        });
        this.setPendingTransactions(storageTxList);
        
        if(this.onPendingTransactionListChangedCallback)
            this.onPendingTransactionListChangedCallback(storageTxList);
    }

    removePendingTransaction(hash) {
        let storageTxList = this.getPendingTransactions();
        storageTxList = storageTxList.filter(item => item.hash !== hash);
        this.setPendingTransactions(storageTxList);
        
        if(this.onPendingTransactionListChangedCallback)
            this.onPendingTransactionListChangedCallback(storageTxList);
    }

    async updatePendingTransactions() {
        if(this.isUpdatingTransactions || Date.now() - this.lastTransactionUpdateTime < Config.Blockchain.TX_UPDATE_RATE_MS )
            return;

        let txListUpdated = false;
        this.isUpdatingTransactions = true;
        this.lastTransactionUpdateTime = Date.now();

        try {
            let storageTxList = this.getPendingTransactions();
            if(storageTxList.length === 0) {
                this.isUpdatingTransactions = false;
                return txListUpdated;
            }

            let maxTxBlockNumber = 0;

            for(let txData of storageTxList) {
                let txReceipt = await this.provider.getTransactionReceipt(txData.hash);
                if((txReceipt && txReceipt.confirmations > 0) || txData.expiresAt < Date.now()) {
                    maxTxBlockNumber = maxTxBlockNumber < txReceipt.blockNumber ? txReceipt.blockNumber : maxTxBlockNumber;
                    storageTxList = storageTxList.filter(item => item.hash !== txData.hash);
                    txListUpdated = true;
                }
            }

            if(txListUpdated) {
                try {
                    this.isInRefreshLock = true;
                    let timeoutCount = 30;
                    while(
                        await this.provider.getBlockNumber() <= maxTxBlockNumber + 1 && 
                        timeoutCount > 0
                    ) {
                        await Helpers.sleep(1000);
                        timeoutCount--;
                    }
        
                    timeoutCount = 60;
                    while(this.isRefreshing && timeoutCount > 0) {
                        await Helpers.sleep(500);
                        timeoutCount--;
                    }
                }
                catch(ex) {}
                finally {
                    this.isInRefreshLock = false;
                }
 
                await this.refreshState()
                
                this.setPendingTransactions(storageTxList);
                if(this.onPendingTransactionListChangedCallback)
                    this.onPendingTransactionListChangedCallback(storageTxList);
            }
        }
        catch(ex) {}
        this.isUpdatingTransactions = false;
        return txListUpdated;
    }

    async getBalance() {
        return this.provider.getBalance(await this.signer.getAddress())
    }

    getToken() {
        return this.token;
    }

    getMarket() {
        return this.market;
    }

    setFocus(focus) {
        this.focus = focus;
        
        if(this.swapRouter)
            this.swapRouter.isFocused = focus !== Focus.NONE;
        
        if(this.airdrop)
            this.airdrop.isFocused = focus === Focus.VESTING;

        if(this.vesting)
            this.vesting.isFocused = focus === Focus.VESTING;
    
        if(this.publicSale)
            this.publicSale.isFocused = focus === Focus.VESTING;
        
        if(this.stakingPool)
            this.stakingPool.isFocused = focus === Focus.STAKING;
        
        if(this.liquidityFarm)
            this.liquidityFarm.isFocused = focus === Focus.STAKING;
    }
}

export const Providers = {
    UNDEFINED: 0,
    WALLET_CONNECT: 1,
    METAMASK: 2
}

export const Focus = {
    NONE: 0,
    STAKING: 1,
    VESTING: 2
}

const web3Interface = new Web3Interface();

export default web3Interface;