import React, { useEffect, useMemo } from 'react';
import { useLanguageTrigger } from '~/Context/LanguageContext';
import Web3Interface, { Focus } from '~/Utilities/Web3Interface';
import AboutUpperSection from '~/Components/AboutUpperSection';
import AboutMission from '~/Components/AboutMission';
import RoadmapItem from '~/Components/RoadmapItem';
import Header from '~/Components/Header/Header';
import Footer from '~/Components/Footer/Footer';
import Language from '~/Language';
import Config from '~/config';
import Style from "./About.module.css";

function About() {
    const languageTrigger = useLanguageTrigger();

    useEffect(() => {
        Web3Interface.setFocus(Focus.NONE);
    }, []);

    const roadmapEntries = useMemo(
        () => {
            let entryList = [];

            let rootKeys = Object
                .keys(Language)
                .filter(i => i.startsWith("__RM_") && i.endsWith("_T"))
                .map(i => i.replace('_T', '_'))

            for(let i=0; i<rootKeys.length; i++) {
                let rootKey = rootKeys[i];
                let title = Language[rootKey + 'T']
                let date = Language[rootKey + 'D']
                let paragraphs = Object
                    .keys(Language)
                    .filter(p => p.startsWith(rootKey + 'L'))
                    .map(p => Language[p])

                entryList.push({
                    title,
                    date,
                    paragraphs,
                    completed: Config.RoadmapEntryCompletedCount > i
                })
            }

            return entryList;
        },
        // eslint-disable-next-line
        [languageTrigger]
    )

    return (
        <div className={Style.container}>
            <Header />

            <AboutUpperSection
                title={ Language.ABOUT_GRAVEL_777 }
                description={ Language.ABOUT_GRAVEL_777_PARAGRAPH } />
        
            <AboutMission/>

            <div className={Style.roadmapContainer}>
                <p className={Style.roadmapTitle}>
                    { Language.OUR_ROADMAP }
                </p>

                {
                    roadmapEntries?.map((item, index, list) => 
                        <RoadmapItem 
                            key={index}
                            title={item.title}
                            date={item.date}
                            paragraphs={item.paragraphs}
                            upperLineStyle={
                                index === 0 ? RoadmapItem.LineStyles.NONE :
                                item.completed ? RoadmapItem.LineStyles.COMPLETED :
                                RoadmapItem.LineStyles.FUTURE
                            }
                            lowerLineStyle={
                                index === list.length - 1 ? RoadmapItem.LineStyles.NONE :
                                list[index + 1].completed ? RoadmapItem.LineStyles.COMPLETED :
                                RoadmapItem.LineStyles.FUTURE
                            }/>
                    )
                }
            </div>

            <div style={{ height: 30 }}/>
                
            <Footer/>
        </div>
    )
}

export default About;