import React from 'react';
import PopupContainer from '../PopupContainer';
import HorizontalSpacer from '~/Assets/Vectors/HorizontalSpacer';
import ClosePlainIcon from '~/Assets/Vectors/ClosePlainIcon';
import Style from './LegalPopup.module.css';

const LegalPopup = ({ isTOS, onClose, isOpen }) => {
	const handleClose = (confirm) => {
		if (onClose) onClose(confirm);
	};

	return (
		<PopupContainer
			isOpen={isOpen}
			onClose={handleClose.bind(null, false)}
			backdropColor='#0009'
		>
			<div className={Style.container}>
				<div className={Style.titleBarContainer}>
					<p className={Style.title}>
						{isTOS ? 'Terms of Service' : 'Privacy Policy'}
					</p>
					<ClosePlainIcon className={Style.closeIcon} onClick={handleClose} />
				</div>

				<HorizontalSpacer className={Style.upperSpacer} />

				{isTOS ? (
					<div className={Style.contentBox}>
						<p>
							<span>Last updated: 2023-01-01</span>
						</p>
						<p>
							<span>1. </span>
							<span>Introduction</span>
						</p>
						<p>
							<span>Welcome to </span>
							<span>Gravel 777</span>
							<span>
								&nbsp;(&ldquo;Company&rdquo;, &ldquo;we&rdquo;,
								&ldquo;our&rdquo;, &ldquo;us&rdquo;)!
							</span>
						</p>
						<p>
							<span>
								These Terms of Service (&ldquo;Terms&rdquo;, &ldquo;Terms of
								Service&rdquo;) govern your use of our website located at{' '}
							</span>
							<span>gravel777.com</span>
							<span>
								&nbsp;(together or individually &ldquo;Service&rdquo;) operated
								by{' '}
							</span>
							<span>Gravel 777</span>
							<span>.</span>
						</p>
						<p>
							<span>
								Our Privacy Policy also governs your use of our Service and
								explains how we collect, safeguard and disclose information that
								results from your use of our web pages.
							</span>
						</p>
						<p>
							<span>
								Your agreement with us includes these Terms and our Privacy
								Policy (&ldquo;Agreements&rdquo;). You acknowledge that you have
								read and understood Agreements, and agree to be bound of them.
							</span>
						</p>
						<p>
							<span>
								If you do not agree with (or cannot comply with) Agreements,
								then you may not use the Service, but please let us know by
								emailing at{' '}
							</span>
							<span>
								<a href='mailto:hello@gravel777.io'>hello@gravel777.io</a>
							</span>
							<span>
								&nbsp;so we can try to find a solution. These Terms apply to all
								visitors, users and others who wish to access or use Service.
							</span>
						</p>
						<p>
							<span>2. </span>
							<span>Communications</span>
						</p>
						<p>
							<span>
								By using our Service, you agree to subscribe to newsletters,
								marketing or promotional materials and other information we may
								send. However, you may opt out of receiving any, or all, of
								these communications from us by following the unsubscribe link
								or by emailing at{' '}
								<a href='mailto:hello@gravel777.io'>hello@gravel777.io</a>.
							</span>
						</p>
						<p>
							<span>3. </span>
							<span>Contests, Sweepstakes and Promotions</span>
						</p>
						<p>
							<span>
								Any contests, sweepstakes or other promotions (collectively,
								&ldquo;Promotions&rdquo;) made available through Service may be
								governed by rules that are separate from these Terms of Service.
								If you participate in any Promotions, please review the
								applicable rules as well as our Privacy Policy. If the rules for
								a Promotion conflict with these Terms of Service, Promotion
								rules will apply.
							</span>
						</p>
						<p>
							<span>4. </span>
							<span>Content</span>
						</p>
						<p>
							<span>
								Content found on or through this Service are the property of
								Gravel 777 or used with permission. You may not distribute,
								modify, transmit, reuse, download, repost, copy, or use said
								Content, whether in whole or in part, for commercial purposes or
								for personal gain, without express advance written permission
								from us.
							</span>
						</p>
						<p>
							<span>5. </span>
							<span>Prohibited Uses</span>
						</p>
						<p>
							<span>
								You may use Service only for lawful purposes and in accordance
								with Terms. You agree not to use Service:
							</span>
						</p>
						<p>
							<span>
								0.1. In any way that violates any applicable national or
								international law or regulation.
							</span>
						</p>
						<p>
							<span>
								0.2. For the purpose of exploiting, harming, or attempting to
								exploit or harm minors in any way by exposing them to
								inappropriate content or otherwise.
							</span>
						</p>
						<p>
							<span>
								0.3. To transmit, or procure the sending of, any advertising or
								promotional material, including any &ldquo;junk mail&rdquo;,
								&ldquo;chain letter,&rdquo; &ldquo;spam,&rdquo; or any other
								similar solicitation.
							</span>
						</p>
						<p>
							<span>
								0.4. To impersonate or attempt to impersonate Company, a Company
								employee, another user, or any other person or entity.
							</span>
						</p>
						<p>
							<span>
								0.5. In any way that infringes upon the rights of others, or in
								any way is illegal, threatening, fraudulent, or harmful, or in
								connection with any unlawful, illegal, fraudulent, or harmful
								purpose or activity.
							</span>
						</p>
						<p>
							<span>
								0.6. To engage in any other conduct that restricts or inhibits
								anyone&rsquo;s use or enjoyment of Service, or which, as
								determined by us, may harm or offend Company or users of Service
								or expose them to liability.
							</span>
						</p>
						<p>
							<span>Additionally, you agree not to:</span>
						</p>
						<p>
							<span>
								0.1. Use Service in any manner that could disable, overburden,
								damage, or impair Service or interfere with any other
								party&rsquo;s use of Service, including their ability to engage
								in real time activities through Service.
							</span>
						</p>
						<p>
							<span>
								0.2. Use any robot, spider, or other automatic device, process,
								or means to access Service for any purpose, including monitoring
								or copying any of the material on Service.
							</span>
						</p>
						<p>
							<span>
								0.3. Use any manual process to monitor or copy any of the
								material on Service or for any other unauthorized purpose
								without our prior written consent.
							</span>
						</p>
						<p>
							<span>
								0.4. Use any device, software, or routine that interferes with
								the proper working of Service.
							</span>
						</p>
						<p>
							<span>
								0.5. Introduce any viruses, trojan horses, worms, logic bombs,
								or other material which is malicious or technologically harmful.
							</span>
						</p>
						<p>
							<span>
								0.6. Attempt to gain unauthorized access to, interfere with,
								damage, or disrupt any parts of Service, the server on which
								Service is stored, or any server, computer, or database
								connected to Service.
							</span>
						</p>
						<p>
							<span>
								0.7. Attack Service via a denial-of-service attack or a
								distributed denial-of-service attack.
							</span>
						</p>
						<p>
							<span>
								0.8. Take any action that may damage or falsify Company rating.
							</span>
						</p>
						<p>
							<span>
								0.9. Otherwise attempt to interfere with the proper working of
								Service.
							</span>
						</p>
						<p>
							<span>6. </span>
							<span>Analytics</span>
						</p>
						<p>
							<span>
								We may use third-party Service Providers to monitor and analyze
								the use of our Service.
							</span>
						</p>
						<p>
							<span>7. </span>
							<span>No Use By Minors</span>
						</p>
						<p>
							<span>
								Service is intended only for access and use by individuals at
								least eighteen (18) years old. By accessing or using Service,
								you warrant and represent that you are at least eighteen (18)
								years of age and with the full authority, right, and capacity to
								enter into this agreement and abide by all of the terms and
								conditions of Terms. If you are not at least eighteen (18) years
								old, you are prohibited from both the access and usage of
								Service.
							</span>
						</p>
						<p>
							<span>8. </span>
							<span>Intellectual Property</span>
						</p>
						<p>
							<span>
								Service and its original content (excluding Content provided by
								users), features and functionality are and will remain the
								exclusive property of Gravel 777 and its licensors. Service is
								protected by copyright, trademark, and other laws of and foreign
								countries. Our trademarks may not be used in connection with any
								product or service without the prior written consent of Gravel
								777.
							</span>
						</p>
						<p>
							<span>9. </span>
							<span>Copyright Policy</span>
						</p>
						<p>
							<span>
								We respect the intellectual property rights of others. It is our
								policy to respond to any claim that Content posted on Service
								infringes on the copyright or other intellectual property rights
								(&ldquo;Infringement&rdquo;) of any person or entity.
							</span>
						</p>
						<p>
							<span>
								If you are a copyright owner, or authorized on behalf of one,
								and you believe that the copyrighted work has been copied in a
								way that constitutes copyright infringement, please submit your
								claim via email to{' '}
								<a href='mailto:hello@gravel777.io'>hello@gravel777.io</a>, with
								the subject line: &ldquo;Copyright Infringement&rdquo; and
								include in your claim a detailed description of the alleged
								Infringement as detailed below, under &ldquo;DMCA Notice and
								Procedure for Copyright Infringement Claims&rdquo;
							</span>
						</p>
						<p>
							<span>
								You may be held accountable for damages (including costs and
								attorneys&rsquo; fees) for misrepresentation or bad-faith claims
								on the infringement of any Content found on and/or through
								Service on your copyright.
							</span>
						</p>
						<p>
							<span>10. </span>
							<span>
								DMCA Notice and Procedure for Copyright Infringement Claims
							</span>
						</p>
						<p>
							<span>
								You may submit a notification pursuant to the Digital Millennium
								Copyright Act (DMCA) by providing our Copyright Agent with the
								following information in writing (see 17 U.S.C 512(c)(3) for
								further detail):
							</span>
						</p>
						<p>
							<span>
								0.1. an electronic or physical signature of the person
								authorized to act on behalf of the owner of the
								copyright&rsquo;s interest;
							</span>
						</p>
						<p>
							<span>
								0.2. a description of the copyrighted work that you claim has
								been infringed, including the URL (i.e., web page address) of
								the location where the copyrighted work exists or a copy of the
								copyrighted work;
							</span>
						</p>
						<p>
							<span>
								0.3. identification of the URL or other specific location on
								Service where the material that you claim is infringing is
								located;
							</span>
						</p>
						<p>
							<span>
								0.4. your address, telephone number, and email address;
							</span>
						</p>
						<p>
							<span>
								0.5. a statement by you that you have a good faith belief that
								the disputed use is not authorized by the copyright owner, its
								agent, or the law;
							</span>
						</p>
						<p>
							<span>
								0.6. a statement by you, made under penalty of perjury, that the
								above information in your notice is accurate and that you are
								the copyright owner or authorized to act on the copyright
								owner&rsquo;s behalf.
							</span>
						</p>
						<p>
							<span>
								You can contact our Copyright Agent via email at
								<a href='mailto:hello@gravel777.io'>hello@gravel777.io</a>.
							</span>
						</p>
						<p>
							<span>11. </span>
							<span>Error Reporting and Feedback</span>
						</p>
						<p>
							<span>
								You may provide us either directly at{' '}
								<a href='mailto:hello@gravel777.io'>hello@gravel777.io</a> or
								via third party sites and tools with information and feedback
								concerning errors, suggestions for improvements, ideas,
								problems, complaints, and other matters related to our Service
								(&ldquo;Feedback&rdquo;). You acknowledge and agree that: (i)
								you shall not retain, acquire or assert any intellectual
								property right or other right, title or interest in or to the
								Feedback; (ii) Company may have development ideas similar to the
								Feedback; (iii) Feedback does not contain confidential
								information or proprietary information from you or any third
								party; and (iv) Company is not under any obligation of
								confidentiality with respect to the Feedback. In the event the
								transfer of the ownership to the Feedback is not possible due to
								applicable mandatory laws, you grant Company and its affiliates
								an exclusive, transferable, irrevocable, free-of-charge,
								sub-licensable, unlimited and perpetual right to use (including
								copy, modify, create derivative works, publish, distribute and
								commercialize) Feedback in any manner and for any purpose.
							</span>
						</p>
						<p>
							<span>12. </span>
							<span>Links To Other Web Sites</span>
						</p>
						<p>
							<span>
								Our Service may contain links to third party web sites or
								services that are not owned or controlled by Gravel 777.
							</span>
						</p>
						<p>
							<span>
								Gravel 777 has no control over, and assumes no responsibility
								for the content, privacy policies, or practices of any third
								party web sites or services. We do not warrant the offerings of
								any of these entities/individuals or their websites.
							</span>
						</p>
						<p>
							<span>
								YOU ACKNOWLEDGE AND AGREE THAT COMPANY SHALL NOT BE RESPONSIBLE
								OR LIABLE, DIRECTLY OR INDIRECTLY, FOR ANY DAMAGE OR LOSS CAUSED
								OR ALLEGED TO BE CAUSED BY OR IN CONNECTION WITH USE OF OR
								RELIANCE ON ANY SUCH CONTENT, GOODS OR SERVICES AVAILABLE ON OR
								THROUGH ANY SUCH THIRD PARTY WEB SITES OR SERVICES.
							</span>
						</p>
						<p>
							<span>
								WE STRONGLY ADVISE YOU TO READ THE TERMS OF SERVICE AND PRIVACY
								POLICIES OF ANY THIRD PARTY WEB SITES OR SERVICES THAT YOU
								VISIT.
							</span>
						</p>
						<p>
							<span>13. </span>
							<span>Disclaimer Of Warranty</span>
						</p>
						<p>
							<span>
								THESE SERVICES ARE PROVIDED BY COMPANY ON AN &ldquo;AS IS&rdquo;
								AND &ldquo;AS AVAILABLE&rdquo; BASIS. COMPANY MAKES NO
								REPRESENTATIONS OR WARRANTIES OF ANY KIND, EXPRESS OR IMPLIED,
								AS TO THE OPERATION OF THEIR SERVICES, OR THE INFORMATION,
								CONTENT OR MATERIALS INCLUDED THEREIN. YOU EXPRESSLY AGREE THAT
								YOUR USE OF THESE SERVICES, THEIR CONTENT, AND ANY SERVICES OR
								ITEMS OBTAINED FROM US IS AT YOUR SOLE RISK.
							</span>
						</p>
						<p>
							<span>
								NEITHER COMPANY NOR ANY PERSON ASSOCIATED WITH COMPANY MAKES ANY
								WARRANTY OR REPRESENTATION WITH RESPECT TO THE COMPLETENESS,
								SECURITY, RELIABILITY, QUALITY, ACCURACY, OR AVAILABILITY OF THE
								SERVICES. WITHOUT LIMITING THE FOREGOING, NEITHER COMPANY NOR
								ANYONE ASSOCIATED WITH COMPANY REPRESENTS OR WARRANTS THAT THE
								SERVICES, THEIR CONTENT, OR ANY SERVICES OR ITEMS OBTAINED
								THROUGH THE SERVICES WILL BE ACCURATE, RELIABLE, ERROR-FREE, OR
								UNINTERRUPTED, THAT DEFECTS WILL BE CORRECTED, THAT THE SERVICES
								OR THE SERVER THAT MAKES IT AVAILABLE ARE FREE OF VIRUSES OR
								OTHER HARMFUL COMPONENTS OR THAT THE SERVICES OR ANY SERVICES OR
								ITEMS OBTAINED THROUGH THE SERVICES WILL OTHERWISE MEET YOUR
								NEEDS OR EXPECTATIONS.
							</span>
						</p>
						<p>
							<span>
								COMPANY HEREBY DISCLAIMS ALL WARRANTIES OF ANY KIND, WHETHER
								EXPRESS OR IMPLIED, STATUTORY, OR OTHERWISE, INCLUDING BUT NOT
								LIMITED TO ANY WARRANTIES OF MERCHANTABILITY, NON-INFRINGEMENT,
								AND FITNESS FOR PARTICULAR PURPOSE.
							</span>
						</p>
						<p>
							<span>
								THE FOREGOING DOES NOT AFFECT ANY WARRANTIES WHICH CANNOT BE
								EXCLUDED OR LIMITED UNDER APPLICABLE LAW.
							</span>
						</p>
						<p>
							<span>14. </span>
							<span>Limitation Of Liability</span>
						</p>
						<p>
							<span>
								EXCEPT AS PROHIBITED BY LAW, YOU WILL HOLD US AND OUR OFFICERS,
								DIRECTORS, EMPLOYEES, AND AGENTS HARMLESS FOR ANY INDIRECT,
								PUNITIVE, SPECIAL, INCIDENTAL, OR CONSEQUENTIAL DAMAGE, HOWEVER
								IT ARISES (INCLUDING ATTORNEYS&rsquo; FEES AND ALL RELATED COSTS
								AND EXPENSES OF LITIGATION AND ARBITRATION, OR AT TRIAL OR ON
								APPEAL, IF ANY, WHETHER OR NOT LITIGATION OR ARBITRATION IS
								INSTITUTED), WHETHER IN AN ACTION OF CONTRACT, NEGLIGENCE, OR
								OTHER TORTIOUS ACTION, OR ARISING OUT OF OR IN CONNECTION WITH
								THIS AGREEMENT, INCLUDING WITHOUT LIMITATION ANY CLAIM FOR
								PERSONAL INJURY OR PROPERTY DAMAGE, ARISING FROM THIS AGREEMENT
								AND ANY VIOLATION BY YOU OF ANY FEDERAL, STATE, OR LOCAL LAWS,
								STATUTES, RULES, OR REGULATIONS, EVEN IF COMPANY HAS BEEN
								PREVIOUSLY ADVISED OF THE POSSIBILITY OF SUCH DAMAGE. EXCEPT AS
								PROHIBITED BY LAW, IF THERE IS LIABILITY FOUND ON THE PART OF
								COMPANY, IT WILL BE LIMITED TO THE AMOUNT PAID FOR THE PRODUCTS
								AND/OR SERVICES, AND UNDER NO CIRCUMSTANCES WILL THERE BE
								CONSEQUENTIAL OR PUNITIVE DAMAGES. SOME STATES DO NOT ALLOW THE
								EXCLUSION OR LIMITATION OF PUNITIVE, INCIDENTAL OR CONSEQUENTIAL
								DAMAGES, SO THE PRIOR LIMITATION OR EXCLUSION MAY NOT APPLY TO
								YOU.
							</span>
						</p>
						<p>
							<span>15. </span>
							<span>Termination</span>
						</p>
						<p>
							<span>
								We may terminate or suspend your account and bar access to
								Service immediately, without prior notice or liability, under
								our sole discretion, for any reason whatsoever and without
								limitation, including but not limited to a breach of Terms.
							</span>
						</p>
						<p>
							<span>
								If you wish to terminate your account, you may simply
								discontinue using Service.
							</span>
						</p>
						<p>
							<span>
								All provisions of Terms which by their nature should survive
								termination shall survive termination, including, without
								limitation, ownership provisions, warranty disclaimers,
								indemnity and limitations of liability.
							</span>
						</p>
						<p>
							<span>16. </span>
							<span>Governing Law</span>
						</p>
						<p>
							<span>
								These Terms shall be governed and construed in accordance with
								the laws of Cyprus, which governing law applies to agreement
								without regard to its conflict of law provisions.
							</span>
						</p>
						<p>
							<span>
								Our failure to enforce any right or provision of these Terms
								will not be considered a waiver of those rights. If any
								provision of these Terms is held to be invalid or unenforceable
								by a court, the remaining provisions of these Terms will remain
								in effect. These Terms constitute the entire agreement between
								us regarding our Service and supersede and replace any prior
								agreements we might have had between us regarding Service.
							</span>
						</p>
						<p>
							<span>17. </span>
							<span>Changes To Service</span>
						</p>
						<p>
							<span>
								We reserve the right to withdraw or amend our Service, and any
								service or material we provide via Service, in our sole
								discretion without notice. We will not be liable if for any
								reason all or any part of Service is unavailable at any time or
								for any period. From time to time, we may restrict access to
								some parts of Service, or the entire Service, to users,
								including registered users.
							</span>
						</p>
						<p>
							<span>18. </span>
							<span>Amendments To Terms</span>
						</p>
						<p>
							<span>
								We may amend Terms at any time by posting the amended terms on
								this site. It is your responsibility to review these Terms
								periodically.
							</span>
						</p>
						<p>
							<span>
								Your continued use of the Platform following the posting of
								revised Terms means that you accept and agree to the changes.
								You are expected to check this page frequently so you are aware
								of any changes, as they are binding on you.
							</span>
						</p>
						<p>
							<span>
								By continuing to access or use our Service after any revisions
								become effective, you agree to be bound by the revised terms. If
								you do not agree to the new terms, you are no longer authorized
								to use Service.
							</span>
						</p>
						<p>
							<span>19. </span>
							<span>Waiver And Severability</span>
						</p>
						<p>
							<span>
								No waiver by Company of any term or condition set forth in Terms
								shall be deemed a further or continuing waiver of such term or
								condition or a waiver of any other term or condition, and any
								failure of Company to assert a right or provision under Terms
								shall not constitute a waiver of such right or provision.
							</span>
						</p>
						<p>
							<span>
								If any provision of Terms is held by a court or other tribunal
								of competent jurisdiction to be invalid, illegal or
								unenforceable for any reason, such provision shall be eliminated
								or limited to the minimum extent such that the remaining
								provisions of Terms will continue in full force and effect.
							</span>
						</p>
						<p>
							<span>20. </span>
							<span>Acknowledgement</span>
						</p>
						<p>
							<span>
								BY USING SERVICE OR OTHER SERVICES PROVIDED BY US, YOU
								ACKNOWLEDGE THAT YOU HAVE READ THESE TERMS OF SERVICE AND AGREE
								TO BE BOUND BY THEM.
							</span>
						</p>
						<p>
							<span>21. </span>
							<span>Contact Us</span>
						</p>
						<p>
							<span>
								Please send your feedback, comments, requests for technical
								support by email:
							</span>
							<span>
								<a href='mailto:hello@gravel777.io'>hello@gravel777.io</a>
							</span>
							<span>.</span>
						</p>
					</div>
				) : (
					<div className={Style.contentBox}>
						<h1>Privacy Policy</h1>
						<p>Last updated: December 11, 2022</p>
						<p>
							This Privacy Policy describes Our policies and procedures on the
							collection, use and disclosure of Your information when You use
							the Service and tells You about Your privacy rights and how the
							law protects You.
						</p>
						<p>
							We use Your Personal data to provide and improve the Service. By
							using the Service, You agree to the collection and use of
							information in accordance with this Privacy Policy.
						</p>
						<h1>Interpretation and Definitions</h1>
						<h2>Interpretation</h2>
						<p>
							The words of which the initial letter is capitalized have meanings
							defined under the following conditions. The following definitions
							shall have the same meaning regardless of whether they appear in
							singular or in plural.
						</p>
						<h2>Definitions</h2>
						<p>For the purposes of this Privacy Policy:</p>
						<ul>
							<li>
								<p>
									<strong>Account</strong> means a unique account created for
									You to access our Service or parts of our Service.
								</p>
							</li>
							<li>
								<p>
									<strong>Company</strong> (referred to as either &quot;the
									Company&quot;, &quot;We&quot;, &quot;Us&quot; or
									&quot;Our&quot; in this Agreement) refers to Innou,
									Themistokli Dervi 5, 1066 Nicosia.
								</p>
							</li>
							<li>
								<p>
									<strong>Cookies</strong> are small files that are placed on
									Your computer, mobile device or any other device by a website,
									containing the details of Your browsing history on that
									website among its many uses.
								</p>
							</li>
							<li>
								<p>
									<strong>Country</strong> refers to: Cyprus
								</p>
							</li>
							<li>
								<p>
									<strong>Device</strong> means any device that can access the
									Service such as a computer, a cellphone or a digital tablet.
								</p>
							</li>
							<li>
								<p>
									<strong>Personal Data</strong> is any information that relates
									to an identified or identifiable individual.
								</p>
							</li>
							<li>
								<p>
									<strong>Service</strong> refers to the Website.
								</p>
							</li>
							<li>
								<p>
									<strong>Service Provider</strong> means any natural or legal
									person who processes the data on behalf of the Company. It
									refers to third-party companies or individuals employed by the
									Company to facilitate the Service, to provide the Service on
									behalf of the Company, to perform services related to the
									Service or to assist the Company in analyzing how the Service
									is used.
								</p>
							</li>
							<li>
								<p>
									<strong>Usage Data</strong> refers to data collected
									automatically, either generated by the use of the Service or
									from the Service infrastructure itself (for example, the
									duration of a page visit).
								</p>
							</li>
							<li>
								<p>
									<strong>Website</strong> refers to Gravel 777, accessible from{' '}
									<a
										href='https://gravel777.com'
										rel='noreferrer'
										target='_blank'
									>
										https://gravel777.com
									</a>
								</p>
							</li>
							<li>
								<p>
									<strong>You</strong> means the individual accessing or using
									the Service, or the company, or other legal entity on behalf
									of which such individual is accessing or using the Service, as
									applicable.
								</p>
							</li>
						</ul>
						<h1>Collecting and Using Your Personal Data</h1>
						<h2>Types of Data Collected</h2>
						<h3>Personal Data</h3>
						<p>
							While using Our Service, We may ask You to provide Us with certain
							personally identifiable information that can be used to contact or
							identify You. Personally identifiable information may include, but
							is not limited to:
						</p>
						<ul>
							<li>
								<p>Email address</p>
							</li>
							<li>
								<p>Usage Data</p>
							</li>
						</ul>
						<h3>Usage Data</h3>
						<p>Usage Data is collected automatically when using the Service.</p>
						<p>
							Usage Data may include information such as Your Device's Internet
							Protocol address (e.g. IP address), browser type, browser version,
							the pages of our Service that You visit, the time and date of Your
							visit, the time spent on those pages, unique device identifiers
							and other diagnostic data.
						</p>
						<p>
							When You access the Service by or through a mobile device, We may
							collect certain information automatically, including, but not
							limited to, the type of mobile device You use, Your mobile device
							unique ID, the IP address of Your mobile device, Your mobile
							operating system, the type of mobile Internet browser You use,
							unique device identifiers and other diagnostic data.
						</p>
						<p>
							We may also collect information that Your browser sends whenever
							You visit our Service or when You access the Service by or through
							a mobile device.
						</p>
						<h3>Tracking Technologies and Cookies</h3>
						<p>
							We use Cookies and similar tracking technologies to track the
							activity on Our Service and store certain information. Tracking
							technologies used are beacons, tags, and scripts to collect and
							track information and to improve and analyze Our Service. The
							technologies We use may include:
						</p>
						<ul>
							<li>
								<strong>Cookies or Browser Cookies.</strong> A cookie is a small
								file placed on Your Device. You can instruct Your browser to
								refuse all Cookies or to indicate when a Cookie is being sent.
								However, if You do not accept Cookies, You may not be able to
								use some parts of our Service. Unless you have adjusted Your
								browser setting so that it will refuse Cookies, our Service may
								use Cookies.
							</li>
							<li>
								<strong>Web Beacons.</strong> Certain sections of our Service
								and our emails may contain small electronic files known as web
								beacons (also referred to as clear gifs, pixel tags, and
								single-pixel gifs) that permit the Company, for example, to
								count users who have visited those pages or opened an email and
								for other related website statistics (for example, recording the
								popularity of a certain section and verifying system and server
								integrity).
							</li>
						</ul>
						<p>
							Cookies can be &quot;Persistent&quot; or &quot;Session&quot;
							Cookies. Persistent Cookies remain on Your personal computer or
							mobile device when You go offline, while Session Cookies are
							deleted as soon as You close Your web browser. You can learn more
							about cookies on{' '}
							<a
								href='https://www.termsfeed.com/blog/cookies/#What_Are_Cookies'
								rel='noreferrer'
								target='_blank'
							>
								TermsFeed website
							</a>{' '}
							article.
						</p>
						<p>
							We use both Session and Persistent Cookies for the purposes set
							out below:
						</p>
						<ul>
							<li>
								<p>
									<strong>Necessary / Essential Cookies</strong>
								</p>
								<p>Type: Session Cookies</p>
								<p>Administered by: Us</p>
								<p>
									Purpose: These Cookies are essential to provide You with
									services available through the Website and to enable You to
									use some of its features. They help to authenticate users and
									prevent fraudulent use of user accounts. Without these
									Cookies, the services that You have asked for cannot be
									provided, and We only use these Cookies to provide You with
									those services.
								</p>
							</li>
							<li>
								<p>
									<strong>Cookies Policy / Notice Acceptance Cookies</strong>
								</p>
								<p>Type: Persistent Cookies</p>
								<p>Administered by: Us</p>
								<p>
									Purpose: These Cookies identify if users have accepted the use
									of cookies on the Website.
								</p>
							</li>
							<li>
								<p>
									<strong>Functionality Cookies</strong>
								</p>
								<p>Type: Persistent Cookies</p>
								<p>Administered by: Us</p>
								<p>
									Purpose: These Cookies allow us to remember choices You make
									when You use the Website, such as remembering your login
									details or language preference. The purpose of these Cookies
									is to provide You with a more personal experience and to avoid
									You having to re-enter your preferences every time You use the
									Website.
								</p>
							</li>
						</ul>
						<p>
							For more information about the cookies we use and your choices
							regarding cookies, please visit our Cookies Policy or the Cookies
							section of our Privacy Policy.
						</p>
						<h2>Use of Your Personal Data</h2>
						<p>The Company may use Personal Data for the following purposes:</p>
						<ul>
							<li>
								<p>
									<strong>To provide and maintain our Service</strong>,
									including to monitor the usage of our Service.
								</p>
							</li>
							<li>
								<p>
									<strong>To manage Your Account:</strong> to manage Your
									registration as a user of the Service. The Personal Data You
									provide can give You access to different functionalities of
									the Service that are available to You as a registered user.
								</p>
							</li>
							<li>
								<p>
									<strong>For the performance of a contract:</strong> the
									development, compliance and undertaking of the purchase
									contract for the products, items or services You have
									purchased or of any other contract with Us through the
									Service.
								</p>
							</li>
							<li>
								<p>
									<strong>To contact You:</strong> To contact You by email,
									telephone calls, SMS, or other equivalent forms of electronic
									communication, such as a mobile application's push
									notifications regarding updates or informative communications
									related to the functionalities, products or contracted
									services, including the security updates, when necessary or
									reasonable for their implementation.
								</p>
							</li>
							<li>
								<p>
									<strong>To provide You</strong> with news, special offers and
									general information about other goods, services and events
									which we offer that are similar to those that you have already
									purchased or enquired about unless You have opted not to
									receive such information.
								</p>
							</li>
							<li>
								<p>
									<strong>To manage Your requests:</strong> To attend and manage
									Your requests to Us.
								</p>
							</li>
							<li>
								<p>
									<strong>For business transfers:</strong> We may use Your
									information to evaluate or conduct a merger, divestiture,
									restructuring, reorganization, dissolution, or other sale or
									transfer of some or all of Our assets, whether as a going
									concern or as part of bankruptcy, liquidation, or similar
									proceeding, in which Personal Data held by Us about our
									Service users is among the assets transferred.
								</p>
							</li>
							<li>
								<p>
									<strong>For other purposes</strong>: We may use Your
									information for other purposes, such as data analysis,
									identifying usage trends, determining the effectiveness of our
									promotional campaigns and to evaluate and improve our Service,
									products, services, marketing and your experience.
								</p>
							</li>
						</ul>
						<p>
							We may share Your personal information in the following
							situations:
						</p>
						<ul>
							<li>
								<strong>With Service Providers:</strong> We may share Your
								personal information with Service Providers to monitor and
								analyze the use of our Service, to contact You.
							</li>
							<li>
								<strong>For business transfers:</strong> We may share or
								transfer Your personal information in connection with, or during
								negotiations of, any merger, sale of Company assets, financing,
								or acquisition of all or a portion of Our business to another
								company.
							</li>
							<li>
								<strong>With Affiliates:</strong> We may share Your information
								with Our affiliates, in which case we will require those
								affiliates to honor this Privacy Policy. Affiliates include Our
								parent company and any other subsidiaries, joint venture
								partners or other companies that We control or that are under
								common control with Us.
							</li>
							<li>
								<strong>With business partners:</strong> We may share Your
								information with Our business partners to offer You certain
								products, services or promotions.
							</li>
							<li>
								<strong>With other users:</strong> when You share personal
								information or otherwise interact in the public areas with other
								users, such information may be viewed by all users and may be
								publicly distributed outside.
							</li>
							<li>
								<strong>With Your consent</strong>: We may disclose Your
								personal information for any other purpose with Your consent.
							</li>
						</ul>
						<h2>Retention of Your Personal Data</h2>
						<p>
							The Company will retain Your Personal Data only for as long as is
							necessary for the purposes set out in this Privacy Policy. We will
							retain and use Your Personal Data to the extent necessary to
							comply with our legal obligations (for example, if we are required
							to retain your data to comply with applicable laws), resolve
							disputes, and enforce our legal agreements and policies.
						</p>
						<p>
							The Company will also retain Usage Data for internal analysis
							purposes. Usage Data is generally retained for a shorter period of
							time, except when this data is used to strengthen the security or
							to improve the functionality of Our Service, or We are legally
							obligated to retain this data for longer time periods.
						</p>
						<h2>Transfer of Your Personal Data</h2>
						<p>
							Your information, including Personal Data, is processed at the
							Company's operating offices and in any other places where the
							parties involved in the processing are located. It means that this
							information may be transferred to — and maintained on — computers
							located outside of Your state, province, country or other
							governmental jurisdiction where the data protection laws may
							differ than those from Your jurisdiction.
						</p>
						<p>
							Your consent to this Privacy Policy followed by Your submission of
							such information represents Your agreement to that transfer.
						</p>
						<p>
							The Company will take all steps reasonably necessary to ensure
							that Your data is treated securely and in accordance with this
							Privacy Policy and no transfer of Your Personal Data will take
							place to an organization or a country unless there are adequate
							controls in place including the security of Your data and other
							personal information.
						</p>
						<h2>Delete Your Personal Data</h2>
						<p>
							You have the right to delete or request that We assist in deleting
							the Personal Data that We have collected about You.
						</p>
						<p>
							Our Service may give You the ability to delete certain information
							about You from within the Service.
						</p>
						<p>
							You may update, amend, or delete Your information at any time by
							signing in to Your Account, if you have one, and visiting the
							account settings section that allows you to manage Your personal
							information. You may also contact Us to request access to,
							correct, or delete any personal information that You have provided
							to Us.
						</p>
						<p>
							Please note, however, that We may need to retain certain
							information when we have a legal obligation or lawful basis to do
							so.
						</p>
						<h2>Disclosure of Your Personal Data</h2>
						<h3>Business Transactions</h3>
						<p>
							If the Company is involved in a merger, acquisition or asset sale,
							Your Personal Data may be transferred. We will provide notice
							before Your Personal Data is transferred and becomes subject to a
							different Privacy Policy.
						</p>
						<h3>Law enforcement</h3>
						<p>
							Under certain circumstances, the Company may be required to
							disclose Your Personal Data if required to do so by law or in
							response to valid requests by public authorities (e.g. a court or
							a government agency).
						</p>
						<h3>Other legal requirements</h3>
						<p>
							The Company may disclose Your Personal Data in the good faith
							belief that such action is necessary to:
						</p>
						<ul>
							<li>Comply with a legal obligation</li>
							<li>Protect and defend the rights or property of the Company</li>
							<li>
								Prevent or investigate possible wrongdoing in connection with
								the Service
							</li>
							<li>
								Protect the personal safety of Users of the Service or the
								public
							</li>
							<li>Protect against legal liability</li>
						</ul>
						<h2>Security of Your Personal Data</h2>
						<p>
							The security of Your Personal Data is important to Us, but
							remember that no method of transmission over the Internet, or
							method of electronic storage is 100% secure. While We strive to
							use commercially acceptable means to protect Your Personal Data,
							We cannot guarantee its absolute security.
						</p>
						<h1>Children's Privacy</h1>
						<p>
							Our Service does not address anyone under the age of 13. We do not
							knowingly collect personally identifiable information from anyone
							under the age of 13. If You are a parent or guardian and You are
							aware that Your child has provided Us with Personal Data, please
							contact Us. If We become aware that We have collected Personal
							Data from anyone under the age of 13 without verification of
							parental consent, We take steps to remove that information from
							Our servers.
						</p>
						<p>
							If We need to rely on consent as a legal basis for processing Your
							information and Your country requires consent from a parent, We
							may require Your parent's consent before We collect and use that
							information.
						</p>
						<h1>Links to Other Websites</h1>
						<p>
							Our Service may contain links to other websites that are not
							operated by Us. If You click on a third party link, You will be
							directed to that third party's site. We strongly advise You to
							review the Privacy Policy of every site You visit.
						</p>
						<p>
							We have no control over and assume no responsibility for the
							content, privacy policies or practices of any third party sites or
							services.
						</p>
						<h1>Changes to this Privacy Policy</h1>
						<p>
							We may update Our Privacy Policy from time to time. We will notify
							You of any changes by posting the new Privacy Policy on this page.
						</p>
						<p>
							We will let You know via email and/or a prominent notice on Our
							Service, prior to the change becoming effective and update the
							&quot;Last updated&quot; date at the top of this Privacy Policy.
						</p>
						<p>
							You are advised to review this Privacy Policy periodically for any
							changes. Changes to this Privacy Policy are effective when they
							are posted on this page.
						</p>
						<h1>Contact Us</h1>
						<p>
							If you have any questions about this Privacy Policy, You can
							contact us:
						</p>
						<ul>
							<li>
								By email:{' '}
								<strong>
									<a href='mailto:hello@gravel777.io'>hello@gravel777.io</a>
								</strong>
							</li>
						</ul>
					</div>
				)}
			</div>
		</PopupContainer>
	);
};

export default LegalPopup;
