import React, { useEffect, useState } from 'react';
import PopupContainer from '../PopupContainer';
import HorizontalSpacer from '~/Assets/Vectors/HorizontalSpacer';
import ClosePlainIcon from '~/Assets/Vectors/ClosePlainIcon';
import Button from '~/Components/Button/Button';
import LocalStorage from '~/Utilities/LocalStorage';
import Language from '~/Language';
import Helpers from '~/helpers';
import Style from './TransactionSettingsPopup.module.css';

const TransactionSettingsPopup = ({
    onClose,
    isOpen,
}) => {
    const [ slippage, setSlippage ] = useState(LocalStorage.getSlippage());
    const [ deadline, setDeadline ] = useState(LocalStorage.getDeadlinePeriod());
    const [ isValidSlippage, setIsValidSlippage ] = useState(true);
    const [ isValidDeadline, setIsValidDeadline ] = useState(true);
    

    const handleClose = (confirm) => {
        if(onClose)
            onClose(confirm);
    }

    const handleApply = () => {
        if(isValidSlippage && isValidDeadline) {
            LocalStorage.setDeadlinePeriod(deadline);
            LocalStorage.setSlippage(slippage);
            handleClose();
        }
    }

    useEffect(
        () => {
            let parsedSlippage = parseFloat(slippage);
            let parsedDeadline = parseFloat(deadline);

            setIsValidSlippage(
                !Number.isNaN(parsedSlippage) && 
                parsedSlippage > 0 && parsedSlippage <= 15 
            )

            setIsValidDeadline(
                !Number.isNaN(parsedDeadline) && 
                parsedDeadline > 0
            )
        },
        [ slippage, deadline ]
    )

    return (
        <PopupContainer
            isOpen={isOpen}
            onClose={handleClose.bind(null, false)}
            backdropColor="#0009">

            <div className={Style.container}>

                <div className={Style.titleBarContainer}>
                    <p className={Style.title}>{ Language.TX_SETTINGS }</p>
                    <ClosePlainIcon 
                        className={Style.closeIcon}
                        onClick={handleClose}/>
                </div>

                <HorizontalSpacer className={Style.upperSpacer} />

                <p className={Style.label}>
                    { Language.SLIPPAGE_LABEL }
                </p>

                <div className={Style.row}>
                    <div className={
                        Helpers.conditionalClass(
                            parseFloat(slippage) === 0.1,
                            Style.toggleButton,
                            Style.toggleButtonActive
                        )}
                        onClick={() => { setSlippage("0.1") }}>
                        0.1%
                    </div>

                    <div className={
                        Helpers.conditionalClass(
                            parseFloat(slippage) === 0.5,
                            Style.toggleButton,
                            Style.toggleButtonActive
                        )}
                        onClick={() => { setSlippage("0.5") }}>
                        0.5%
                    </div>

                    <div className={
                        Helpers.conditionalClass(
                            parseFloat(slippage) === 1,
                            Style.toggleButton,
                            Style.toggleButtonActive
                        )}
                        onClick={() => { setSlippage("1.0") }}>
                        1.0%
                    </div>

                    <input
                        className={Style.input}
                        type="number"
                        min="0.1"
                        step="0.1"
                        value={slippage}
                        onChange={e => setSlippage(e.target.value)}/>

                    <p className={Style.endLabel}>
                        %
                    </p>
                </div>

                <HorizontalSpacer className={Style.midSpacer} />

                <div className={Style.row}>
                    <p className={Style.label} style={{padding: 0}}>
                        { Language.DEADLINE_LABEL } 
                    </p>

                    <input
                        className={Style.input}
                        type="number"
                        min="1"
                        step="1"
                        value={deadline}
                        onChange={e => setDeadline(e.target.value)}/>
                    
                    <p className={Style.endLabel}>
                        min
                    </p>
                </div>

                <HorizontalSpacer className={Style.midSpacer} />

                <div className={Style.buttonContainer}>
                    <Button
                        className={Style.button}
                        disabled={!isValidSlippage || !isValidDeadline}
                        onClick={handleApply}
                        text={ Language.APPLY }/>

                    <Button
                        className={
                            Helpers.joinClasses(
                                Style.button,
                                Style.secondaryButton
                            )
                        }
                        onClick={handleClose.bind(null, false)}
                        text={ Language.CANCEL }/>
                </div>
            </div>

        </PopupContainer>
    );
}

export default TransactionSettingsPopup;