import Config from "~/config";

class LocalStorage {
    static getSlippage() {
        return parseFloat(window.localStorage.slippage || Config.Defaults.SLIPPAGE_PERCENT)
    }

    static getDeadlinePeriod() {
        return parseFloat(window.localStorage.deadline || Config.Defaults.DEADLINE_MINUTES)
    }

    static getSelectedLanguage() {
        return window.localStorage.language || null;
    }

    static setSlippage(value) {
        window.localStorage.slippage = value;
    }

    static setDeadlinePeriod(value) {
        window.localStorage.deadline = value;
    }

    static setSelectedLanguage(value) {
        window.localStorage.language = value;
    }
}

export default LocalStorage;