import React, { useState } from 'react'
import Button from '~/Components/Button'
import HorizontalSpacer from '~/Assets/Vectors/HorizontalSpacer'
import ClosePlainIcon from '~/Assets/Vectors/ClosePlainIcon'
import Language from '~/Language'
import Helpers from '~/helpers'
import Style from './UnstakePanel.module.css'

function UnstakePanel({
    isFarm,
    deposit,
    assetPrice,
    onUnstakeClicked,
    onClose
}) {
    const [withdrawPercentage, setWithdrawPercentage] = useState(0);

    const handleUnstake = () => {
        if(onUnstakeClicked)
            onUnstakeClicked(
                withdrawPercentage === 100 ?
                deposit :
                Helpers.ethToWei((Helpers.weiToEth(deposit) * withdrawPercentage / 100).toString())
            );

        
        if(onClose)
            onClose()
    }

    return (
        <div className={Style.container}>
            <div className={Style.header}>
                <p className={Style.title}>{ Language.WITHDRAW }</p>
                <ClosePlainIcon
                    onClick={onClose}
                    className={Style.headerIcon}/>
            </div>

            <HorizontalSpacer 
                className={Style.horizontalSpacer}
                width="100%" 
                height="1px"/>

            <div className={Style.fieldsContainer}>
                <div className={Style.sliderContainer}>
                    <p className={Style.sliderValue}>
                        { withdrawPercentage }%
                    </p>

                    <input
                        className={
                            Helpers.conditionalClass(
                                isFarm,
                                Style.slider,
                                Style.sliderRed
                            )
                        }
                        type="range"
                        value={ withdrawPercentage }
                        onChange={ (event) => { setWithdrawPercentage(parseFloat(event.target.value)) } }
                        disabled={ Helpers.weiToEth(deposit) === 0 }
                        />
                        
                </div>
            </div>

            <HorizontalSpacer
                className={Style.spacer}
                width="100%" />

            <div className={Style.fieldsContainer}>
                <div className={Style.inputRow}>
                    <p className={Style.label}>
                    {
                        isFarm ?
                        "LP Tokens:" :
                        "Gravel 777:"
                    }
                    </p>

                    <p className={Style.value}>
                        { Helpers.formatValue(Helpers.weiToEth(deposit) * withdrawPercentage / 100, 5) }
                    </p>
                </div>

                <div className={Style.balanceRow}>
                    <p className={Style.secondaryLabel}>
                        { Language.BALANCE }
                        <span 
                            className={Style.secondaryLabelHighlight}
                            onClick={() => { setWithdrawPercentage(Helpers.weiToEth(deposit) === 0 ? 0 : 100) }}>
                            { Helpers.weiToEth(deposit, 3) } (Max)
                        </span>
                    </p>

                    <p className={Style.secondaryLabel}>
                        ≈ { Helpers.formatUsdValue(Helpers.weiToEth(deposit) * assetPrice * withdrawPercentage / 100) }
                    </p>
                </div>
            </div>

            <div style={{ flex: 'auto 1' }}/>

            <Button
                className={
                    Helpers.conditionalClass(
                        isFarm,
                        Style.unstakeButton,
                        Style.unstakeButtonRed
                    )
                }
                onClick={ handleUnstake }
                disabled={ parseFloat(withdrawPercentage) === 0 }
                text={ Language.UNSTAKE } />
        </div>
    )
}

export default UnstakePanel