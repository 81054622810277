import React from 'react'

function ArrowDown({className}) {
    return (
        <svg 
            className={className}
            viewBox="0 0 18 18" 
            fill="none" 
            xmlns="http://www.w3.org/2000/svg">
            <path d="M3.903 6.11513C4.19099 5.82714 4.65792 5.82714 4.94591 6.11513L8.84911 10.0183L12.7523 6.11513C13.0403 5.82714 13.5072 5.82714 13.7952 6.11513C14.0832 6.40312 14.0832 6.87004 13.7952 7.15803L9.37056 11.5827C9.08257 11.8707 8.61565 11.8707 8.32766 11.5827L3.903 7.15803C3.61501 6.87004 3.61501 6.40312 3.903 6.11513Z"/>
        </svg>
    )
}

export default ArrowDown