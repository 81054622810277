import React, { useState } from 'react'
import ArrowDown from '~/Assets/Vectors/ArrowDown';
import Style from './FAQMenuItem.module.css'

function FAQMenuItem({
    title,
    content
}) {
    const [isExpanded, setIsExpanded] = useState(false);
    
    const toggleExpanded = () => {
        setIsExpanded(!isExpanded);
    }
    
    return (
        <div 
            className={Style.container}
            style={isExpanded ? { backgroundColor: "#282833" } : {}}
            onClick={toggleExpanded}>
            <div className={Style.topBar}>
                <p className={Style.itemTitle}>{ title }</p>
                <div 
                    className={Style.expandIconContainer}
                    style={isExpanded ? {backgroundColor: "#1E1E26"} : {}}>
                    <ArrowDown
                        className={
                            Style.expandIcon +
                            (
                                isExpanded ?
                                ' ' + Style.expandIconExpanded :
                                ''
                            )
                        }/>
                </div>
            </div>

            <div className={Style.contentContainer + (isExpanded ? '' : ' ' + Style.contentHidden)}>
                <p className={Style.content}>{ content }</p>
            </div>
        </div>
    )
}

export default FAQMenuItem