import React, { Fragment, useEffect, useMemo, useState } from 'react'
import { usePopupManager } from '~/Context/PopupManagerContext'
import { useRoundData } from '~/API/RoundData'
import VerticalSpacer from '~/Assets/Vectors/VerticalSpacer'
import VideoPopup from '~/Popups/VideoPopup'
import Button from '../Button'
import Loader from '~/Assets/Vectors/Loader'
import Images from '~/Assets/Images'
import Language from '~/Language'
import Helpers from '~/helpers'
import Style from './HomeUpperSection.module.css'

function HomeUpperSection() {
    const { showPopup } = usePopupManager();
    const { data, isLoading, isError } = useRoundData();
    const [ tick, setTick ] = useState(false);

    const poolRevealTime = useMemo(
        () => {
            if(!isLoading && !isError) {
                return Helpers.getTimeUntilDate(data?.currentRound?.startTime, tick)
            }
        }, 
        [data, isLoading, isError, tick]
    )

    useEffect(
        () => {
            setTimeout(
                () => {
                    setTick(!tick)    
                }, 
                1000
            );
        },
        [tick]
    )

    const handleHowItWorks = () => {
        showPopup(VideoPopup, {});
    }

    return (
        <div className={Style.container}>
            <div className={Style.leftContainer}>
                <p className={Style.title}>
                    { Language.G777_SLOGAN_TOP } <br/> { Language.G777_SLOGAN_BOTTOM }
                </p>

                <p className={Style.description}>
                    { Language.HOME_PAGE_PARAGRAPH }
                </p>

                <Button
                    className={Style.button}
                    onClick={handleHowItWorks}
                    text={Language.HOW_GRAVEL_777_WORKS}/>

                <div className={Style.roundInfoContainer}>
                    <p className={Style.currentPoolLabel}>
                        { Language.CURRENT_POOL }
                    </p>
                    
                    <p className={Style.currentPoolValue}>
                        { Helpers.formatValue(data?.currentRound?.poolValue) } G777 ({ Helpers.formatUsdValue(data?.currentRound?.poolValue * data?.tokenPrice) })
                    </p>

                    <div className={Style.poolTimerContainer}>
                        <p className={Style.poolTimerLabel}>
                            { Language.POOL_ENDS_IN }
                        </p>

                        <div className={Style.poolTimerValueContainer}>
                            {
                                !poolRevealTime ?
                                <Fragment>
                                    <Loader className={Style.loader}/>
                                    <p className={Style.poolTimerWaitingText}>
                                        { Language.FETCHING_DATA }
                                    </p>
                                </Fragment>
                                :
                                poolRevealTime.isPast ?
                                <Fragment>
                                    <Loader className={Style.loader}/>
                                    <p className={Style.poolTimerWaitingText}>
                                        { Language.WAITING_FOR_POOL_REVEAL }
                                    </p>
                                </Fragment>
                                :
                                <Fragment>
                                    <p className={Style.poolTimerValueText}>
                                        <span>{ poolRevealTime.hours }</span> { Language.HOURS }
                                    </p>

                                    <VerticalSpacer height={27}/>

                                    <p className={Style.poolTimerValueText}>
                                        <span>{ poolRevealTime.minutes }</span> { Language.MINUTES }
                                    </p>

                                    <VerticalSpacer height={27}/>

                                    <p className={Style.poolTimerValueText}>
                                        <span>{ poolRevealTime.seconds }</span> { Language.SECONDS }
                                    </p>
                                </Fragment>
                            }
                        </div>
                    </div>
                    
                </div>
            </div>

            <div className={Style.rightContainer}>
                <img 
                    alt=''
                    src={Images.BotsHome}
                    className={Style.botsImage}/>
            </div>
        </div>
    )
}

export default HomeUpperSection