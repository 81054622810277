import React from 'react';

const VerticalSpacer = ({
    className,
    width,
    height,
    useWhite,
    opacity
}) => {
    return (
        <svg   
            className={className}
            width={width || 1}
            height={height}
            viewBox="0 0 1 35" 
            fill="none" 
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="none"
            opacity={opacity}>
            <defs>
                <linearGradient id="paint0_linear_810_315" x1="0.5" y1="0" x2="0.5" y2="35" gradientUnits="userSpaceOnUse">
                    <stop stopColor="var(--c-orange)" stopOpacity="0"/>
                    <stop offset="0.508997" stopColor="var(--c-orange)"/>
                    <stop offset="0.988192" stopColor="var(--c-orange)" stopOpacity="0"/>
                </linearGradient>
                <linearGradient id="paint0_linear_810_315_white" x1="0.5" y1="0" x2="0.5" y2="35" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#ffffff" stopOpacity="0"/>
                    <stop offset="0.508997" stopColor="#ffffff"/>
                    <stop offset="0.988192" stopColor="#ffffff" stopOpacity="0"/>
                </linearGradient>
            </defs>
            <rect width="1" height="35" fill={`url(#paint0_linear_810_315${useWhite ? '_white' : ''})`} fillOpacity="0.6"/>
        </svg>
    )
}

export default VerticalSpacer;