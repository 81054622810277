import React, { useEffect } from 'react';
import Web3Interface, { Focus } from '~/Utilities/Web3Interface';
import VestingUpperSection from '~/Components/VestingUpperSection';
import VestingControls from '~/Components/VestingControls';
import Header from '~/Components/Header/Header';
import Footer from '~/Components/Footer/Footer';
import Style from "./Vesting.module.css";

function Vesting() {
    useEffect(() => {
        Web3Interface.setFocus(Focus.VESTING);
    }, []);

    return (
        <div className={Style.container}>
            <Header/>

            <VestingUpperSection>
                <VestingControls/>
            </VestingUpperSection>

            <Footer/>
        </div>
    )
}

export default Vesting;