import React from 'react'
import HorizontalSpacer from '~/Assets/Vectors/HorizontalSpacer'
import Button from '~/Components/Button'
import InfoIcon from '~/Assets/Vectors/InfoIcon'
import Language from '~/Language'
import Helpers from '~/helpers'
import Style from './NoDepositPanel.module.css'

function NoDepositPanel({
    isFarm,
    isApproved,
    tvl,
    apr,
    assetPrice,
    onInfoClicked,
    onDepositClicked,
    onApproveClicked
}) {
    return (
        <div className={Style.container}>
            <div className={Style.header}>
                <p className={Style.title}>
                {
                    isFarm ?
                    Language.LIQUIDITY_FARM :
                    Language.STAKING_POOL
                }
                </p>
                <InfoIcon
                    onClick={onInfoClicked}
                    className={Style.headerIcon}/>
            </div>

            <HorizontalSpacer 
                className={Style.horizontalSpacer}
                width="100%" 
                height="1px"/>
    
            <div className={Style.fieldContainer}>    
                <p className={Style.contentTitle}>
                {
                    isFarm ?
                    Language.EARN_G777_LIQUIDITY_FARM :
                    Language.EARN_G777_STAKING_POOL
                }
                </p>

                <div className={Style.row}>
                    <p className={Style.label}>
                        TVL:
                    </p>
                    <p className={Style.label}>
                        { Language.APR }
                    </p>
                </div>

                <div className={Style.row}>
                    <p className={Style.value}>
                    { 
                        Helpers.formatUsdValue(
                            Helpers.weiToEth(tvl) * assetPrice
                        ) 
                    }
                    </p>
                    <p className={Style.value}>
                        { Helpers.formatValue(apr) }%
                    </p>
                </div>
            </div>

            <div style={{ flex: 'auto 1' }}/>

            <Button
                className={
                    Helpers.conditionalClass(
                        isFarm,
                        Style.stakeButton,
                        Style.stakeButtonRed
                    )
                }
                onClick={
                    isApproved ?
                    onDepositClicked :
                    onApproveClicked
                }
                text={
                    !isApproved ?
                    Language.APPROVE_TOKEN
                    :
                    isFarm ?
                    Language.ADD_LIQUIDITY :
                    Language.STAKE_G777
                } />
        </div>
    )
}

export default NoDepositPanel