import React, { useEffect, useMemo, useState } from 'react'
import { usePopupManager } from '~/Context/PopupManagerContext'
import { BigNumber } from 'ethers'
import TransactionSettingsPopup from '~/Popups/TransactionSettingsPopup'
import HorizontalSpacer from '~/Assets/Vectors/HorizontalSpacer'
import ClosePlainIcon from '~/Assets/Vectors/ClosePlainIcon'
import Button from '~/Components/Button'
import GearIcon from '~/Assets/Vectors/GearIcon'
import Language from '~/Language'
import Helpers from '~/helpers'
import Style from './StakePanel.module.css'

function StakePanel({
    isFarm,
    deposit,
    balance,
    assetPrice,
    depositAssetPrice,
    apr,
    onStakeClicked,
    onClose
}) {
    const { showPopup } = usePopupManager();
    const [ inputValue, setInputValue ] = useState('0');
    const [ isValidInput, setIsValidInput ] = useState(false);

    const totalDeposit = useMemo(
        () => {
            return (
                (parseFloat(inputValue) * depositAssetPrice || 0) +
                Helpers.weiToEth(deposit) * assetPrice
            )
        }, 
        [ inputValue, depositAssetPrice, deposit, assetPrice ]
    ) 

    useEffect(
        () => {
            try {
                let bnInputValue = Helpers.ethToWei(inputValue);
                let bnBalance = BigNumber.from(balance);
    
                setIsValidInput(
                    bnInputValue.gt(0) &&
                    bnInputValue.lte(bnBalance)
                )
            }
            catch (ex) {
                setIsValidInput(false)
            }
        },
        [ inputValue, balance, setIsValidInput ]
    )

    const handleSetMax = () => {
        setInputValue(Helpers.weiToEthString(balance));
    }

    const handleStake = () => {
        if(onStakeClicked)
            onStakeClicked(inputValue);

        if(onClose)
            onClose()
    }

    const handleTransactionSettings = () => {
        showPopup(TransactionSettingsPopup, {})
    }

    return (
        <div className={Style.container}>
            <div className={Style.header}>
                <p className={Style.title}>{ Language.DEPOSIT }</p>
                {
                    isFarm &&
                    <GearIcon
                        onClick={handleTransactionSettings}
                        className={Style.headerIcon}/>
                }
                <ClosePlainIcon
                    onClick={onClose}
                    className={Style.headerIcon}/>
            </div>

            <HorizontalSpacer 
                className={Style.horizontalSpacer}
                width="100%" 
                height="1px"/>

            <div className={Style.fieldContainer}>    
                <div className={Style.inputRow}>
                    <p className={Style.inputLabel}>
                    {
                        isFarm ?
                        "MATIC:" :
                        "Gravel 777:"
                    }
                    </p>

                    <input
                        className={Style.input}
                        onChange={(event) => { setInputValue(event.target.value) }}
                        value={inputValue}
                        type="text"/>
                </div>

                <div className={Style.balanceRow}>
                    <p className={Style.label}>
                        { Language.BALANCE }
                        <span 
                            className={Style.labelHighlight}
                            onClick={handleSetMax}>
                            {Helpers.formatValue(Helpers.weiToEth(balance), 3)} (Max)
                        </span>
                    </p>

                    <p className={Style.label}>
                        ≈ { Helpers.formatUsdValue(parseFloat(inputValue) * depositAssetPrice) }
                    </p>
                </div>
            </div>

            <HorizontalSpacer 
                className={Style.spacer}
                width="100%" />

            <div className={Style.fieldContainer}>
                <div className={Style.lowerRow}>
                    <p className={Style.secondaryLabel}>
                        { Language.NEW_TOTAL_DEPOSIT }
                    </p>    
                    <p className={Style.secondaryLabel}>
                        { Language.MONTHLY_INCOME }
                    </p>    
                    <p className={Style.secondaryLabel}>
                        { Language.YEARLY_INCOME }
                    </p>    
                </div>

                <div className={Style.lowerRow}>
                    <p className={Style.secondaryValue}>
                        ≈ { Helpers.formatUsdValue(totalDeposit) }
                    </p>    
                    <p className={Style.secondaryValue}>
                        ≈ { Helpers.formatUsdValue(totalDeposit * apr / 100 / 12) }
                    </p>    
                    <p className={Style.secondaryValue}>
                        ≈ { Helpers.formatUsdValue(totalDeposit * apr / 100) }
                    </p> 
                </div>
            </div>

            <div style={{ flex: 'auto 1' }}/>

            <Button
                className={
                    Helpers.conditionalClass(
                        isFarm,
                        Style.stakeButton,
                        Style.stakeButtonRed
                    )
                }
                onClick={ handleStake }
                disabled={!isValidInput}
                text={ Language.STAKE } />

        </div>
    )
}

export default StakePanel