import { ethers } from 'ethers';
import AirdropABI from './ABI/Airdrop.js';
import Config from '~/config.js';
import Language from '~/Language/index.js';

class Airdrop {
    constructor(web3Interface, signer) {
        this.web3Interface = web3Interface;
        this.signer = signer;
        this.lastSparseUpdate = 0;
        this.isInitialized = true;
        this.isFocused = false;
        this.state = null;

        this.contract = new ethers.Contract(
            Config.Blockchain.Contracts.AIRDROP, 
            AirdropABI, 
            signer
        );
    }

    async updateState() {
        if(!this.isFocused && this.state !== null)
            return;

        let newState = {}

        if(Date.now() > this.lastSparseUpdate + Config.Blockchain.SPARSE_UPDATE_RATE_MS || this.state === null) {
            this.lastSparseUpdate = Date.now();
        }

        let userData = await this.contract.userData(this.web3Interface.address)
        newState.total = userData.amount;
        newState.claimed = userData.claimed; 

        newState.claimable = await this.contract.getClaimableTokens(this.web3Interface.address)

        if(!this.state)
            this.state = newState;
        else
            this.state = {...this.state, ...newState};

        return this.state;
    }

    clearState() {
        this.state = null;
    }

    async claimTokens() {
        let txData = await this.contract.claimTokens()
    
        this.web3Interface.addPendingTransaction(
            txData.hash, 
            'AIRDROP_CLAIM', 
            Language.CLAIMING_TOKENS
        )

        return txData;
    }
}

export default Airdrop;