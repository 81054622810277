import React from 'react'
import MinusIcon from '~/Assets/Vectors/MinusIcon'
import PlusIcon from '~/Assets/Vectors/PlusIcon'
import VerticalSpacer from '~/Assets/Vectors/VerticalSpacer'
import Helpers from '~/helpers'
import Style from './TickerButtons.module.css'

function TickerButtons({
    primaryColor,
    plusDisabled,
    minusDisabled,
    onPlusClicked,
    onMinusClicked
}) {
    return (
        <div 
            className={Style.container}
            style={{ backgroundColor: primaryColor }}>
            <div 
                onClick={
                    plusDisabled ?
                    () => {} :
                    onPlusClicked
                }
                className={
                    Helpers.conditionalClass(
                        plusDisabled,
                        Style.item,
                        Style.itemDisabled
                    )
                }>
                <PlusIcon className={
                    Helpers.conditionalClass(
                        plusDisabled,
                        Style.icon,
                        Style.iconDisabled
                    )}/>
            </div>

            <VerticalSpacer 
                height="80%" 
                width="1px"
                opacity={0.4}
                useWhite/>

            <div 
                onClick={
                    minusDisabled ?
                    () => {} :
                    onMinusClicked
                }
                className={
                    Helpers.conditionalClass(
                        minusDisabled,
                        Style.item,
                        Style.itemDisabled
                    )
                }>
                <MinusIcon className={
                    Helpers.conditionalClass(
                        minusDisabled,
                        Style.icon,
                        Style.iconDisabled
                    )}/>
            </div>
        </div>
    )
}

export default TickerButtons