import React from 'react'
import HorizontalSpacer from '~/Assets/Vectors/HorizontalSpacer'
import TickerButtons from '~/Components/TickerButtons'
import Button from '~/Components/Button'
import InfoIcon from '~/Assets/Vectors/InfoIcon'
import Language from '~/Language'
import Helpers from '~/helpers'
import Style from './DepositPanel.module.css'

function DepositPanel({
    isFarm,
    deposit,
    unclaimed,
    assetPrice,
    apr,
    onDepositClicked,
    onWithdrawClicked,
    onClaimClicked,
    onInfoClicked
}) {
    return (
        <div className={Style.container}>
            <div className={Style.header}>
                <p className={Style.title}>
                {
                    isFarm ?
                    Language.LIQUIDITY_FARM :
                    Language.STAKING_POOL
                }
                </p>
                <InfoIcon
                    onClick={onInfoClicked}
                    className={Style.headerIcon}/>
            </div>

            <HorizontalSpacer 
                className={Style.horizontalSpacer}
                width="100%" 
                height="1px"/>

            <div className={Style.fieldContainer}>
                <div className={Style.row}>
                    <p className={Style.label}>
                        { Language.DEPOSIT }:
                    </p>
                    <p className={Style.label}>
                        { Language.APR }:
                    </p>
                </div>

                <div className={Style.row}>
                    <p className={Style.value}>
                        { Helpers.formatValue(Helpers.weiToEth(deposit)) } 
                        <span className={Style.secondaryLabel}>
                        {
                            isFarm ?
                            "LP Tokens" :
                            "G777"
                        }    
                        </span>
                    </p>
                    <p className={Style.value}>
                        { Helpers.formatValue(apr) }%
                    </p>
                </div>

                <p className={Style.secondaryLabel}>
                    ≈ { Helpers.formatUsdValue(Helpers.weiToEth(deposit) * assetPrice) }
                </p>
            </div>

            <HorizontalSpacer 
                className={Style.horizontalSpacer}
                width="100%" 
                height="1px"/>

            <div className={Style.fieldContainer}>
                <p className={Style.label}>
                    { Language.EARNED }
                </p>

                <p className={Style.earned}>
                    ≈ { Helpers.formatValue(Helpers.weiToEth(unclaimed), 4) } 
                    <span className={Style.secondaryLabel}>G777</span>
                </p>
            </div>

            <div style={{ flex: 'auto 1' }} />

            <div className={Style.buttonContainer}>
                <Button
                    className={
                        Helpers.conditionalClass(
                            isFarm,
                            Style.claimButton,
                            Style.claimButtonRed
                        )
                    }
                    onClick={onClaimClicked}
                    disabled={ Helpers.weiToEth(unclaimed) === 0 }
                    text={ Language.CLAIM_REWARDS }/>
            
                <TickerButtons
                    primaryColor={
                        isFarm ?
                        "#501461" :
                        "#251A54"
                    }
                    onPlusClicked={onDepositClicked}
                    onMinusClicked={onWithdrawClicked}/>
            </div>
        </div>
    )
}

export default DepositPanel