import React from 'react';
import PopupContainer from '../PopupContainer';
import Style from './VideoPopup.module.css';
import Images from '~/Assets/Images';
import Language from '~/Language';
import CloseIcon from '~/Assets/Vectors/CloseIcon';

const VideoPopup = ({
    onClose,
    isOpen,
}) => {
    const handleClose = (confirm) => {
        if(onClose)
            onClose(confirm);
    }

    return (
        <PopupContainer
            isOpen={isOpen}
            onClose={handleClose.bind(null, false)}
            backdropColor="#0009">

            <div className={Style.container}>
                <CloseIcon 
                    className={Style.closeIcon}
                    onClick={handleClose}/>
                    
                <video 
                    className={Style.video} 
                    controls
                    autoPlay
                    poster={Images.VideoThumbnail}>
                    <source src={Language.INFORMATIONAL_VIDEO_URL} type="video/mp4"/>
                    Your browser does not support the video tag.
                </video>
            </div>

        </PopupContainer>
    );
}

export default VideoPopup;