import React from 'react'
import Images from '~/Assets/Images'
import Language from '~/Language'
import Style from './AboutMission.module.css'

function AboutMission() {
    return (
        <div className={Style.container}>
            <p className={Style.externalTitle}>
                { Language.OUR_MISSION }
            </p>

            <p className={Style.externalDescription}>
                { Language.THE_GRAVEL_777_MISSION_LINE_SHORT }
            </p>

            <div 
                className={Style.internalContainer}
                style={{ background: `url("${Images.MissionWaves}")` }}>

                <div className={Style.botContainer}>
                    <img
                        alt=''
                        className={Style.botImage}
                        src={Images.BotJackpot} />

                </div>
                
                <p className={Style.internalTitle}>
                    { Language.THE_PLATFORMS_AIM }
                </p>

                <p className={Style.internalDescription}>
                    { Language.THE_GRAVEL_777_MISSION_LINE_1 }
                </p>

                <p className={Style.internalDescription + ' ' + Style.internalDescriptionLower}>
                    { Language.THE_GRAVEL_777_MISSION_LINE_2 }
                </p>
            </div>
        </div>
    )
}

export default AboutMission