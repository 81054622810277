import React from 'react'
import CloseIcon from '~/Assets/Vectors/CloseIcon'
import Style from './ToastMessage.module.css'
import { cx } from '@emotion/css'

function ToastMessage ({
    message,
    type,
    onClose,
}) {
    return (
       <div className={cx(Style.messageContainer, type)}>
            <div className={Style.container}>
                <span className={Style.message}>{message}</span>
                <button type="button" className={Style.closeButton} onClick={onClose}>
                    <CloseIcon className={Style.closeIcon} />
                </button>
            </div>
       </div>
    )
}

export default ToastMessage