import React from 'react'
import ClosePlainIcon from '~/Assets/Vectors/ClosePlainIcon'
import HorizontalSpacer from '~/Assets/Vectors/HorizontalSpacer'
import Button from '~/Components/Button'
import Language from '~/Language'
import Helpers from '~/helpers'
import Config from '~/config'
import Style from './InfoPanel.module.css'

function InfoPanel({
    isFarm,
    tvl,
    assetPrice,
    deposit,
    contractAddress,
    onClose
}) {
    const handleViewContract = () => {
        window.open(
            Config.Blockchain.Network.TX_EXPLORER.substring(0, Config.Blockchain.Network.TX_EXPLORER.length - 4) + '/address/' + contractAddress,
            "_blank"
        )
    }

    return (
        <div className={Style.container}>
            <div className={Style.header}>
                <p className={Style.title}>{ Language.POOL_INFO }</p>
                <ClosePlainIcon
                    onClick={onClose}
                    className={Style.headerIcon}/>
            </div>

            <HorizontalSpacer 
                className={Style.spacer}
                width="100%" 
                height="1px"/>

            <div className={Style.row}>
                <p className={Style.label}>
                    { Language.TOTAL_VALUE_LOCKED }
                </p>
                <p className={Style.value}>
                    { 
                        Helpers.formatUsdValue(
                            Helpers.weiToEth(tvl) * assetPrice
                        ) 
                    }
                </p>
            </div>

            <div className={Style.row}>
                <p className={Style.label}>
                    { Language.TOTAL_TOKENS_LOCKED }
                </p>
                <p className={Style.value}>
                    {
                        Helpers.weiToEth(tvl).toLocaleString('US-en') +
                        (
                            isFarm ? ' LP' : ' G777'
                        )
                    }
                </p>
            </div>

            <div className={Style.row}>
                <p className={Style.label}>
                    { Language.YOUR_SHARE }
                </p>
                <p className={Style.value}>
                    {
                        Helpers.formatValue(100 * Helpers.weiToEth(deposit) / Helpers.weiToEth(tvl), 4)
                    }%
                </p>
            </div>

            <div style={{ flex: 'auto 1' }}/>

            <Button
                className={
                    Helpers.conditionalClass(
                        isFarm,
                        Style.viewContract,
                        Style.viewContractRed
                    )
                }
                onClick={handleViewContract}
                text={ Language.VIEW_CONTRACT }/>
        </div>
    )
}

export default InfoPanel