import React, { useContext, createContext, useEffect } from 'react';
import Language from '~/Language';
import LocalStorage from '~/Utilities/LocalStorage';

const LanguageTriggerContext = createContext();
const SetLanguageContext = createContext();

export function useLanguageTrigger() {
    return useContext(LanguageTriggerContext);
}

export function useSetCurrentLanguage() {
    return useContext(SetLanguageContext);
}

export function LanguageProvider({ children, trigger, setTrigger }) {
    useEffect(
        () => {
            let lastSelectedLanguage = LocalStorage.getSelectedLanguage();
            if(lastSelectedLanguage) {
                LocalStorage.setSelectedLanguage(lastSelectedLanguage)
                Language.setLanguage(lastSelectedLanguage)
                setTrigger({})
            }
        },
        [setTrigger]
    )

    const handleSetLanguage = (language) => {
        LocalStorage.setSelectedLanguage(language)
        Language.setLanguage(language)
        setTrigger({})
    }

    return (
        <SetLanguageContext.Provider value={handleSetLanguage}>
            <LanguageTriggerContext.Provider value={trigger}>
                {children}
            </LanguageTriggerContext.Provider>
        </SetLanguageContext.Provider>
    )
}