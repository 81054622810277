import React, { useEffect, useRef, useState } from 'react';
import Web3Interface, { Focus } from '~/Utilities/Web3Interface';
import HomeUpperSection from '~/Components/HomeUpperSection';
import LastRoundStats from '~/Components/LastRoundStats';
import StakingSection from '~/Components/StakingSection';
import RoundResultTable from '~/Components/RoundResultTable';
import Header from '~/Components/Header/Header';
import Footer from '~/Components/Footer/Footer';
import Language from '~/Language';
import Style from "./Home.module.css";
import ToastMessage from '~/Components/ToastMessage/ToastMessage';

function Home() {
    const tableRef = useRef();
    const toastMessage = 'This website is still under construction. Visit us soon to get the full experience!';
    const [message, setMessage] = useState(toastMessage);

    useEffect(() => {
        Web3Interface.setFocus(Focus.STAKING);
    }, []);

    const handleViewAll = () => {
        if(tableRef.current) {
            tableRef.current.scrollIntoView({
                behavior: "smooth", 
                block: "start", 
                inline: "nearest"
            })
        }
    }

    return (
        <div className={Style.container}>
            {message && <ToastMessage message={message} onClose={() => setMessage('')} />}

            <Header />

            <div className={Style.pageContents}>
                <HomeUpperSection />

                <LastRoundStats onViewAllClicked={handleViewAll}/>

                <StakingSection />

                <div className={Style.section} ref={tableRef}>
                    <p className={Style.sectionTitle}>
                        { Language.PREVIOUS_REWARDS }
                    </p>

                    <RoundResultTable />
                </div>
            </div>

            <Footer />
        </div>
    )
}

export default Home;