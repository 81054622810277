import React, { useMemo, useState } from 'react'
import DepositPanel from './DepositPanel'
import NoDepositPanel from './NoDepositPanel'
import InfoPanel from './InfoPanel'
import StakePanel from './StakePanel'
import UnstakePanel from './UnstakePanel'
import Button from '../Button'
import Loader from '~/Assets/Vectors/Loader'
import Language from '~/Language'
import Helpers from '~/helpers'
import Config from '~/config'
import Style from './StakingControl.module.css'

function StakingControl({
    isConnected,
    isInNetwork,
    isLoading,
    isWaitingTransaction,
    isApproved,
    contractAddress,
    pendingTransactions,
    isFarm,
    tokenPrice,
    assetPrice,
    depositAssetPrice,
    tvl,
    deposit,
    balance,
    blockReward,
    unclaimed,
    onStakeClicked,
    onUnstakeClicked,
    onClaimClicked,
    onApproveClicked,
    onConnectWallet,
    onSwitchNetwork
}) {
    const [ isOpen, setIsOpen] = useState(false);
    const [ currentPanel, setCurrentPanel ] = useState(StakingControl.Panels.None)

    const apr = useMemo(
        () => (
            100 * 
            Helpers.weiToEth(blockReward) * tokenPrice * 
            365 * 24 * 60 * 60 * 1000 / Config.Blockchain.Network.BLOCK_TIME_MS /
            Helpers.weiToEth(tvl) / assetPrice
        ), 
        [blockReward, tokenPrice, assetPrice, tvl]
    )

    const handleClosePanel = () => {
        setIsOpen(false);
    }

    const handleDeposit = () => {
        setIsOpen(true);
        setCurrentPanel(StakingControl.Panels.Stake);
    }

    const handleWithdraw = () => {
        setIsOpen(true);
        setCurrentPanel(StakingControl.Panels.Unstake);
    }

    const handleInfo = () => {
        setIsOpen(true);
        setCurrentPanel(StakingControl.Panels.Info);
    }

    return (
        <div className={Style.container}>
            {
                (!isConnected || !isInNetwork) ?
                <div className={Style.messageOverlayContainer}>
                    <p className={Style.messageOverlayText}>
                        {
                            isConnected ?
                            Language.formatString(Language.PLEASE_SWITCH_TO_NETWORK, Config.Blockchain.Network.Configuration.chainName) :
                            Language.PLEASE_CONNECT_YOUR_WALLET
                        }
                    </p>

                    <Button
                        className={
                            Helpers.conditionalClass(
                                isFarm,
                                Style.messageOverlayButton,
                                Style.messageOverlayButtonRed
                            )}
                        onClick={
                            isConnected ?
                            onSwitchNetwork :
                            onConnectWallet
                        }
                        text={
                            isConnected ?
                            Language.SWITCH_NETWORK :
                            Language.CONNECT_WALLET
                        }/>
                </div>
                :
                isLoading ?
                <div className={Style.messageOverlayContainer}>
                    <div className={Style.loaderContainer}>
                        <Loader className={Style.loader}/>
                        <p className={Style.messageOverlayText}>
                            { Language.FETCHING_DATA }
                        </p>
                    </div>
                </div>
                :
                isWaitingTransaction ?
                <div className={Style.messageOverlayContainer}>
                    <div className={Style.loaderContainer}>
                        <Loader className={Style.loader}/>
                        <p className={Style.messageOverlayText}>
                            { Language.WAITING_FOR_CONFIRMATION }
                        </p>
                    </div>
                </div>
                :
                pendingTransactions && pendingTransactions.length > 0 ?
                <div className={Style.messageOverlayContainer}>
                    <div className={Style.loaderContainer}>
                        <Loader className={Style.loader}/>
                        <p className={Style.messageOverlayText}>
                            { pendingTransactions[0].description }
                        </p>
                    </div>
                </div>
                :
                null
            }

            <div className={
                Helpers.conditionalClass(
                    !isConnected || !isInNetwork || isLoading || isWaitingTransaction ||
                    (pendingTransactions && pendingTransactions.length > 0),
                    Style.panelContainer,
                    Style.blurred
                )}>
                <div className={
                    Helpers.conditionalClass(
                        isOpen,
                        Helpers.conditionalClass(
                            isFarm,
                            Style.overlayPanel,
                            Style.overlayPanelRed
                        ),
                        Style.overlayPanelOpen
                    )}>
                    {
                        currentPanel === StakingControl.Panels.Stake ?
                        <StakePanel
                            isFarm={isFarm}
                            deposit={deposit}
                            balance={balance}
                            assetPrice={assetPrice}
                            depositAssetPrice={depositAssetPrice}
                            apr={apr}
                            onStakeClicked={onStakeClicked}
                            onClose={handleClosePanel}/>
                        :
                        currentPanel === StakingControl.Panels.Unstake ?
                        <UnstakePanel
                            isFarm={isFarm}
                            deposit={deposit}
                            assetPrice={assetPrice}
                            onUnstakeClicked={onUnstakeClicked}
                            onClose={handleClosePanel}/>
                        :
                        currentPanel === StakingControl.Panels.Info ?
                        <InfoPanel
                            isFarm={isFarm}
                            tvl={tvl}
                            deposit={deposit}
                            assetPrice={assetPrice}
                            contractAddress={contractAddress}
                            onClose={handleClosePanel}/>
                        :
                        null
                    }
                </div>

                <div className={
                    Helpers.conditionalClass(
                        isFarm,
                        Style.mainPanel,
                        Style.mainPanelRed
                    )
                }>
                    {
                        Helpers.weiToEth(deposit) > 0 ?
                        <DepositPanel
                            isFarm={isFarm}
                            deposit={deposit}
                            blockReward={blockReward}
                            unclaimed={unclaimed}
                            assetPrice={assetPrice}
                            apr={apr}
                            onInfoClicked={handleInfo}
                            onDepositClicked={handleDeposit}
                            onWithdrawClicked={handleWithdraw}
                            onClaimClicked={onClaimClicked}/>
                        :
                        <NoDepositPanel
                            isFarm={isFarm}
                            isApproved={isApproved}
                            tvl={tvl}
                            apr={apr}
                            assetPrice={assetPrice}
                            blockReward={blockReward}
                            onInfoClicked={handleInfo}
                            onDepositClicked={handleDeposit}
                            onApproveClicked={onApproveClicked}/>
                    }
                </div>
            </div>
        </div>
    )
}

StakingControl.Panels = {
    None: 0,
    Info: 1,
    Stake: 2,
    Unstake: 3
}

export default StakingControl