import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { usePopupManager } from '~/Context/PopupManagerContext';
import axios from 'axios';
import validator from 'validator';
import MessagePopup from '~/Popups/MessagePopup';
import LegalPopup from '~/Popups/LegalPopup';
import Button from '../Button';
import Language from '~/Language';
import Config from '~/config';
import Style from './Footer.module.css';

function Footer() {
    const { showPopup } = usePopupManager();
    const routerNavigate = useNavigate();

    const [ email, setEmail ] = useState('');
    const [ isSubmitting, setIsSubmitting ] = useState(false);

    const handleOpenTOS = () => {
        showPopup(LegalPopup, {isTOS: true})
    }

    const handleOpenFAQ = () => {
        routerNavigate("/faq"); 
    }

    const handleOpenPrivacyPolicy = () => {
        showPopup(LegalPopup, {isTOS: false})
    }

    const handleSubscribeNewsletter = () => {
        if(!validator.isEmail(email)) {
            showPopup(
                MessagePopup,
                {
                    title: Language.INVALID_EMAIL,
                    messageLines: [ Language.EMAIL_ADDRESS_NOT_VALID ],
                }
            )
            return;
        }
        
        setIsSubmitting(true)
        axios
            .post(
                Config.API_BASE_URL + '/newsletter',
                { email },
                {
                    headers: { 
                        'Content-Type': 'application/x-www-form-urlencoded' 
                    }
                }
            )
            .then(() => {
                showPopup(
                    MessagePopup,
                    {
                        title: Language.SUBSCRIBED,
                        messageLines: [ Language.THANK_YOU_FOR_SUBSCRIBING ],
                    }
                )
                setEmail('')
            })
            .catch(() => {
                showPopup(
                    MessagePopup,
                    {
                        title: Language.FAILED_TO_SUBSCRIBE,
                        messageLines: [ Language.COULD_NOT_SUBSCRIBE ],
                    }
                )
            })
            .finally(() => {
                setIsSubmitting(false)
            })
    }

    return (
        <div className={Style.container}>
            <div className={Style.mainSection}>
                <div className={Style.leftContainer}>
                    <p className={Style.logoText}>
                        GRAVEL<span>777</span>
                    </p>
                    <p className={Style.description}>
                        { Language.EXPERIENCE_THE_EXCITEMENT }
                    </p>
                </div>

                <div className={Style.spacer}/>

                <div className={Style.rightContainer}>
                    <p className={Style.title}>
                        { Language.STAY_IN_THE_LOOP }
                    </p>
                    <p className={Style.description}>
                        { Language.JOIN_OUR_MAILING_LIST }
                    </p>

                    <div className={Style.signUpContainer}>
                        <input
                            type="email" 
                            placeholder={ Language.YOUR_EMAIL_ADDRESS }
                            className={Style.input}
                            disabled={isSubmitting}
                            value={email}
                            onChange={e => setEmail(e.target.value)}/>

                        <Button
                            className={Style.button}
                            isLoading={isSubmitting}
                            text={
                                isSubmitting ?
                                Language.SIGNING_UP :
                                Language.SIGN_UP
                            }
                            onClick={handleSubscribeNewsletter}/>
                    </div>

                    <p className={Style.title}>
                        { Language.JOIN_THE_COMMUNITY }
                    </p>

                    <div className={Style.iconListContainer}>
                        {
                            Config.SocialMedia
                                .filter(item => item.url)
                                .map(item => 
                                    <a 
                                        key={item.type}    
                                        href={item.url} 
                                        title={item.type} 
                                        target="_blank"
                                        className={Style.iconContainer}>
                                        { React.createElement(item.icon, {className: Style.icon})}
                                    </a>    
                                )
                        }
                    </div>
                </div>
            </div>

            <div className={Style.bottomBar}>
                <p className={Style.copyright}>
                    { `© ${new Date().getFullYear()} GRAVEL777` } 
                </p>

                <div className={Style.spacer}/>
                
                <div className={Style.bottomBarLinkContainer}>    
                    <p className={Style.tosLink} onClick={handleOpenPrivacyPolicy}>
                        { Language.PRIVACY_POLICY }
                    </p>

                    <p className={Style.tosLink} onClick={handleOpenFAQ}>
                        FAQ
                    </p>

                    <p className={Style.tosLink} onClick={handleOpenTOS}>
                        { Language.TERMS_OF_SERVICE }
                    </p>
                </div>
            </div>
        </div>
    )
}

export default Footer