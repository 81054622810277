import React from 'react'

function CloseIcon({
    className,
    onClick
}) {
    return (
        <svg 
            className={className} 
            onClick={onClick}
            viewBox="0 0 32 32" 
            fill="none" 
            xmlns="http://www.w3.org/2000/svg">
            <circle cx="16" cy="16" r="16" stroke="none" fill="black" />
            <path d="M10.5996 10.6016L21.3996 21.4016" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M21.3996 10.6016L10.5996 21.4016" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        </svg>

    )
}

export default CloseIcon