import React, { useMemo, useState } from 'react'
import { useRoundData } from '~/API/RoundData';
import ReactPaginate from 'react-paginate'
import RoundResultTableItem from '../RoundResultTableItem'
import Loader from '~/Assets/Vectors/Loader';
import Language from '~/Language';
import Helpers from '~/helpers';
import Config from '~/config';
import Style from './RoundResultTable.module.css'

function RoundResultTable() {
    const { data, isLoading, isError } = useRoundData();
    const [ currentPage, setCurrentPage ] = useState(0);

    const pageCount = useMemo(
        () => {
            if(!isLoading && !isError) {
                return Math.ceil(
                    data.previousRounds.length / 
                    Config.Defaults.ROUND_TABLE_ITEMS_PER_PAGE
                )
            }
            else {
                return 1
            }
        },
        [ data, isLoading, isError]
    )

    const roundListSubset = useMemo(
        () => {
            if(!isLoading && !isError) {
                return data.previousRounds.slice(
                    currentPage * Config.Defaults.ROUND_TABLE_ITEMS_PER_PAGE,
                    (currentPage + 1) * Config.Defaults.ROUND_TABLE_ITEMS_PER_PAGE
                )
            }
            else {
                return []
            }
        },
        [ data, isLoading, isError, currentPage]
    )

    const handlePageClick = (page) => {
        setCurrentPage(page.selected)
    }

    return (
        <div className={Style.container}>
            {
                (isLoading || isError) &&
                <div className={Style.loadingContainer}>
                    {
                        isLoading &&
                        <Loader className={Style.loader}/>
                    }
                    <p className={Style.loadingText}>
                    {
                        isLoading ?
                        Language.FETCHING_DATA :
                        Language.FAILED_TO_FETCH_DATA
                    }
                    </p>
                </div>
            }
            <div className={
                Helpers.conditionalClass(
                    isLoading || isError,
                    Style.tableContainer,
                    Style.containerLoading
                )}>
                <div className={Style.headerContainer}>
                    <p className={Style.headerLabel}>
                        { Language.ROUND }
                    </p>
                    <p className={Helpers.joinClasses(Style.headerLabel, Style.dateValue)}>
                        { Language.DATE }
                    </p>
                    <p className={Style.headerLabel}>
                        { Language.JACKPOT }
                    </p>
                    <p className={Helpers.joinClasses(Style.headerLabel, Style.mintValue)}>
                        { Language.NFT_MINT }
                    </p>
                    <p className={Style.headerLabel}>
                        { Language.PASSIVE_INCOME }
                    </p>
                </div>

                {
                    isLoading || isError ?
                    Array.from(Array(Config.Defaults.ROUND_TABLE_ITEMS_PER_PAGE).keys()).map((item) =>
                        <RoundResultTableItem
                            key={item}/>
                    )
                    :
                    roundListSubset.map(item => 
                        <RoundResultTableItem
                            key={item.round}
                            round={item.round}
                            txHash={item.txId}
                            tokenPrice={item.tokenPrice}
                            date={item.endTime}
                            jackpot={item.jackpotAmount}
                            nftMint={item.nftMintTokenId}
                            passiveIncomeValue={item.passiveIncomeAmount}
                            passiveIncomeWinner={item.passiveIncomeTokenId}/>
                    )
                }
            </div>

            <ReactPaginate
                className={
                    Helpers.conditionalClass(
                    isLoading || isError,
                    Style.paginationContainer,
                    Style.containerLoading
                )}
                pageLinkClassName={Style.paginationPageClassName}
                activeLinkClassName={Style.paginationActivePageClassName}
                nextLinkClassName={Style.paginationNavigation}
                previousClassName={Style.paginationNavigation}
                breakClassName={Style.paginationBreak}
                breakLabel=". . ."
                nextLabel=">"
                onPageChange={handlePageClick}
                pageRangeDisplayed={2}
                pageCount={pageCount}
                previousLabel="<"
                renderOnZeroPageCount={null} />
        </div>
    )
}

export default RoundResultTable