import React, { useRef, useState } from 'react';
import Loader from '~/Assets/Vectors/Loader';
import Style from './Button.module.css';
import { cx } from '@emotion/css';

const Button = ({
  className,
  text,
  disabled,
  isLoading,
  loaderColor,
  onClick
}) => {
  const hoverDotRef = useRef(null);
  const [isHovering, setIsHovering] = useState(false);
  const [isMouseDown, setIsMouseDown] = useState(false);
  const [dotCoords, setDotCoords] = useState([0,0]);
  const moveHoverDot = (event) => {
    if(!hoverDotRef || !isHovering) {
      return;
    }

    const buttonBound = event.target.getBoundingClientRect();

    const mouseLeft = event.clientX - buttonBound.left;
    const mouseTop = event.clientY - buttonBound.top;
    
    setDotCoords([mouseLeft, mouseTop]);
  }

  return (
    <button 
      className={cx(className, Style.button, {
        [Style.buttonDisabled]: disabled || isLoading,
        [Style.pressed]: isMouseDown,
      })}
      onClick={onClick} 
      disabled={disabled}
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => {
        setIsHovering(false)
        setIsMouseDown(false)
      }}
      onMouseDown={() => setIsMouseDown(true)}
      onMouseUp={() => setIsMouseDown(false)}
      onMouseMove={moveHoverDot}
    > 
      <div
        ref={hoverDotRef}
        className={cx(Style.buttonHoverDot, {
          [Style.hidden]: !isHovering,
        })}
        style={{translate: `${dotCoords[0]}px ${dotCoords[1]}px`}}
      />

      {isLoading && 
        <Loader 
          className={Style.loader}
          color={loaderColor}
        /> 
      }
      <p className={Style.text}>
        { text }
      </p>
    </button>
  )
}

export default Button;