import { ethers } from 'ethers';
import Config from './config';

class Helpers {

    static joinClasses(...classes) {
        let result = '';
        for(let cls of classes)
            if(cls)
                result += ((result.length === 0) ? '' : ' ') + cls;

        return result;
    }

    static conditionalClass(condition, primary, conditional) {
        return (
            primary +
            (
                condition ?
                ' ' + conditional : 
                ''
            )
        )
    }

    static async sleep(timeMilliseconds = 1000) {
        return new Promise(resolve => {
            setTimeout(() => {
                resolve()
            }, timeMilliseconds)
        })
    }

    static getElapsedTimeString(to, from, endString = ' ago', lessThanMinuteString = 'just now') {
        if (!from)
            from = Date.now();

        let differenceMinutes = Math.floor(
            Math.abs(
                new Date(to).getTime() -
                new Date(from).getTime()
            ) / 60000
        );

        let differenceInUnits = {
            year: Math.floor(differenceMinutes / (60 * 24 * 365)),
            month: Math.floor(differenceMinutes / (60 * 24 * 30)),
            day: Math.floor(differenceMinutes / (60 * 24)),
            hour: Math.floor(differenceMinutes / 60),
            minute: differenceMinutes
        }

        for (let unit in differenceInUnits) {
            let valueForUnit = differenceInUnits[unit];
            let unitString = unit + (valueForUnit > 1 ? 's' : '');

            if (valueForUnit > 0)
                return `${valueForUnit} ${unitString}${endString}`;
        }

        return lessThanMinuteString;
    }

    static getTimeUntilDate(date) {
        let target = (new Date(date)).getTime();
        let timeDifference = Math.floor((target - Date.now()) / 1000) + Config.Defaults.ROUND_TIME_SECONDS;
        let isPast = timeDifference < 0;

        let hours = Math.floor(timeDifference / 3600);
        let minutes = Math.floor(timeDifference % 3600 / 60);
        let seconds = Math.floor(timeDifference % 60);

        return {
            isPast,
            hours: isPast ? 0 : hours,
            minutes: isPast ? 0 : minutes,
            seconds: isPast ? 0 : seconds
        }
    }

    static stringToHex(value) {
        return value
            .split('')
            .map(character => character.charCodeAt(0).toString(16))
            .join('');
    }

    static getTruncatedAddress(address) {
        if (!address)
            address = '0x0000000000000000000000000000000000000000';

        return address.substr(0, 4) + '...' + address.substr(-4);
    }

    static formatUsdValue(value) {
        let numberValue = parseFloat(value);
        numberValue =
            Number.isNaN(numberValue) || !Number.isFinite(numberValue) ?
                0 : numberValue;

        return numberValue.toLocaleString("en-US", { style:"currency", currency:"USD" });
    }

    static formatValue(value, deicmals = 2) {
        let numberValue = parseFloat(value);
        numberValue =
            Number.isNaN(numberValue) || !Number.isFinite(numberValue) ?
                0 : numberValue;

        return numberValue.toFixed(deicmals);
    }

    static weiToEth(value, digits) {
        return (
            !value ? 0 :
            !digits ? parseFloat(ethers.utils.formatEther(value)) :
            parseFloat(ethers.utils.formatEther(value)).toFixed(digits)
        )
    }

    static parseStablecoinValue(value, digits) {
        return (
            !value ? 0 :
            !digits ? parseFloat(ethers.utils.formatUnits(value, 6)) :
            parseFloat(ethers.utils.formatUnits(value, 6)).toFixed(digits)
        )
    }

    static ethToWei(value) {
        return ethers.utils.parseEther(value)
    }

    static weiToEthString(value) {
        return ethers.utils.formatEther(value)
    }

    static getRequestErrorMessage(response) {
        if (!response || response instanceof String)
            return response;

        const splitResponse = response.split(':');

        if (splitResponse.length > 0 && !splitResponse[0].includes(' '))
            return splitResponse.slice(1, splitResponse.length).join(':')
        else
            return response;
    }

    static getRequestErrorField(response) {
        if (!response || response instanceof String)
            return response;

        const splitResponse = response.split(':');

        if (splitResponse.length > 0 && !splitResponse[0].includes(' '))
            return splitResponse[0];
        else
            return response;
    }

    static isSameAddress(addressA, addressB) {
        return !!(
            addressA && addressB &&
            addressA.toLowerCase() === addressB.toLowerCase()
        )
    }
}

export default Helpers;