import { BigNumber, ethers } from 'ethers';
import PublicSaleABI from './ABI/PublicSale.js';
import LocalStorage from '../LocalStorage.js';
import Config from '~/config.js';
import Language from '~/Language/index.js';

class PublicSale {
    constructor(web3Interface, signer, swapRouter) {
        this.web3Interface = web3Interface;
        this.signer = signer;
        this.swapRouter = swapRouter;
        this.lastSparseUpdate = 0;
        this.isInitialized = true;
        this.isFocused = false;
        this.state = null;

        this.contract = new ethers.Contract(
            Config.Blockchain.Contracts.PUBLIC_SALE, 
            PublicSaleABI, 
            signer
        );
    }

    async updateState() {
        if(!this.isFocused && this.state !== null)
            return;

        let newState = {...this.state}

        if(Date.now() > this.lastSparseUpdate + Config.Blockchain.SPARSE_UPDATE_RATE_MS || this.state === null) {
            this.lastSparseUpdate = Date.now();
            newState.saleState = await this.contract.saleState()
            newState.totalInvested = await this.contract.totalInvested()
        }

        let userData = await this.contract.userData(this.web3Interface.address)
        newState.buyAmount = userData.buyAmount; 
        newState.spentAmount = userData.spentAmount; 
        newState.totalPurchasedTokens = userData.totalPurchasedTokens;
        newState.totalRewardedTokens = userData.totalRewardedTokens;
        
        if(newState.saleState === 2 && userData.buyAmount.gt(0)) {
            let claimResponse = await this.contract.callStatic.claimTokens(0, Math.floor(Date.now() / 1000 + 100))
            newState.availableAmount = claimResponse.availableAmount;
            newState.purchasedTokens = claimResponse.purchasedTokens;
            newState.rewardedTokens = claimResponse.rewardedTokens;
        }
        else {
            newState.availableAmount = BigNumber.from(0);
            newState.purchasedTokens = BigNumber.from(0);
            newState.rewardedTokens = BigNumber.from(0);
        }

        this.state = newState;

        return this.state;
    }

    clearState() {
        this.state = null;
    }

    async claimTokens() {
        let availableAmount = await this.contract.getAvailableAmount(this.web3Interface.address);

        let amountOutMin = availableAmount
            .mul(10000 * (1 - LocalStorage.getSlippage()))
            .div(10000);

        let deadline = 
            Math.floor(Date.now() / 1000 + LocalStorage.getDeadlinePeriod() * 60)

        let txData = await this.contract.claimTokens(
            amountOutMin,
            deadline
        )
    
        this.web3Interface.addPendingTransaction(
            txData.hash, 
            'PUBLIC_SALE_CLAIM', 
            Language.CLAIMING_TOKENS
        )

        return txData;
    }

    async buyEth(amount) {
        let deadline = 
            Math.floor(Date.now() / 1000 + LocalStorage.getDeadlinePeriod() * 60)

        let txData = await this.contract.buy(
            0,
            deadline,
            { value: amount }
        )

        this.web3Interface.addPendingTransaction(
            txData.hash, 
            'PUBLIC_SALE_BUY', 
            Language.CREATING_POSITION
        )

        return txData;
    }
}

export default PublicSale;