import React from 'react'

function ArrowLeft({
    className,
    onClick
}) {
    return (
        <svg 
            className={className}
            onClick={onClick}
            viewBox="0 0 32 32" 
            xmlns="http://www.w3.org/2000/svg">
            <circle cx="16" cy="16" r="15.5" transform="rotate(180 16 16)" fill="none"/>
            <path d="M17.196 12.054C17.4386 12.2965 17.4386 12.6897 17.196 12.9322L13.9091 16.2191L17.196 19.506C17.4386 19.7485 17.4386 20.1417 17.196 20.3842C16.9535 20.6268 16.5603 20.6268 16.3178 20.3842L12.5918 16.6582C12.3493 16.4157 12.3493 16.0225 12.5918 15.78L16.3178 12.054C16.5603 11.8114 16.9535 11.8114 17.196 12.054Z" stroke="none"/>
        </svg>

    )
}

export default ArrowLeft;
