import React from 'react'

function ArrowRightIcon({
    className, 
    circleColor,
    lineColor
}) {
    return (
        <svg 
            className={className}
            viewBox="0 0 38 38" 
            fill="none" 
            xmlns="http://www.w3.org/2000/svg">
            <circle cx="19" cy="19" r="19" fill={ circleColor || "var(--c-orange)" }/>
            <path d="M17.5673 23.6861C17.2793 23.3981 17.2793 22.9312 17.5673 22.6432L21.4705 18.74L17.5673 14.8368C17.2793 14.5488 17.2793 14.0819 17.5673 13.7939C17.8553 13.5059 18.3222 13.5059 18.6102 13.7939L23.0349 18.2185C23.3229 18.5065 23.3229 18.9735 23.0349 19.2614L18.6102 23.6861C18.3222 23.9741 17.8553 23.9741 17.5673 23.6861Z" fill={ lineColor || "black" }/>
        </svg>
    )
}

export default ArrowRightIcon