import React, { useState, useContext, createContext, useEffect } from 'react';
import Web3Interface from '../Utilities/Web3Interface';

const Web3Context = createContext();
const Web3StatusContext = createContext();
const Web3AccountContext = createContext();
const Web3StateContext = createContext();
const Web3PendingTransactionsContext = createContext();

export function useWeb3() {
    return useContext(Web3Context);
}

export function useWeb3Status() {
    return useContext(Web3StatusContext);
}

export function useWeb3Account() {
    return useContext(Web3AccountContext);
}

export function useWeb3State() {
    return useContext(Web3StateContext);
}

export function useWeb3PendingTransactions() {
    return useContext(Web3PendingTransactionsContext);
}

export function Web3Provider({ children }) {
    const [isConnected, setIsConnected] = useState(null);
    const [blockNumber, setBlockNumber] = useState(null);
    const [networkId, setNetworkId] = useState(null);
    const [provider, setProvider] = useState(null);
    const [account, setAccount] = useState(null);
    const [state, setState] = useState(null);
    const [pendingTransactions, setPendingTransactions] = useState(null);

    useEffect(() => {
        Web3Interface.setOnAccountChangedCallback(setAccount);
        return () => {
            Web3Interface.setOnAccountChangedCallback(null);
        }
    }, [setAccount])

    useEffect(() => {
        Web3Interface.setOnStateUpdateCallback(setState);
        return () => {
            Web3Interface.setOnStateUpdateCallback(null);
        }
    }, [setState])

    useEffect(() => {
        Web3Interface.setOnPendingTransactionListChangedCallback(setPendingTransactions);
        return () => {
            Web3Interface.setOnPendingTransactionListChangedCallback(null);
        }
    }, [setPendingTransactions])

    useEffect(() => {
        Web3Interface.setOnConnectionStatusChangeCallback(setIsConnected);
        return () => {
            Web3Interface.setOnConnectionStatusChangeCallback(null);
        }
    }, [setIsConnected])

    useEffect(() => {
        Web3Interface.setOnNewBlockCallback(setBlockNumber);
        return () => {
            Web3Interface.setOnNewBlockCallback(null);
        }
    }, [setBlockNumber])

    useEffect(() => {
        Web3Interface.setOnNetworkChangedCallback(setNetworkId);
        return () => {
            Web3Interface.setOnNetworkChangedCallback(null);
        }
    }, [setNetworkId])

    useEffect(() => {
        Web3Interface.setOnProviderChangedCallback(setProvider);
        return () => {
            Web3Interface.setOnProviderChangedCallback(null);
        }
    }, [setProvider])

    return (
        <Web3Context.Provider value={Web3Interface}>
            <Web3StatusContext.Provider value={{ isConnected, networkId, blockNumber, provider }}>
                <Web3AccountContext.Provider value={account ? account.toLowerCase() : null}>
                    <Web3StateContext.Provider value={state}>
                        <Web3PendingTransactionsContext.Provider value={pendingTransactions}>
                            {children}
                        </Web3PendingTransactionsContext.Provider>
                    </Web3StateContext.Provider>
                </Web3AccountContext.Provider>
            </Web3StatusContext.Provider>
        </Web3Context.Provider>
    )
}