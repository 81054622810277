import React from 'react'
import { usePopupManager } from '~/Context/PopupManagerContext'
import Images from '~/Assets/Images'
import VideoPopup from '~/Popups/VideoPopup'
import Button from '../Button'
import Language from '~/Language'
import Style from './AboutUpperSection.module.css'

function AboutUpperSection({
    title,
    description
}) {
    const { showPopup } = usePopupManager()

    const handleHowItWorks = () => {
        showPopup(VideoPopup, {});
    }

    return (
        <div className={Style.container}>

            <div
                className={Style.botImage}
                style={{ backgroundImage: `url("${Images.BotsHome}")`}}/>

            <div className={Style.contentsContainer}>
                <div className={Style.leftContainer}>
                    <p className={Style.title}>
                        { title }
                    </p>

                    <p className={Style.description}>
                        { description }
                    </p>

                    <Button
                        className={Style.button}
                        onClick={handleHowItWorks}
                        text={Language.HOW_GRAVEL_777_WORKS}/>
                </div>
            </div>
        </div>
    )
}

export default AboutUpperSection