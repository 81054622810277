import React, { useState, useEffect } from 'react'
import { useLanguageTrigger, useSetCurrentLanguage } from '~/Context/LanguageContext';
import { motion } from 'framer-motion';
import Images from '~/Assets/Images';
import Language from '~/Language';
import Config from '~/config';
import Helpers from '~/helpers';
import Style from './LanguageDropdown.module.css'

function LanguageDropdown({
    className,
    isSideMenu
}) {
    const setLanguage = useSetCurrentLanguage();
    const languageTrigger = useLanguageTrigger();

    const [ selectedLanguage, setSelectedLanguage ] = useState(Language.getLanguage())
    const [ isOpen, setIsOpen ] = useState(false)

    useEffect(
        () => {
            setSelectedLanguage(Language.getLanguage())
        },
        [languageTrigger]
    );

    const handleOpen = (event) => {
        event.preventDefault();
        setIsOpen(true);
    }

    const handleCancel = (event) => {
        event.preventDefault();
        setIsOpen(false);
    }

    const handleSelectItem = (language) => {
        setSelectedLanguage(language)
        setLanguage(language)
        setIsOpen(false);
    }

    const variants = {
        open: { 
            opacity: 1, 
            y: 0,
            x: 0,
            display: 'flex'
        },
        closed: { 
            opacity: 0, 
            y: -10,
            x: 0,
            display: 'none'
        },
    }

    return (
        Config.MultilanguageEnabled ?
        <div className={Helpers.joinClasses(Style.container, className)}>
            { 
                isOpen &&
                <div className={Style.cancelOverlay} onClick={handleCancel}/>
            }

            <motion.div 
                initial={false}
                animate={isOpen ? "open" : "closed"}
                transition={{ duration: 0.2 }}
                className={
                    Helpers.conditionalClass(
                        isSideMenu,
                        Style.menuContainer,
                        Style.sideMenuMenuContainer
                    )
                }
                variants={variants}>
                {
                    Language
                        .getAvailableLanguages()
                        .map((item, index) =>
                        <div 
                            key={index}
                            className={Style.menuItemCotainer}
                            onClick={handleSelectItem.bind(null, item)}>
                            
                            <div 
                                className={Style.menuItemIcon}
                                style={{backgroundImage: `url("${Images.Flags[item]}")`}}/>
                            
                            <p className={Style.menuItemText}>
                                { Language.getString('LANGUAGE_NAME', item) }
                            </p>   
                        </div>
                    )   
                }
            </motion.div>

            <div 
                className={
                    Helpers.conditionalClass(
                        isSideMenu,
                        Style.iconContainer,
                        Style.sideMenuIconContainer
                    )
                }  
                onClick={handleOpen}>
                <div 
                    className={Style.icon}
                    style={{backgroundImage: `url("${Images.Flags[selectedLanguage]}")`}}/>
            </div>

        </div>
        :
        <div className={Style.spacer}/>
    )
}

export default LanguageDropdown