import React, { Fragment, useCallback, useMemo, useState } from 'react'
import { useWeb3PendingTransactions, useWeb3State, useWeb3Status } from '~/Context/Web3Context'
import { usePopupManager } from '~/Context/PopupManagerContext'
import Web3Interface from '~/Utilities/Web3Interface'
import MessagePopup from '~/Popups/MessagePopup'
import ConnectWalletPopup from '~/Popups/ConnectWalletPopup'
import HorizontalSpacer from '~/Assets/Vectors/HorizontalSpacer'
import Dropdown from '../Dropdown'
import Button from '../Button'
import Loader from '~/Assets/Vectors/Loader'
import Language from '~/Language'
import Helpers from '~/helpers'
import Config from '~/config'
import Style from './VestingControls.module.css'

function VestingControls() {
    const [ selectedSource, setSelectedSource ] = useState(0);
    const [ isClaiming, setIsClaiming ] = useState(false);

    const state = useWeb3State();
    const allPendingTransactions = useWeb3PendingTransactions()
    const { isConnected, networkId } = useWeb3Status();
    const { showPopup } = usePopupManager();

    const getPublicSaleState = useCallback(
        () => {
            let percentageUsed = 0;
            let percentageAvailable = 0;

            if(state?.publicSale?.buyAmount.gt(0)) {
                percentageUsed = state?.publicSale?.spentAmount
                    .mul(100000)
                    .div(state?.publicSale?.buyAmount)
                    .toNumber() / 1000
        
                percentageAvailable = state?.publicSale?.availableAmount
                    .mul(10000000)
                    .div(state?.publicSale?.buyAmount)
                    .toNumber() / 100000
            }

            let claimable = Helpers.weiToEth(
                state?.publicSale?.purchasedTokens?.add(
                    state?.publicSale?.rewardedTokens
                )
            );

            let vested = Helpers.weiToEth(state?.publicSale?.totalPurchasedTokens);
            let rewarded = Helpers.weiToEth(state?.publicSale?.totalRewardedTokens);

            return {
                fields: [
                    {
                        value: Helpers.formatValue(vested),
                        label: Language.G777_VESTED
                    },
                    {
                        value: Helpers.formatValue(rewarded),
                        label: Language.G777_REWARDED
                    },
                    {
                        value: Helpers.formatValue(percentageUsed) + '%',
                        label: Language.INVESTMENT_CLAIMED
                    },
                ],
                data: {
                    percentageUsed,
                    percentageAvailable,
                    claimable,
                    vested,
                    rewarded
                }
            }
        },
        [state]
    )

    const getAirdropState = useCallback(
        () => {
            let percentageUsed = 0;
            let percentageAvailable = 0;

            if(state?.airdrop?.total?.gt(0)) {
                percentageUsed = state?.airdrop?.claimed
                    .mul(100000)
                    .div(state?.airdrop?.total)
                    .toNumber() / 1000
        
                percentageAvailable = state?.airdrop?.claimable
                    .mul(10000000)
                    .div(state?.airdrop?.total)
                    .toNumber() / 100000
            }

            let claimable = Helpers.weiToEth(state?.airdrop?.claimable);
            let total = Helpers.weiToEth(state?.airdrop?.total);
            let claimed = Helpers.weiToEth(state?.airdrop?.claimed);

            return {
                fields: [
                    {
                        value: Helpers.formatValue(total),
                        label: Language.TOTAL_G777
                    },
                    {
                        value: Helpers.formatValue(claimed),
                        label: Language.TOKENS_CLAIMED
                    },
                    {
                        value: Helpers.formatValue(percentageUsed) + '%',
                        label: Language.PERCENTAGE_CLAIMED
                    },
                ],
                data: {
                    percentageUsed,
                    percentageAvailable,
                    claimable,
                    total,
                    claimed
                }
            }
        },
        [state]
    )

    const getVestingState = useCallback(
        () => {
            let percentageUsed = 0;
            let percentageAvailable = 0;

            if(state?.vesting?.totalVested?.gt(0)) {
                percentageUsed = state?.vesting?.claimedVested
                    .mul(100000)
                    .div(state?.vesting?.totalVested)
                    .toNumber() / 1000
        
                percentageAvailable = state?.vesting?.claimableVested
                    .mul(10000000)
                    .div(state?.vesting?.totalVested)
                    .toNumber() / 100000
            }

            let claimable = Helpers.weiToEth(state?.vesting?.claimableVested?.add(state?.vesting?.claimableReward));
            let vested = Helpers.weiToEth(state?.vesting?.claimedVested);
            let rewarded = Helpers.weiToEth(state?.vesting?.claimedReward);
            let lockStatus = state?.vesting?.lockStatus

            return {
                fields: [
                    {
                        value: Helpers.formatValue(vested),
                        label: Language.VESTED_G777
                    },
                    {
                        value: Helpers.formatValue(rewarded),
                        label: Language.REWARDED_G777
                    },
                    {
                        value: Helpers.formatValue(percentageUsed) + '%',
                        label: Language.PERCENTAGE_CLAIMED
                    },
                ],
                data: {
                    percentageUsed,
                    percentageAvailable,
                    claimable,
                    vested,
                    rewarded,
                    lockStatus
                }
            }
        },
        [state]
    )

    const vestingSources = useMemo(
        () => {
            let sourceList = [];

            if(state?.publicSale?.buyAmount?.gt(0) && state?.publicSale?.saleState === 2)
                sourceList.push(Language.PUBLIC_SALE)

            if(state?.airdrop?.total?.gt(0))
                sourceList.push(Language.AIRDROP)

            if(state?.vesting?.totalVested?.gt(0))
                sourceList.push(Language.INVESTOR)

            return sourceList;
        },
        [state]
    )

    const sourceData = useMemo(
        () => {
            if(vestingSources[selectedSource] === Language.PUBLIC_SALE)
                return getPublicSaleState();
            else 
            if(vestingSources[selectedSource] === Language.INVESTOR)
                return getVestingState();
            else 
            if(vestingSources[selectedSource] === Language.AIRDROP)
                return getAirdropState();
            else
                return null;
        },
        [
            selectedSource, 
            vestingSources,
            getPublicSaleState,
            getVestingState,
            getAirdropState
        ]
    )

    const pendingTransactions = useMemo(
        () => {
            return (allPendingTransactions || []).filter(item =>
                item.tag.startsWith('PUBLIC_SALE') ||
                item.tag.startsWith('VESTING') ||
                item.tag.startsWith('AIRDROP')
            )
        },
        [ allPendingTransactions ]
    )

    const handleClaimClicked = async () => {
        setIsClaiming(true);
        try {
            if(vestingSources[selectedSource] === Language.PUBLIC_SALE) {
                await Web3Interface.publicSale.claimTokens();
            }
            else 
            if(vestingSources[selectedSource] === Language.INVESTOR) {
                let lockStatus = sourceData?.data?.lockStatus;

                if(lockStatus === 0) {
                    await Web3Interface.vesting.claimTokens();
                }
                else {
                    showPopup(
                        MessagePopup,
                        {
                            title: Language.INVESTMENT_LOCKED,
                            messageLines: [ 
                                Language[`INVESTMENT_LOCKED_REASON_${lockStatus}`] ||
                                Language.INVESTMENT_LOCKED_REASON_UNKNOWN
                            ]
                        }
                    )
                }
            }
            else 
            if(vestingSources[selectedSource] === Language.AIRDROP) {
                await Web3Interface.airdrop.claimTokens();
            }
        }
        finally {
            setIsClaiming(false)
        }
    }

    const handleConnect = () => {
        showPopup(ConnectWalletPopup, {})
    }

    const handleSwitchNetwork = () => {
        Web3Interface.requestNetworkSwitch(Config.Blockchain.Network.Configuration);
    }

    return (
        <div className={Style.container}>
            {
                (!isConnected || (networkId !== Config.Blockchain.Network.ID)) ?
                <div className={Style.messageOverlayContainer}>
                    <p className={Style.messageOverlayText}>
                        {
                            isConnected ?
                            Language.formatString(Language.PLEASE_SWITCH_TO_NETWORK, Config.Blockchain.Network.Configuration.chainName) :
                            Language.PLEASE_CONNECT_YOUR_WALLET
                        }
                    </p>

                    <Button
                        className={Style.messageOverlayButton}
                        onClick={
                            isConnected ?
                            handleSwitchNetwork :
                            handleConnect
                        }
                        text={
                            isConnected ?
                            Language.SWITCH_NETWORK :
                            Language.CONNECT_WALLET
                        }/>
                </div>
                :
                !state ?
                <div className={Style.messageOverlayContainer}>
                    <div className={Style.loaderContainer}>
                        <Loader className={Style.loader}/>
                        <p className={Style.messageOverlayText}>
                            { Language.FETCHING_DATA }
                        </p>
                    </div>
                </div>
                :
                pendingTransactions && pendingTransactions.length > 0 ?
                <div className={Style.messageOverlayContainer}>
                    <div className={Style.loaderContainer}>
                        <Loader className={Style.loader}/>
                        <p className={Style.messageOverlayText}>
                            { pendingTransactions[0].description }
                        </p>
                    </div>
                </div>
                :
                null
            }

            <div className={
                Helpers.conditionalClass(
                    !isConnected || networkId !== Config.Blockchain.Network.ID || !state || 
                    (pendingTransactions && pendingTransactions.length > 0),
                    Style.blockContainer,
                    Style.blurred
                )}>
                <div className={Style.row}>
                    <p className={Style.amountValue}>
                    {
                        sourceData ?
                        `${ Helpers.formatValue(sourceData.data.claimable, 4)} G777` :
                        Language.NOTHING_TO_CLAIM
                    }
                    </p>

                    <p className={Style.amountLabel}>
                    {
                        sourceData &&
                        Language.CLAIMABLE
                    }
                    </p>
                </div>

                <p className={Style.secondaryLabel}>
                {
                    sourceData ?
                    `${ sourceData?.data?.percentageAvailable }${Language.OF_YOUR_TOTAL}` :
                    Language.NO_VESTING_SOURCES
                }
                </p>

                <Dropdown 
                    className={Style.dropdown}
                    items={vestingSources}
                    noItemsLabel={Language.ACCOUNT_NOT_ELIGIBLE}
                    onItemSelected={setSelectedSource}/>

                <HorizontalSpacer width="100%" height="2px"/>

                <div className={Style.statColumns}>
                {
                    sourceData ?
                    sourceData.fields?.map((item, index) =>
                        <div className={Style.column} key={index}>
                            <p className={Style.secondaryValue}>
                                { item.value }
                            </p>
                            <p className={Style.secondaryLabel}>
                                { item.label }
                            </p>
                        </div>
                    )   
                    :
                    <Fragment>
                        <div className={Style.column}>
                            <p className={Style.secondaryValue}>
                                0.00
                            </p>
                            <p className={Style.secondaryLabel}>
                                { Language.TOTAL_G777 }
                            </p>
                        </div>
                        <div className={Style.column}>
                            <p className={Style.secondaryValue}>
                                0.00
                            </p>
                            <p className={Style.secondaryLabel}>
                                { Language.CLAIMED_SO_FAR }
                            </p>
                        </div>
                        <div className={Style.column}>
                            <p className={Style.secondaryValue}>
                                0.00%
                            </p>
                            <p className={Style.secondaryLabel}>
                                { Language.PERCENTAGE_CLAIMED }
                            </p>
                        </div>
                    </Fragment>
                }
                </div>
            </div>

            <div className={
                Helpers.conditionalClass(
                    !isConnected || networkId !== Config.Blockchain.Network.ID || !state || 
                    (pendingTransactions && pendingTransactions.length > 0),
                    Style.blockContainer,
                    Style.blurred
                )}>
                <Button
                    className={Style.claimButton}
                    onClick={handleClaimClicked}
                    loaderColor="#000"
                    isLoading={isClaiming}
                    disabled={
                        !sourceData ||
                        sourceData.data.claimable === 0
                    }
                    text={
                        isClaiming ?
                        Language.CLAIMING_TOKENS :
                        Language.CLAIM_GRAVEL_777
                    } />
            </div>
        </div>
    )
}

export default VestingControls