import React, { useState } from 'react'
import { motion } from 'framer-motion';
import ArrowDown from '~/Assets/Vectors/ArrowDown'
import Helpers from '~/helpers'
import Style from './Dropdown.module.css'

function Dropdown({
    className,
    items,
    onItemSelected,
    initialItem,
    noItemsLabel
}) {
    const [ isOpen, setIsOpen ] = useState(false)
    const [ currentItem, setCurrentItem ] = useState(initialItem || 0);

    const handleOpen = (event) => {
        event.preventDefault();

        if(items?.length > 0)
            setIsOpen(true);
    }

    const handleCancel = (event) => {
        event.preventDefault();
        setIsOpen(false);
    }

    const handleSelectItem = (item) => {
        setCurrentItem(item);
        setIsOpen(false);
        onItemSelected(item)
    }

    const variants = {
        open: { 
            opacity: 1, 
            y: 0,
            x: 0,
            display: 'flex'
        },
        closed: { 
            opacity: 0, 
            y: -10,
            x: 0,
            display: 'none'
        },
    }

    return (
        <div className={Helpers.joinClasses(Style.container, className)}>
            { 
                isOpen &&
                <div className={Style.cancelOverlay} onClick={handleCancel}/>
            }

            <motion.div 
                initial={false}
                animate={isOpen ? "open" : "closed"}
                transition={{ duration: 0.2 }}
                className={Style.menuContainer}
                variants={variants}>
                {
                    items?.map((item, index) =>
                        <p 
                            key={index}
                            className={Style.menuItem}
                            onClick={handleSelectItem.bind(null, index)}>
                            { item }
                        </p>   
                    )   
                }
            </motion.div>

            <div 
                className={
                    Helpers.conditionalClass(
                        isOpen,
                        Helpers.conditionalClass(
                            !items || items?.length === 0,
                            Style.input,
                            Style.inputDisabled
                        ),
                        Style.inputOpen
                    )
                }
                onClick={handleOpen}>
                <p className={Style.value}>
                    { (items && items[currentItem]) || noItemsLabel || '[NO ITEMS]' }
                </p>

                <ArrowDown className={
                    Helpers.conditionalClass(
                        isOpen,
                        Style.arrowIcon,
                        Style.arrowIconOpen
                    )
                } />
            </div>
        </div>
    )
}

export default Dropdown