import { get } from "./Fetcher"
import useSWR from "swr"
import Config from "~/config"

export function useRoundData() {
    const { data, error } = useSWR(
        Config.API_BASE_URL + '/data', 
        get, 
        { refreshInterval: 15000 }
    )
    
    return {
        data,
        isLoading: !error && !data,
        isError: error
    }
}