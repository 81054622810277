import React from 'react'

function ArrowRight({
    className,
    onClick
}) {
    return (
        <svg 
            className={className}
            onClick={onClick}
            viewBox="0 0 32 32" 
            xmlns="http://www.w3.org/2000/svg">
            <circle cx="16" cy="16" r="15.5" fill="none" />
            <path d="M14.7922 19.946C14.5497 19.7035 14.5497 19.3103 14.7922 19.0678L18.0792 15.7809L14.7922 12.494C14.5497 12.2515 14.5497 11.8583 14.7922 11.6158C15.0348 11.3732 15.428 11.3732 15.6705 11.6158L19.3965 15.3418C19.639 15.5843 19.639 15.9775 19.3965 16.22L15.6705 19.946C15.428 20.1886 15.0348 20.1886 14.7922 19.946Z" stroke="none" />
        </svg>
    )
}

export default ArrowRight;
