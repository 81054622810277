import React from 'react'
import Images from '~/Assets/Images'
import Language from '~/Language'
import Style from './PublicSaleUpperSection.module.css'

function PublicSaleUpperSection({
    children
}) {
    return (
        <div className={Style.wrapper}>
            <div className={Style.container}>

                <div className={Style.contentsContainer}>
                    <div className={Style.leftContainer}>
                        <p className={Style.title}>
                            { Language.ENTER_GRAVEL_777_PUBLIC_SALE }
                        </p>

                        <p className={Style.description}>
                            { Language.GRAVEL_777_PUBLIC_SALE_PARAGRAPH }
                        </p>

                    </div>

                    <div className={Style.rightContainer}>
                        { children }
                    </div>
                </div>

                <div className={Style.contentsContainer}>
                    <img
                        className={Style.botImage}
                        src={Images.BotsExplode}
                    />
                </div>
            </div>
        </div>
    )
}

export default PublicSaleUpperSection