import React from 'react';

const DroidLogo = ({
  color = 'white',
  size = 32,
  style = {},
  className = ''
}) => {
  return (
    
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="300 50 135 150"
      style={style}
      className={className}
    >
        <g>
            <g stroke={color} strokeWidth={4}>
                <path d="M432.386,117.999L432.386,137.199" />
                <path d="M437.186,122.799L437.186,132.399" />
                <path d="M441.986,113.199L441.986,141.999" />
            </g>
            <g stroke={color} strokeWidth={4}>
                <path d="M302.786,117.999L302.786,137.199" />
                <path d="M297.986,122.799L297.986,132.399" />
                <path d="M293.186,113.199L293.186,141.999" />
            </g>
            <path fill={color} d="M343.586,71.74L343.586,68.799C343.586,66.812 355.632,60.399 367.646,60.399C379.659,60.399 391.586,66.812 391.586,68.799L391.586,72.437C401.237,76.85 409.513,83.758 415.586,92.334L415.586,103.599L414.386,103.599C412.399,103.599 410.786,105.212 410.786,107.199L410.786,147.999C410.786,149.986 412.399,151.599 414.386,151.599L415.586,151.599L415.586,103.599L421.586,103.599C423.573,103.599 425.186,105.212 425.186,107.199L425.186,147.999C425.186,149.986 423.573,151.599 421.586,151.599L415.586,151.599L415.586,161.278C406.186,174.553 391.507,183.831 374.617,186.044C373.906,182.817 361.158,182.908 360.517,186.227C343.907,184.49 329.313,175.935 319.586,163.415L319.586,151.599L320.786,151.599C322.773,151.599 324.386,149.986 324.386,147.999L324.386,107.199C324.386,105.212 322.773,103.599 320.786,103.599L319.586,103.599L319.586,151.599L313.586,151.599C311.599,151.599 309.986,149.986 309.986,147.999L309.986,107.199C309.986,105.212 311.599,103.599 313.586,103.599L319.586,103.599L319.586,90.196C325.831,82.158 334.083,75.754 343.586,71.74ZM381.986,96.399L353.186,96.399C353.14,96.416 333.953,103.585 331.262,104.947C330.544,105.31 329.91,106.065 329.91,106.945L329.91,138.387C329.91,139.294 330.579,140.071 331.33,140.413C334.045,141.647 346.201,145.796 346.201,145.796L367.617,141.999L388.971,145.796C388.971,145.796 401.127,141.647 403.842,140.413C404.593,140.071 405.262,139.294 405.262,138.387L405.262,106.945C405.262,106.065 404.628,105.31 403.91,104.947C401.142,103.546 382.031,96.416 381.986,96.399ZM391.586,72.437C386.232,69.99 380.455,68.31 374.396,67.539C373.424,70.365 361.638,70.281 360.718,67.364C354.7,67.973 348.944,69.477 343.586,71.74L343.586,75.999C343.586,77.986 345.199,79.599 347.186,79.599L387.986,79.599C389.973,79.599 391.586,77.986 391.586,75.999L391.586,72.437Z" />
        </g>
    </svg>
    
  )
}

export default DroidLogo;