const DE = {
    LANGUAGE_NAME: "German",
    INFORMATIONAL_VIDEO_URL: "",
    PREVIOUS_REWARDS: "Versendete Prämien",
    BUY_G777: "G777 erwerben",
    VIEW_NFT_COLLECTION: "NFT Sammlung",
    PUBLIC_SALE: "Öffentlicher Verkauf",
    VESTING: "Token Einfordern",
    ABOUT: "Über Uns",
    CONNECT: "VERBINDEN",
    FETCHING_DATA: "Daten werden abgerufen...",
    FAILED_TO_FETCH_DATA: "Fehler beim Abrufen der Daten",
    LAST_ROUND_WINNERS: "Letzter Gewinner",
    VIEW_ALL: "Alle ansehen",
    JACKPOT_WINNER: "Jackpot Gewinner",
    NFT_MINT_WINNER: "NFT Gewinner",
    VIEW_ON_OPENSEA: "Auf OpenSea ansehen",
    PASSIVE_INCOME_RECIPIENT: "Empfänger des passiven Einkommens",
    EARN_GRAVEL_TOKENS: "Verdiene Gravel777",
    PLEASE_SWITCH_TO_NETWORK: "Bitte wechseln Sie zum {0}-Netzwerk",
    PLEASE_CONNECT_YOUR_WALLET: "Bitte verbinden Sie Ihre Wallet",
    SWITCH_NETWORK: "Netzwerk wechseln",
    CONNECT_WALLET: "Wallet verbinden",
    WAITING_FOR_CONFIRMATION: "Warten auf Bestätigung...",
    BALANCE: "Kontostand:",
    NEW_TOTAL_DEPOSIT: "Neue Einzahlung:",
    MONTHLY_INCOME: "Monatliches Einkommen:",
    YEARLY_INCOME: "Jährliches Einkommen:",
    STAKE: "Stake",
    UNSTAKE: "Unstake",
    WITHDRAW: "Abheben",
    DEPOSIT: "Einzahlen",
    POOL_INFO: "Pool Informationen",
    TOTAL_VALUE_LOCKED: "Gesamter gesperrter Wert:",
    TOTAL_TOKENS_LOCKED: "Gesamte gesperrte Tokens:",
    YOUR_SHARE: "Anteile:",
    VIEW_CONTRACT: "Vertrag ansehen",
    LIQUIDITY_FARM: "Liquidität",
    STAKING_POOL: "Staking Pool",
    APR: "APR:",
    EARNED: "Verdient:",
    CLAIM_REWARDS: "Belohnung beanspruchen",
    EARN_G777_LIQUIDITY_FARM: "Verdiene G777, indem du Liquidität bereitstellst",
    EARN_G777_STAKING_POOL: "Verdiene G777, indem du deine bestehenden G777 setzt",
    APPROVE_TOKEN: "Token genehmigen",
    ADD_LIQUIDITY: "Liquidität hinzufügen",
    STAKE_G777: "G777 Staken",
    ROUND: "Runde",
    DATE: "Datum",
    JACKPOT: "Jackpot",
    NFT_MINT: "NFT Schürfung",
    PASSIVE_INCOME: "Passives Einkommen",
    VIEW_TRANSACTION: "Transaktion ansehen",
    INVALID_EMAIL: "Ungültige E-Mail",
    EMAIL_ADDRESS_NOT_VALID: "Die angegebene E-Mail-Adresse ist ungültig. Bitte geben Sie eine gültige E-Mail-Adresse an, um fortzufahren!",
    SUBSCRIBED: "Abonniert",
    THANK_YOU_FOR_SUBSCRIBING: "Vielen Dank, dass Sie unseren Newsletter abonniert haben! Wir halten Sie über die neuesten Entwicklungen im Projekt auf dem Laufenden.",
    FAILED_TO_SUBSCRIBE: "Fehler beim Abonnieren",
    COULD_NOT_SUBSCRIBE: "Das Abonnieren des Newsletters ist fehlgeschlagen. Probieren Sie es erneut, oder wenden Sie sich bitte an den Support!",
    EXPERIENCE_THE_EXCITEMENT: "Erleben Sie den Nervenkitzel von dezentralisierten Gaming mit Gravel 777, wo jeder Transfer ein Gewinner sein könnte.",
    STAY_IN_THE_LOOP: "Immer auf dem Laufenden bleiben",
    JOIN_OUR_MAILING_LIST: "Tragen Sie sich in unseren Email Verteiler ein, um immer über unsere neuesten Funktionen und Ereignisse informiert zu werden.",
    YOUR_EMAIL_ADDRESS: "Ihre E-Mail-Adresse",
    SIGNING_UP: "Anmelden...",
    SIGN_UP: "Anmelden",
    JOIN_THE_COMMUNITY: "Treten Sie der Gemeinschaft bei",
    PRIVACY_POLICY: "Datenschutzrichtlinie",
    TERMS_OF_SERVICE: "Nutzungsbedingungen",
    G777_SLOGAN_TOP: "Senden, Spielen, Gewinnen",
    G777_SLOGAN_BOTTOM: "mit Gravel 777",
    HOME_PAGE_PARAGRAPH: "Benutzen Sie Gravel 777 um mit Token Transfers passives Einkommen zu gewinnen.",
    CURRENT_POOL: "Aktueller Pool",
    POOL_ENDS_IN: "Pool endet in",
    WAITING_FOR_POOL_REVEAL: "Warten auf Pool Ausschüttung...",
    HOURS: "Stunden",
    MINUTES: "Minuten",
    SECONDS: "Sekunden",
    HOW_GRAVEL_777_WORKS: "WIE GRAVEL 777 FUNKTIONIERT",
    CLAIM_YOUR_VESTED_TOKENS: "Fordern Sie Ihre Vested Tokens an",
    CLAIM_YOUR_VESTED_TOKENS_PARAGRAPH: "Fordern Sie Ihre Vested Tokens aus dem Öffentlichen Verkauf, Airdrop oder Seed-Investition an und beginnen Sie, am Spiel teilzunehmen. Entdecken Sie, wie Sie durch regelmäßige Token Transfers Belohnungen verdienen können.",
    G777_VESTED: "G777 Vested",
    G777_REWARDED: "G777 Prämie",
    INVESTMENT_CLAIMED: "Investment eingefordert",
    TOTAL_G777: "Gesamte G777 Token",
    TOKENS_CLAIMED: "Einngeforderte Tokens",
    PERCENTAGE_CLAIMED: "Prozent eingefordert",
    VESTED_G777: "Vested G777 Token",
    REWARDED_G777: "G777 Prämie",
    AIRDROP: "Airdrop",
    INVESTOR: "Investor",
    NOTHING_TO_CLAIM: "Keine Token zum Einfordern",
    CLAIMABLE: "Anspruchsberechtigt",
    OF_YOUR_TOTAL: "% von Ihrem Gesamtbetrag",
    NO_VESTING_SOURCES: "Das ausgewählte Konto hat keine Vesting Quellen",
    ACCOUNT_NOT_ELIGIBLE: "[KONTO NICHT BERECHTIGT]",
    CLAIMED_SO_FAR: "Bisher eingefordert",
    CLAIMING_TOKENS: "Fordern Sie Token ein...",
    CLAIM_GRAVEL_777: "Fordern Sie Gravel 777 ein",
    ENTER_GRAVEL_777_PUBLIC_SALE: "Partizipieren Sie im öffentlichen Gravel 777 Verkauf",
    GRAVEL_777_PUBLIC_SALE_PARAGRAPH: "Der öffentliche Verkauf von Gravel 777 ist eine herausragende Gelegenheit für Erstinvestitionen, da diese zum Erfolg des Projekts beitragen und deshalb im Laufe der Zeit höhere Renditen erzielen können.",
    DAYS: "Tage",
    HRS: "Std.",
    MINS: "Min.",
    YOUR_POSITION: "Ihre Position",
    VESTED_PRICE: "Vested Preis",
    PRICE_DISCLAIMER: "* Tokenpreis nach Ende der Vesting-Periode des öffentlichen Verkaufs, unter der Annahme eines 25% Verkaufs und eines Netto-Nullkaufs an der DEX. Dieser Wert ist nur eine Schätzung!",
    SALE_NOT_OPEN_YET: "Der Verkauf ist noch nicht geöffnet. Bitte kommen Sie in {0} Tagen, {1} Stunden und {2} Minuten wieder!",
    THE_SALE_HAS_FINISHED: "Der Verkauf ist beendet. Bitte gehen Sie zur Seite Vesting, um Ihre Tokens zu beanspruchen!",
    GO_TO_VESTING_PAGE: "Zur Vesting Seite gehen",
    CREATING_POSITION: "Erstelle Position...",
    CREATE_POSITION: "Position erstellen",
    FREQUENTLY_ASKED_QUESTIONS: "Häufig gestellte Fragen",
    FAQ_PARAGRAPH: "Erhalten Sie Antworten auf häufig gestellte Fragen zu Gravel 777 und wie Sie auf der Plattform Token gewinnen können.",
    ABOUT_GRAVEL_777: "Über Gravel 777",
    ABOUT_GRAVEL_777_PARAGRAPH: "Gravel 777 ist eine dezentrale Gaming Plattform, die auf dem Polygon-Netzwerk aufgebaut ist. Die Plattform belohnt Nutzer für ihre Aktivitäten mit Token und ermöglicht ihnen, einen Teil des Pools als Jackpot oder passives Einkommen durch die NFTs der Plattform zu verdienen.",
    OUR_ROADMAP: "Unser Roadmap",
    OUR_MISSION: "Unsere Mission",
    THE_GRAVEL_777_MISSION_LINE_SHORT: "Unterstützung von Nutzern durch dezentrales Gaming auf dem Polygon-Netzwerk",
    THE_PLATFORMS_AIM: "Das Ziel der Plattform",
    THE_GRAVEL_777_MISSION_LINE_1: "Die Mission von Gravel 777 ist es, eine dezentralisierte Gaming-Plattform bereitzustellen, die es Nutzern ermöglicht, regelmäßig Token Transfers durchzuführen und Belohnungen durch einen faire und transparente Gewinnausschüttung zu erhalten. Durch die Nutzung von Blockchain Technologie und Smart Contracts möchte Gravel 777 ein dynamisches und ansprechendes Gaming Erlebnis schaffen, das es Nutzern ermöglicht, Tokens und NFTs durch ihre tägliche Aktivität zu verdienen.",
    THE_GRAVEL_777_MISSION_LINE_2: "Unser Ziel ist es, eine Plattform zu schaffen, die für alle Nutzer zugänglich ist, unabhängig von ihrem Wissensstand mit Blockchain Technologie. Wir streben danach, ein benutzerfreundliches Erlebnis zu bieten, das es Nutzern ermöglicht, einfach am Spiel teilzunehmen und Belohnungen zu erhalten, während gleichzeitig die Nutzung von Blockchain-Technologie gefördert und zum Wachstum des dezentralen Ökosystems beigetragen wird. Wir glauben, dass wir durch die Kombination von Blockchain-Technologie und dem Nervenkitzel des Gamings eine Plattform schaffen können, die wirklich einzigartig und ansprechend für unsere Nutzer ist.",
    CONNECT_YOUR_WALLET: "Wallet verbinden",
    SELECT_WALLET_TYPE: "Bitte wählen Sie unten die bevorzugte Wallet aus! Wenn MetaMask nicht verfügbar ist, können Sie WalletConnect über Ihre Mobile Wallet App verwenden.",
    HOW_TO_USE_WALLET_CONNECT: "Wie man WalletConnect verwendet",
    YES: "Ja",
    NO: "Nein",
    OK: "OK",
    CLOSE: "Schließen",
    APPLY: "Anwenden",
    CANCEL: "Abbrechen",
    TX_SENT_TITLE: "Transaktion gesendet",
    TX_SENT_MESSAGE: "Ihre Transaktion wurde gesendet. Bitte geben Sie den Minern etwas Zeit, um Ihre Transaktion zu akzeptieren. Sobald die Transaktion validiert wurde, können Sie die Token auf OpenSea ansehen.",
    TX_SETTINGS: "Transaktionseinstellungen",
    SLIPPAGE_LABEL: "Swap-Slippage:",
    DEADLINE_LABEL: "Transaktionsfrist (Minuten):",
    INVESTMENT_LOCKED: "Investment gesperrt",
    INVESTMENT_LOCKED_REASON_1: "Ihr Investment wurde gesperrt, weil Sie dem Projektteam nicht die notwendigen KYC-Informationen zur Verfügung gestellt haben. Bitte kontaktieren Sie das Team so bald wie möglich, um Ihr Investment zu entsperren und Ihre Tokens zu beanspruchen!",
    INVESTMENT_LOCKED_REASON_2: "Ihr Investment wurde gesperrt, weil Sie den Vertragsabschluss mit dem Projektteam nicht unterzeichnet haben. Bitte kontaktieren Sie das Team so bald wie möglich, um Ihr Investment zu entsperren und Ihre Tokens zu beanspruchen!",
    INVESTMENT_LOCKED_REASON_UNKNOWN: "Ihr Investment wurde aus einem unbekannten Grund gesperrt. Bitte kontaktieren Sie das Team so bald wie möglich!",
    
    __FAQ_0_Q: "Was ist die Plattform Gravel 777?",
    __FAQ_0_A: "Die Plattform Gravel 777 ist eine Gaming-Erfahrung, die um einen ERC20-Token namens Gravel 777 (G777) gebaut ist. Das Ziel der Plattform ist es, Nutzern eine nahtlose Gaming-Erfahrung zu bieten, während sie den Token für täglichen Transaktionen verwenden.",
    __FAQ_1_Q: "Wie funktioniert die Plattform Gravel 777?",
    __FAQ_1_A: "Wenn Nutzer den Token der Plattform Gravel 777 für Aktionen wie Transfers, Swapping oder Staking verwenden, nimmt der Sender automatisch an einer Verlosung teil. Am Ende jeder Runde wird eine Transaktion als Gewinner ausgewählt und erhält den größten Teil des Poolwerts. Zusätzlich sind Transaktionen, die während einer Spielrunde getätigt werden, auch berechtigt, neu geprägte Gravel 777 NFTs zu gewinnen, die dem Inhaber eine Quelle passiven Einkommens bieten.",
    __FAQ_2_Q: "Wie können Nutzer an der Plattform Gravel 777 teilnehmen?",
    __FAQ_2_A: "Um an der Plattform Gravel 777 teilzunehmen, müssen Nutzer einen ERC20 Token Transfer mit dem G777 Token durchführen. Damit werden sie automatisch in die aktuelle Spielrunde eingeschrieben, und 5% des übertragenen Werts werden der Rundenpool hinzugefügt.",
    __FAQ_3_Q: "Welche Gewinnchancen gibt es auf der Plattform Gravel 777?",
    __FAQ_3_A: "Die Gewinnchancen einer Transaktion auf der Plattform Gravel 777 sind proportional zum Betrag der Transaktion. Das bedeutet, dass die Gewinnchancen proportional zum Steuerbetrag sind, der auf der Transaktion gezahlt wurde, und zu dem Beitrag, den sie zum Pool der Runde geleistet hat.",
    __FAQ_4_Q: "Was passiert am Ende einer Spielrunde?",
    __FAQ_4_A: "Am Ende einer Spielrunde wird der Rundenpool geschlossen und automatisch ein neuer erstellt. Der geschlossene Rundenpool wird dann aufgedeckt, um einen Gewinner auszuwählen und Belohnungen zu verteilen. Dieser Prozess beinhaltet Interaktionen mit verschiedenen Plattformmodulen sowie Off-Chain-Diensten.",
    __FAQ_5_Q: "Wie werden Gewinner auf der Plattform Gravel 777 ausgewählt?",
    __FAQ_5_A: "Gewinner werden auf der Plattform Gravel 777 mithilfe eines sicheren und verifizierbaren Zufallsseeds von Chainlink VRF ausgewählt. Dieser Seed wird verwendet, um sowohl den Gewinner des Rundenjackpots als auch den Gewinner der neu geprägten Gravel 777 NFT auszuwählen.",
    __FAQ_6_Q: "Wie generiert die Plattform Gravel 777 sichere Zufallszahlen?",
    __FAQ_6_A: "Die Plattform Gravel 777 verwendet einen Oracle, speziell den Chainlink VRF, um sichere Zufallszahlen zu generieren. Die Plattform stellt eine Anfrage für Zufallswörter auf dem VRF Coordinator-Vertrag, die dann von Knoten auf der Chainlink-Plattform bereitgestellt werden. Die Zufallszahlen werden dann on-chain verifiziert, um Manipulationen zu verhindern.",
    __FAQ_7_Q: "Gibt es Kosten im Zusammenhang mit der Verwendung des Oracle auf der Plattform Gravel 777?",
    __FAQ_7_A: "Ja, es gibt Kosten im Zusammenhang mit der Verwendung des Oracle auf der Plattform Gravel 777. Die Plattform verwendet LINK-Token, um für Anfragen beim Oracle zu bezahlen. Die Kosten für diese Anfragen sind wenige Euro Cents die pro Tag für LINK Tokens ausgegeben werden. Diese Kosten werden vom Treasury der Plattform gedeckt.",
    __FAQ_8_Q: "Wie läuft der Auswahlprozess für einen Gewinner auf der Plattform Gravel 777 ab?",
    __FAQ_8_A: "Die Plattform Gravel 777 verwendet einen einfachen und fairen Prozess zur Auswahl von Gewinnern. Jede auf der Plattform getätigte Transaktion wird im Round-Pool Vertrag zusammen mit dem übertragenen Betrag protokolliert. Die Transaktionen werden in chronologischer Reihenfolge dem Round-Pool Vertrag als Slots hinzugefügt, wobei die Größe des Slots von dem übertragenen Betrag abhängt. Sobald die Runde endet, wird im Bereich von 0 bis zum Gesamtbetrag, der während der Runde übertragen wurde, eine Zufallszahl generiert. Der Gewinner wird dann ausgewählt, indem der Transaktionsslot gefunden wird, unter dem sich die Zufallszahl befindet.",
    __FAQ_9_Q: "Warum gilt diese Methode zur Auswahl von Gewinnern als fair?",
    __FAQ_9_A: "Diese Methode gilt als fair, da sie die Gesamtmenge der von einem Spieler während der Runde übertragenen Token berücksichtigt. Die Gewinnwahrscheinlichkeit ist direkt proportional zur Menge der von dem Spieler übertragenen Token. Die Zeit, zu der die Token übertragen wurden, ist kein Faktor, da die Quelle der Zufallszahl uniform ist und nicht basierend auf der Position des Transferslots in der Runde diskriminiert.",
    __FAQ_10_Q: "Wer sind die Empfänger des Rundenpools in Gravel 777?",
    __FAQ_10_A: "Die Empfänger des Rundenpools in Gravel 777 sind der Jackpot-Gewinner (50%), Gewinner des passiven Einkommens (bis zu 10%), Staking-Pool (7%), Liquiditätsfarm (10%), öffentlicher Verkauf (15%), vesting (Seed-Investoren, 5%) und Entwicklungsteam (3%).",
    __FAQ_11_Q: "Kann die initiale Verteilung des Rundenpools in Gravel 777 aktualisiert werden?",
    __FAQ_11_A: "Ja, die initiale Verteilung des Rundenpools in Gravel 777 kann durch einen Vorschlag und eine Abstimmung durch die Gemeinschaft über Governance aktualisiert werden. Wenn das Abstimmungsergebnis positiv ausfällt, wird die neue Verteilung sofort in Kraft gesetzt.",
    __FAQ_12_Q: "Wie wird der passive Einkommensanteil in Gravel 777 berechnet?",
    __FAQ_12_A: "Der passive Einkommensanteil in Gravel 777 wird als 10% der Gesamtmenge multipliziert mit der Gesamtmenge berechnet. Dies stellt sicher, dass jedes NFT den gleichen jährlichen Durchschnittsertrag hat, unabhängig davon, wann es geschürft wurde.",
    __FAQ_13_Q: "Was ist der öffentliche Verkauf in Gravel 777?",
    __FAQ_13_A: "Der öffentliche Verkauf in Gravel 777 ist eine Möglichkeit für frühe Investoren, zum initialen Erfolg des Projekts beizutragen und im Laufe der Zeit höhere Renditen zu erzielen.",
    __FAQ_14_Q: "Wie ist der öffentliche Verkauf in Gravel 777 strukturiert?",
    __FAQ_14_A: "Der öffentliche Verkauf in Gravel 777 ist in zwei Phasen unterteilt: Die Investmentphase und die Rückzahlungsphase. Während der Investmentphase können Investoren USDC in den öffentlichen Verkaufsvertrag einzahlen. Diese Phase dauert vier Tage. Während der Rückzahlungsphase können öffentliche Verkaufsinvestoren ihre USDC-Einlagen als G777-Token beanspruchen, die linear über einen siebenwöchigen Zeitraum freigegeben werden.",
    __FAQ_15_Q: "Was ist der Zweck der zweiten Phase des öffentlichen Verkaufs in Gravel 777?",
    __FAQ_15_A: "Die zweite Phase des öffentlichen Verkaufs in Gravel 777 ist dafür konzipiert, einen konstanten Kaufdruck auf dem DEX zu erzeugen, wodurch der Tokenpreis während des siebenwöchigen Zeitraums allmählich steigt.",
    __FAQ_16_Q: "Welche Anreize werden Investoren zur Teilnahme am öffentlichen Verkauf in Gravel 777 geboten?",
    __FAQ_16_A: "Um Investoren zur Teilnahme am öffentlichen Verkauf zu motivieren, werden öffentliche Verkaufsinvestoren für das erste Jahr mit 15% jedes Rundenpools belohnt.",
    __FAQ_17_Q: "Was ist ein Staking-Pool?",
    __FAQ_17_A: "Ein Staking-Pool ist eine Möglichkeit, Token-Halter dazu zu motivieren, ihre Tokens zu halten anstatt sie zu verkaufen. Dies hilft, die Volatilität des Tokenpreises zu verhindern.",
    __FAQ_18_Q: "Wie funktioniert der G777-Staking-Pool?",
    __FAQ_18_A: "G777-Token-Halter können ihre Tokens im Staking-Pool staken und erhalten im Gegenzug zusätzliche G777-Belohnungen dafür. Dies hilft dabei, die Tokens nicht zu verkaufen, was dazu beitragen kann, die Volatilität des Tokenpreises zu reduzieren.",
    __FAQ_19_Q: "Wie werden die Belohnungen für den Staking-Pool generiert?",
    __FAQ_19_A: "Die Belohnungen für den Staking-Pool werden aus der Transaktionssteuer generiert. Am Ende jeder Runde werden 7% des Rundenwerts an den Staking-Pool gesendet und an Staker verteilt.",
    __FAQ_20_Q: "Trägt das Staking im G777-Staking-Pool zur Token-Inflation bei?",
    __FAQ_20_A: "Nein, da der Staking-Pool nur Token belohnt, die bereits im Umlauf sind, trägt er nicht zur Token-Inflation bei.",
    __FAQ_21_Q: "Was ist eine Liquiditätsfarm?",
    __FAQ_21_A: "Eine Liquiditätsfarm ist eine Möglichkeit, die Gemeinschaft dazu zu ermutigen, für einen Token auf einem dezentralen Austausch Liquidität zur Verfügung zu stellen. Dies kann dazu beitragen, die Volatilität des Tokenpreises zu reduzieren.",
    __FAQ_22_Q: "Wie funktioniert die G777-Liquiditätsfarm?",
    __FAQ_22_A: "Benutzer können ihre Liquiditätspool- (LP) Tokens im Vertrag der Farm sperren und im Gegenzug G777-Token erhalten. Dies bietet Anreize für Benutzer, Liquidität dem Pool hinzuzufügen und belohnt auch das Sperren von LP-Tokens in der Farm.",
    __FAQ_23_Q: "Wie werden die Belohnungen für die G777-Liquiditätsfarm generiert?",
    __FAQ_23_A: "Die Belohnungen für die Liquiditätsfarm werden aus der Transaktionssteuer generiert. Am Ende jeder Runde werden 10% des Rundenwerts an die Liquiditätsfarm gesendet und an die Benutzer verteilt.",
    __FAQ_24_Q: "Was ist das Treasury und wie funktioniert es?",
    __FAQ_24_A: "Das Treasury ist ein Vertrag, der für die Verteilung von Mitteln für die Plattformentwicklung, Marketing und Anreizmaßnahmen verantwortlich ist. Sobald ein neues Projekt von der Entwicklungs- oder Marketingabteilung initiiert wird, wird ein Vorschlag auf dem Governance-Vertrag veröffentlicht. Wenn der Vorschlag von der Gemeinschaft akzeptiert wird, erhält das Team die Mittel aus dem Reservierungsvertrag, um das Projekt umzusetzen.",
    __FAQ_25_Q: "Wie wird das Treasury finanziert?",
    __FAQ_25_A: "Das Treasury wird ursprünglich aus dem Überschuss von 10% finanziert, der an NFT-Halter gesendet wird. Nach dem ersten Jahr werden die für öffentliche Verkaufsinvestoren vorgesehenen Mittel in das Treasury umgeleitet.",
    __FAQ_26_Q: "Was ist Vesting und wie funktioniert es?",
    __FAQ_26_A: "Vesting ist ein Prozess, der die lineare Freigabe von schuldigen Tokens an Seed-Round Investoren, Gründern und anderen Partnern über einen bestimmten Verzinsungszeitraum ermöglicht. Die vestierten Investoren können ihre vestierten Token jederzeit beanspruchen, aber sie können ihre Token nicht sofort nach dem Launch verkaufen, was dazu beitragen kann, einen plötzlichen Rückgang des Tokenpreises zu verhindern. Der Verzinsungszeitraum für Investoren beträgt ein Jahr.",
    __FAQ_27_Q: "Was ist der Governance-Vertrag und wie funktioniert er?",
    __FAQ_27_A: "Der Governance-Vertrag ermöglicht der Gemeinschaft, an der Entscheidungsfindung für die Konfiguration der Plattform und die Verteilung von Treasury-Mitteln teilzunehmen. Vorschläge werden in der Regel vom Team gemacht und, wenn sie von der Gemeinschaft akzeptiert werden, durch eine Abstimmung mit G777-Tokens umgesetzt. Das Gewicht jeder Stimme basiert auf der Anzahl der vom Wähler gehaltenen Tokens.",

    __RM_1_T: "Definieren der Grundlage der Plattform",
    __RM_1_D: "August 2022",
    __RM_1_L_1: "Das erste Meilenstein des Gravel 777-Projekts besteht darin, die Architektur, Tokenomics, Webseite und das Design der NFT-Kunstwerke zu definieren. Dies beinhaltet die Darstellung der technischen Details der Plattform, wie die G777 ERC20-Token und der Round Pool implementiert werden und wie die Steuer auf den Übertragungen gesammelt und an den Jackpot-Gewinner und NFT-Besitzer verteilt wird.",
    __RM_1_L_2: "Das Team konzentriert sich auch darauf, ein umfassendes Whitepaper zu erstellen, das die Vision und Ziele des Projekts sowie die Mechanismen der Plattform und die von ihr angebotenen Nutzen für Benutzer beschreibt. Sie arbeiten zudem an der Gestaltung von NFT-Kunstwerken und dem Gesamterscheinungsbild der Webseite, um eine visuell ansprechende und benutzerfreundliche Erfahrung für die Spieler sicherzustellen. Insgesamt ist dieser erste Meilenstein entscheidend für den erfolgreichen Start und Betrieb der Gravel 777-Plattform.",
    __RM_2_T: "Entwicklung und Testen",
    __RM_2_D: "November 2022",
    __RM_2_L_1: "Der zweite Meilenstein des Projekts beinhaltet das Schreiben von Smart Contracts, die Durchführung von Sicherheitsprüfungen und das Testen der Plattform. Dazu gehört die Entwicklung von Smart Contracts, die den Transfer von Tokens, die Sammlung und Verteilung des Round Pools und die Ausgabe und Übertragung von NFTs regeln. Das Team wird auch mit Sicherheitsexperten zusammenarbeiten, um sicherzustellen, dass die Verträge sicher und frei von Schwachstellen sind.",
    __RM_2_L_2: "Neben dem Schreiben von Smart Contracts und der Durchführung von Sicherheitsprüfungen wird sich das Team auch auf das Testen der Plattform konzentrieren, um sicherzustellen, dass sie wie vorgesehen funktioniert und alle Funktionen ordnungsgemäß funktionieren. Dies beinhaltet das Erstellen von Testfällen und das Ausführen von Simulationen, um verschiedene Szenarien zu simulieren und sicherzustellen, dass die Plattform die erwartete Transaktionsmenge verarbeiten kann.",
    __RM_2_L_3: "Schließlich wird das Team an der Entwicklung der Webseite und des Reveal-Dienstes arbeiten, der für das Abschließen von Runden, das Berichten von Rundengewinnern und andere Daten verantwortlich ist. Der Reveal-Dienst wird Aufrufe in der Blockchain tätigen, um jede Runde abzuschließen und die Ergebnisse der Webseite zu melden, die sie für Benutzer anzeigt. Insgesamt ist dieser zweite Meilenstein entscheidend für den Start der Gravel 777-Plattform und dafür, dass sie zuverlässig und sicher ist.",
    __RM_3_T: "Bereitstellen in einer Testumgebung",
    __RM_3_D: "Dezember 2022",
    __RM_3_L_1: "Dar dritte Meilenstein beinhaltet das Bereitstellen der Plattform in einer Testumgebung und das Ermöglichen von Tests durch das Team und eine ausgewählte Gruppe von Personen aus der Community. Dies beinhaltet das Einrichten der Testumgebung, das Bereitstellen von Smart Contracts und anderen Komponenten der Plattform und das Bereitstellen von einem Zugang für das Team und ausgewählte Community-Mitglieder.",
    __RM_3_L_2: "Das Ziel dieses Meilensteins ist es, sicherzustellen, dass die Plattform wie vorgesehen funktioniert und eventuelle Probleme oder Fehler zu identifizieren, die vor dem vollständigen Start behoben werden müssen. Das Team wird eng mit Testern zusammenarbeiten, um Feedback zu sammeln und eventuelle Probleme zu lösen, die während des Testprozesses auftreten.",
    __RM_3_L_3: "Sobald die Tests abgeschlossen sind und alle erforderlichen Modifikationen vorgenommen wurden, wird das Team die Plattform mit der exakt gleichen Konfiguration wie in der Testumgebung im Hauptnetzwerk bereitstellen. Dies beinhaltet das Einrichten der Mainnet-Bereitstellung, das Bereitstellen von Smart Contracts und anderen Komponenten der Plattform und das Bereitstellen für die Öffentlichkeit. Insgesamt ist dieser dritte Meilenstein entscheidend für die Vorbereitung der Gravel 777-Plattform auf den vollständigen Start und dafür, dass sie für die breitere Community einsatzbereit ist.",
    __RM_4_T: "Community-Engagement und Anreize",
    __RM_4_D: "",
    __RM_4_L_1: "Dar vierte Meilenstein der Gravel 777 Roadmap konzentriert sich auf das Community-Engagement und Anreize die Platform aktiv zu nutzen. Das Ziel dieser Phase ist es, durch gezielte Bemühungen, die Community zu engagieren und anzuregen, die Bekanntheit und den Einsatz der Plattform zu erhöhen.",
    __RM_4_L_2: "Um dieses Ziel zu erreichen, wird das Team eine Reihe von Airdrops durchführen, um Community-Mitglieder zu belohnen, die die Plattform über soziale Medien, Empfehlungen und andere Kanäle bewerben. Zusätzlich zu den Airdrops wird das Team auch mit Influencern und Content-Erstellern zusammenarbeiten, um die Reichweite der Kampagne zu vergrößern. Um weiterhin mit der Community in Kontakt zu bleiben, wird das Team auch soziale Medien, Foren und andere Online-Kanäle nutzen, um Fragen zu beantworten, Unterstützung zu leisten und Feedback zu sammeln. Um die Community über die Plattform und ihre Funktionen zu informieren, kann das Team auch online Events wie AMA-Sessions oder Webinare veranstalten. Durch die Nutzung von Community-Feedback wird das Team daran arbeiten, die Benutzerfreundlichkeit zu verbessern und eventuelle Probleme oder Bedenken zu lösen. Am Ende dieses Meilensteins strebt das Team danach, erfolgreich eine Reihe von Airdrops durchgeführt zu haben, die Bekanntheit und den Einsatz der Plattform fördern, sowie das Engagement mit der Community durch Online-Kanäle und Events zu erhöhen.",
    __RM_5_T: "Live gehen",
    __RM_5_D: "",
    __RM_5_L_1: "Dar fünfte Meilenstein der Roadmap beinhaltet die Veröffentlichung mit allen Funktionen der Plattform. Diese Phase markiert den offiziellen Start der Plattform und beinhaltet die Bereitstellung des Projekts, das Hinzufügen von Startkapital und die Eröffnung des öffentlichen Verkaufs.",
    __RM_5_L_2: "Sobald das Projekt bereitgestellt und die initiale Liquidität hinzugefügt wurde, wird das Team den öffentlichen Verkauf eröffnen, um den Benutzern den Kauf von G777-Token zu ermöglichen. Der erste Pool wird auch zu diesem Zeitpunkt gefüllt und der Jackpot-Gewinner und NFT-Besitzer wird bestimmt. Dies markiert den Start der ersten offiziellen Runde auf der Gravel 777 Plattform. Die Community kann mit dem Transfer von Token und der Teilnahme an der Plattform beginnen.",
    __RM_5_L_3: "Um einen reibungslosen und erfolgreichen Start zu gewährleisten, wird das Team eng mit Börsen und anderen Partnern zusammenarbeiten, um sicherzustellen, dass die Plattform ordnungsgemäß integriert ist und die Benutzer Zugang zu den erforderlichen Ressourcen und einer hochwertigen Unterstützung haben. Am Ende dieses Meilensteins wird die Gravel 777 Plattform online und vollständig betriebsbereit sein.",
}

export default DE;