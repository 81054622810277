import React, { useState } from 'react'
import { useWeb3PendingTransactions, useWeb3State, useWeb3Status } from '~/Context/Web3Context';
import { usePopupManager } from '~/Context/PopupManagerContext';
import Web3Interface from '~/Utilities/Web3Interface';
import ConnectWalletPopup from '~/Popups/ConnectWalletPopup';
import StakingControl from '~/Components/StakingControl';
import Language from '~/Language';
import Helpers from '~/helpers';
import Config from '~/config';
import Style from './StakingSection.module.css'

function StakingSection() {
    const state = useWeb3State();
    const pendingTransactions = useWeb3PendingTransactions()
    const { showPopup } = usePopupManager();
    const { isConnected, networkId } = useWeb3Status();

    const [ stakingPoolWaiting, setStakingPoolWaiting ] = useState(false);
    const [ liquidityFarmWaiting, setLiquidityFarmWaiting ] = useState(false);

    const handleConnect = () => {
        showPopup(ConnectWalletPopup, {})
    }

    const handleSwitchNetwork = () => {
        Web3Interface.requestNetworkSwitch(Config.Blockchain.Network.Configuration);
    }

    const handleStakingPoolStake = async (amount) => {
        setStakingPoolWaiting(true)
        try {
            await Web3Interface.stakingPool.stake(Helpers.ethToWei(amount))
        }
        finally {
            setStakingPoolWaiting(false)
        }
    }

    const handleStakingPoolUnstake = async (amount) => {
        setStakingPoolWaiting(true)
        try {
            await Web3Interface.stakingPool.unstake(amount)
        }
        finally {
            setStakingPoolWaiting(false)
        }
    }

    const handleStakingPoolClaim = async () => {
        setStakingPoolWaiting(true)
        try {
            await Web3Interface.stakingPool.harvest()
        }
        finally {
            setStakingPoolWaiting(false)
        }
    }

    const handleStakingPoolApprove = async () => {
        setStakingPoolWaiting(true)
        try {
            await Web3Interface.swapRouter.approveStakingPool()
        }
        finally {
            setStakingPoolWaiting(false)
        }
    }

    const handleLiquidityFarmStake = async (amount) => {
        setLiquidityFarmWaiting(true)
        try {
            await Web3Interface.liquidityFarm.stake(Helpers.ethToWei(amount))
        }
        finally {
            setLiquidityFarmWaiting(false)
        }
    }

    const handleLiquidityFarmUnstake = async (amount) => {
        setLiquidityFarmWaiting(true)
        try {
            await Web3Interface.liquidityFarm.unstake(amount)
        }
        finally {
            setLiquidityFarmWaiting(false)
        }
    }

    const handleLiquidityFarmClaim = async () => {
        setLiquidityFarmWaiting(true)
        try {
            await Web3Interface.liquidityFarm.harvest()
        }
        finally {
            setLiquidityFarmWaiting(false)
        }
    }

    const handleLiquidityFarmApprove = async () => {
        setLiquidityFarmWaiting(true)
        try {
            await Web3Interface.swapRouter.approveLiquidityFarm()
        }
        finally {
            setLiquidityFarmWaiting(false)
        }
    }

    return (
        <div className={Style.container}>
            <p className={Style.title}>
                { Language.EARN_GRAVEL_TOKENS }
            </p>

            <div className={Style.contents}>
                <StakingControl 
                    isConnected={isConnected}
                    isInNetwork={ networkId === Config.Blockchain.Network.ID }
                    isLoading={ !state }
                    isWaitingTransaction={ stakingPoolWaiting }
                    isApproved={ state?.tokenApproved }
                    contractAddress={ Config.Blockchain.Contracts.STAKING_POOL }
                    pendingTransactions={ (pendingTransactions || []).filter(item => item.tag.startsWith("STAKING_POOL")) }
                    deposit={ state?.stakingPool?.stakedAmount || "0" }
                    unclaimed={ state?.stakingPool?.pendingRewards || "0" }
                    tvl={ state?.stakingPool?.tvl || "0" }
                    balance={ state?.tokenBalance || "0" }
                    blockReward={ state?.stakingPool?.blockReward || "0" }
                    tokenPrice={ Helpers.parseStablecoinValue(state?.tokenPrice || "0") }
                    assetPrice={ Helpers.parseStablecoinValue(state?.tokenPrice || "0") }
                    depositAssetPrice={ Helpers.parseStablecoinValue(state?.tokenPrice || "0") } 
                    onStakeClicked={handleStakingPoolStake}
                    onUnstakeClicked={handleStakingPoolUnstake}
                    onClaimClicked={handleStakingPoolClaim}
                    onApproveClicked={handleStakingPoolApprove}
                    onConnectWallet={handleConnect}
                    onSwitchNetwork={handleSwitchNetwork} />

                <div style={{width: 35, height: 20}}/>
                
                <StakingControl 
                    isFarm
                    isConnected={isConnected}
                    isInNetwork={ networkId === Config.Blockchain.Network.ID }
                    isLoading={ !state }
                    isWaitingTransaction={ liquidityFarmWaiting }
                    isApproved={ true }
                    contractAddress={ Config.Blockchain.Contracts.LIQUIDITY_FARM }
                    pendingTransactions={ (pendingTransactions || []).filter(item => item.tag.startsWith("LIQUIDITY_FARM")) }
                    deposit={ state?.liquidityFarm?.stakedAmount || "0" }
                    unclaimed={ state?.liquidityFarm?.pendingRewards || "0" }
                    tvl={ state?.liquidityFarm?.tvl || "0" }
                    balance={ state?.balance || "0" }
                    blockReward={ state?.liquidityFarm?.blockReward || "0" }
                    tokenPrice={ Helpers.parseStablecoinValue(state?.tokenPrice || "0") }
                    assetPrice={ Helpers.parseStablecoinValue(state?.lpValue || "0") }
                    depositAssetPrice={ Helpers.parseStablecoinValue(state?.coinPrice || "0") } 
                    onStakeClicked={handleLiquidityFarmStake}
                    onUnstakeClicked={handleLiquidityFarmUnstake}
                    onClaimClicked={handleLiquidityFarmClaim}
                    onApproveClicked={handleLiquidityFarmApprove}
                    onConnectWallet={handleConnect}
                    onSwitchNetwork={handleSwitchNetwork} />
            </div>
        </div>
    )
}

export default StakingSection