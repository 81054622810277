import React, { useMemo, useRef, useState } from 'react'
import { useRoundData } from '~/API/RoundData';
import LastRoundStatsItem from '../LastRoundStatsItem'
import ArrowLeft from '~/Assets/Vectors/ArrowLeft';
import ArrowRight from '~/Assets/Vectors/ArrowRight';
import Loader from '~/Assets/Vectors/Loader';
import Images from '~/Assets/Images'
import Language from '~/Language';
import Helpers from '~/helpers';
import Config from '~/config';
import Style from './LastRoundStats.module.css'

function LastRoundStats({
    onViewAllClicked
}) {
    const containerRef = useRef();
    const jackpotItemRef = useRef();
    const mintItemRef = useRef();
    const passiveIncomeItemRef = useRef();
    
    const { data, isLoading, isError } = useRoundData();
    const [ focusedItem, setFocusedItem ] = useState(0)
    
    const lastRoundData = useMemo(
        () => {
            if(!isLoading || isError)
                return data?.previousRounds[0] || null
            else
                return null;
        },
        [data, isLoading, isError]
    )

    const scrollToItem = (item) => {
        const itemRefs = [ 
            jackpotItemRef, 
            mintItemRef, 
            passiveIncomeItemRef 
        ]

        if(itemRefs[item].current) {
            itemRefs[item].current.scrollIntoView({
                behavior: "smooth", 
                block: "nearest", 
                inline: "center"
            })
        }
    }

    const handleOnScrollRight = () => {
        if(focusedItem < 2) {
            scrollToItem(focusedItem + 1)
            setFocusedItem(focusedItem + 1)       
        }
    }

    const handleOnScrollLeft = () => {
        if(focusedItem > 0) {
            scrollToItem(focusedItem - 1)
            setFocusedItem(focusedItem - 1)            
        }
    }

    return (
        <div className={Style.container}>
            {
                (isLoading || isError) &&
                <div className={Style.loadingContainer}>
                    {
                        isLoading &&
                        <Loader className={Style.loader}/>
                    }
                    <p className={Style.loadingText}>
                    {
                        isLoading ?
                        Language.FETCHING_DATA :
                        Language.FAILED_TO_FETCH_DATA
                    }
                    </p>
                </div>
            }

            <div className={Style.header}>
                <p className={Style.headerTitle}>
                    { Language.LAST_ROUND_WINNERS }
                </p>
                
                <div className={Style.headerControls}>
                    <p 
                        className={Style.viewAllLabel}
                        onClick={onViewAllClicked}>
                        { Language.VIEW_ALL }
                    </p>

                    <div className={Style.arrowContainer}>
                        <ArrowLeft
                            className={Style.arrowIcon}
                            onClick={handleOnScrollLeft}/>

                        <ArrowRight
                            className={Style.arrowIcon}
                            onClick={handleOnScrollRight}/>
                    </div>
                </div>

            </div>

            <div 
                className={
                    Helpers.conditionalClass(
                        isLoading || isError,
                        Style.itemContainer,
                        Style.itemContainerLoading
                    )
                }
                ref={containerRef}>
                <LastRoundStatsItem 
                    containerRef={jackpotItemRef}
                    isJackpot
                    label={ Language.JACKPOT_WINNER }
                    value={`${Helpers.formatValue(lastRoundData?.jackpotAmount)} G777 (${Helpers.formatUsdValue(lastRoundData?.jackpotAmount * lastRoundData?.tokenPrice)})`}
                    url={Config.Blockchain.Network.TX_EXPLORER + lastRoundData?.txId}/>

                <LastRoundStatsItem 
                    containerRef={mintItemRef}
                    image={
                        isLoading || isError ? 
                        Images.Bot1 :
                        Config.ExternalLinks.NFT_IMAGE_URL + lastRoundData?.nftMintTokenId + Config.ExternalLinks.NFT_IMAGE_EXTENSION
                    }
                    label={ Language.NFT_MINT_WINNER }
                    value={`NFT #${lastRoundData?.nftMintTokenId}`}
                    url={Config.ExternalLinks.OPENSEA_ITEM_URL + lastRoundData?.nftMintTokenId}
                    viewLabel={ Language.VIEW_ON_OPENSEA }/>
                    
                <LastRoundStatsItem 
                    containerRef={passiveIncomeItemRef}
                    image={
                        isLoading || isError ?
                        Images.Bot2 :
                        Config.ExternalLinks.NFT_IMAGE_URL + lastRoundData?.passiveIncomeTokenId + Config.ExternalLinks.NFT_IMAGE_EXTENSION
                    }
                    label={ Language.PASSIVE_INCOME_RECIPIENT }
                    value={`${Helpers.formatValue(lastRoundData?.passiveIncomeAmount)} G777 (${Helpers.formatUsdValue(lastRoundData?.passiveIncomeAmount * lastRoundData?.tokenPrice)})`}
                    url={Config.Blockchain.Network.TX_EXPLORER + lastRoundData?.txId}/>
            </div>
        </div>
    )
}

export default LastRoundStats