import React from 'react'
import Images from '~/Assets/Images'
import ArrowRightIcon from '~/Assets/Vectors/ArrowRightIcon'
import Language from '~/Language'
import Style from './LastRoundStatsItem.module.css'

function LastRoundStatsItem({
    containerRef,
    isJackpot,
    image,
    label,
    value,
    viewLabel,
    url
}) {
    const handleViewTransaction = () => {
        window.open(url, '_blank');
    }

    return (
        <div className={Style.container} ref={containerRef}>   
            <img
                alt=''
                src={
                    isJackpot ?
                    Images.JackpotIcon :
                    image
                }
                className={
                    isJackpot ?
                    Style.jackpotItemImage :
                    Style.botItemImage
                }/>

            <div className={Style.statsContainer}>
                <p className={Style.label}>
                    { label }
                </p>

                <p className={Style.value}>
                    { value }
                </p>

                <div 
                    className={Style.linkContainer}
                    onClick={handleViewTransaction}>
                    <ArrowRightIcon 
                        className={Style.linkIcon}/>

                    <p className={Style.linkLabel}>
                        { viewLabel || Language.VIEW_TRANSACTION }
                    </p>
                </div>
            </div>
        </div>
    )
}

export default LastRoundStatsItem