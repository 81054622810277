import { ethers } from 'ethers';
import StakingPoolABI from './ABI/StakingPool.js';
import Config from '~/config.js';
import Language from '~/Language/index.js';

class StakingPool {
    constructor(web3Interface, signer) {
        this.web3Interface = web3Interface;
        this.signer = signer;
        this.lastSparseUpdate = 0;
        this.isInitialized = true;
        this.isFocused = false;
        this.state = null;
        
        this.contract = new ethers.Contract(
            Config.Blockchain.Contracts.STAKING_POOL, 
            StakingPoolABI, 
            signer
        );
    }

    async updateState() {
        if(!this.isFocused && this.state !== null)
            return;

        let newState = {}

        if(Date.now() > this.lastSparseUpdate + Config.Blockchain.SPARSE_UPDATE_RATE_MS) {
            this.lastSparseUpdate = Date.now();    
            newState.tvl = await this.contract.totalDeposits()
            newState.blockReward = await this.contract.getBlockReward()
        }

        let userData = await this.contract.userData(this.web3Interface.address)
        newState.stakedAmount = userData.stakedAmount; 

        newState.pendingRewards = 
            await this.contract.pendingRewards(this.web3Interface.address)

        if(!this.state)
            this.state = newState;
        else
            this.state = {...this.state, ...newState};

        return this.state;
    }

    async harvest() {
        let txData = await this.contract.harvest()
    
        this.web3Interface.addPendingTransaction(
            txData.hash, 
            'STAKING_POOL_HARVEST', 
            Language.CLAIMING_TOKENS
        )

        return txData;
    }

    async stake(amount) {
        let txData = await this.contract.stake(amount)
    
        this.web3Interface.addPendingTransaction(
            txData.hash, 
            'STAKING_POOL_STAKE', 
            'Staking tokens...'
        )

        return txData;
    }

    async unstake(amount) {
        let txData = await this.contract.unstake(amount)
    
        this.web3Interface.addPendingTransaction(
            txData.hash, 
            'STAKING_POOL_UNSTAKE', 
            'Unstaking tokens...'
        )

        return txData;
    }
}

export default StakingPool;