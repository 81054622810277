const Images = {
    Flags: {
        en: require('./Flags/en.png'),
        de: require('./Flags/de.png')
    },
    BotsHome: require('./droid_bright_blue.png'),
    BotsAbout: require('./bots_about.png'),
    BotsExplode: require('./droid-1000-explode-Medium.png'),
    BotsFront: require('./droid-1000-front-Medium.png'),
    JackpotIcon: require('./jackpot_icon.png'),
    BotJackpot: require('./bot_jackpot.png'),
    MissionWaves: require('./mission_waves.png'),
    VideoThumbnail: require('./video_thumbnail.jpg'),
}

export default Images;