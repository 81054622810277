import React from 'react'

function TelegramIcon({className}) {
    return (
        <svg 
            className={className}
            viewBox="0 0 12 10" 
            fill="none" 
            xmlns="http://www.w3.org/2000/svg">
            <path d="M10.3631 0.741949L0.730321 4.45732C0.0728706 4.7211 0.0767699 5.0878 0.610486 5.25131L3.01292 6.00116L3.93215 8.81944C4.0439 9.1279 3.98881 9.25024 4.31273 9.25024C4.5627 9.25024 4.67359 9.13627 4.81282 9.00027C4.90135 8.91363 5.42702 8.40256 6.01396 7.83189L8.51295 9.67825C8.97279 9.93194 9.30483 9.80052 9.41933 9.25117L11.0597 1.52102C11.2277 0.84769 10.803 0.54227 10.3631 0.741949ZM3.38996 5.82888L8.80506 2.41246C9.07539 2.24852 9.32325 2.33666 9.11974 2.5173L4.483 6.70082L4.30246 8.62642L3.38996 5.82888V5.82888Z" fill="white"/>
        </svg>
    )
}

export default TelegramIcon