import React, { useEffect, useState } from 'react'
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import SideMenuItem from './SideMenuItem/SideMenuItem'
import ClosePlainIcon from '~/Assets/Vectors/ClosePlainIcon';
import Language from '~/Language';
import Style from './SideMenu.module.css'
import Config from '~/config';
import LanguageDropdown from '../LanguageDropdown';

function SideMenu({
    isOpen,
    onClose
}) {
    const [hideBackdrop, setHideBackdrop] = useState(true);
    const backdropVariants = {
        open: { opacity: 1 },
        closed: { opacity: 0 },
    }

    const menuVariants = {
        open: { opacity: 1, x: 0 },
        closed: { opacity: 0, x: '-100%' }
    }

    useEffect(
        () => {
            if(isOpen) {
                setHideBackdrop(false);
            }
            else {
                setTimeout(() => {
                    setHideBackdrop(true)
                }, 200);
            }
        }, 
        [isOpen, setHideBackdrop]
    )

    return (
        <motion.div 
            initial={false}
            animate={isOpen ? "open" : "closed"}
            transition={{ duration: 0.2 }}
            className={Style.container}
            style={{x: hideBackdrop ? '-100%' : 0}}
            variants={backdropVariants}
            onClick={onClose}>
            <motion.div 
                initial={false}
                animate={isOpen ? "open" : "closed"}
                transition={{ duration: 0.2 }}
                className={Style.menuContainer}
                variants={menuVariants}
                onClick={(event) => { 
                    event.stopPropagation();
                }}>
                <div className={Style.menuContainer}>
                    <div className={Style.menuBar}>
                        <Link
                            className={Style.logoText}
                            to="/">
                            GRAVEL<span className={Style.logoText777}>777</span>
                        </Link>
                        
                        <LanguageDropdown
                            className={Style.languageDropdown}
                            isSideMenu/>

                        <ClosePlainIcon
                            className={Style.closeIcon} 
                            onClick={onClose}/>
                    </div>

                    <div className={Style.menuItemContainer}>
                        <SideMenuItem
                            external
                            text={ Language.BUY_G777 }
                            href={Config.ExternalLinks.TOKEN_EXCHANGE} />

                        <SideMenuItem
                            external
                            text={ Language.VIEW_NFT_COLLECTION }
                            href={Config.ExternalLinks.OPENSEA_COLLECTION_URL} />

                        <SideMenuItem
                            text={ Language.PUBLIC_SALE }
                            href="/sale" />

                        <SideMenuItem
                            text={ Language.VESTING }
                            href="/vesting" />

                        <SideMenuItem
                            text={ Language.ABOUT }
                            href="/about" />

                        <SideMenuItem
                            text="FAQ"
                            href="/faq" />
                    </div>
                </div>
            </motion.div>
        </motion.div>
    )
}

export default SideMenu