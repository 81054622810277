import React from 'react';
import PopupContainer from '../PopupContainer';
import HorizontalSpacer from '~/Assets/Vectors/HorizontalSpacer';
import ClosePlainIcon from '~/Assets/Vectors/ClosePlainIcon';
import Button from '~/Components/Button/Button';
import Language from '~/Language';
import Helpers from '~/helpers';
import Style from './MessagePopup.module.css';

const MessagePopup = ({
    title,
    messageLines,
    isPrompt,
    acceptLabel,
    denyLabel,
    isDenyPrimary,
    onClose,
    isOpen,
}) => {
    const handleClose = (confirm) => {
        if(onClose)
            onClose(confirm);
    }

    return (
        <PopupContainer
            isOpen={isOpen}
            onClose={handleClose.bind(null, false)}
            backdropColor="#0009">

            <div className={Style.container}>

                <div className={Style.titleBarContainer}>
                    <p className={Style.title}>{ title }</p>
                    <ClosePlainIcon className={Style.closeIcon}/>
                </div>

                <HorizontalSpacer className={Style.upperSpacer} />

                {
                    messageLines instanceof Array ?
                    messageLines.map(text => 
                        <p 
                            key={text}
                            className={Style.message}>
                            { text }
                        </p>
                    )
                    :
                    <p className={Style.message}>
                        { messageLines }
                    </p>
                }

                <div className={Style.buttonContainer}>
                    <Button
                        className={Style.button}
                        onClick={handleClose.bind(null, true)}
                        text={
                            acceptLabel || (isPrompt ? Language.YES : Language.OK)
                        }/>

                    {
                        isPrompt &&
                        <Button
                            className={
                                Helpers.conditionalClass(
                                    !isDenyPrimary,
                                    Style.button,
                                    Style.secondaryButton
                                )
                            }
                            onClick={handleClose.bind(null, false)}
                            text={ 
                                denyLabel || Language.NO
                            }/>
                    }
                </div>
            </div>

        </PopupContainer>
    );
}

export default MessagePopup;